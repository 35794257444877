import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonStyle,
  Checkbox,
  Form,
  FormField,
} from "@prequel-internal/react-components";
import { Provider } from "../../store/providers";
import { useTypedDispatch, useTypedSelector } from "../../store";
import { selectImportProductConfigs } from "../../store/configs/configs.duck";
import { useNavigate } from "react-router-dom";
import { createProvider } from "../../store/providers/providers.duck";

const ProviderForm = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const productConfigs = useTypedSelector(selectImportProductConfigs);
  const [provider, setProvider] = useState<Provider>({
    name: "",
    schema_suffix_in_destination_system: "",
    id_in_recipient_system: "",
    products: [],
  });
  const [productsError, setProductsError] = useState<string>();

  useEffect(() => {
    if (productConfigs) {
      setField(
        "products",
        productConfigs.find(({ product_name }) => product_name === "default")
          ? ["default"]
          : []
      );
    }
  }, [productConfigs]);

  const setField = (key: keyof Provider, value: string | string[]) => {
    setProvider((oldProvider: Provider) => ({
      ...oldProvider,
      [key]: value,
    }));
  };

  const updateProducts = (isEnabled: boolean, productName: string) => {
    let updatedProducts: string[] = [];
    if (isEnabled) {
      updatedProducts = [...provider.products, productName];
    } else {
      updatedProducts = provider.products.filter((p) => p !== productName);
    }
    setProductsError(undefined);
    setField("products", updatedProducts);
  };

  const onSubmit = () => {
    if (productConfigs && provider.products.length === 0) {
      setProductsError("Must select at least one product");
      return;
    }

    dispatch(
      createProvider({
        provider,
        redirect: () => navigate("/import/providers"),
      })
    );
  };

  return (
    <Form className="space-y-4" onSubmit={onSubmit} submitButtonRef={buttonRef}>
      <FormField
        id="name"
        type="text"
        label="Name"
        subtext="Descriptive name for this Provider. This will only be visible internally and is only used as a reference."
        value={provider.name}
        onChangeHandler={(value: string) => setField("name", value)}
        required
      />
      <FormField
        id="schema_suffix_in_destination_system"
        type="text"
        label="Schema Suffix in Destination System"
        subtext={
          "The Schema Suffix will be used as part of that schema name under which this data will be loaded. For example, a schema suffix named 'acme' will result in a destination schema named 'prequel_import_acme'."
        }
        value={provider.schema_suffix_in_destination_system}
        onChangeHandler={(value: string) =>
          setField("schema_suffix_in_destination_system", value)
        }
        required
      />
      <FormField
        id="id_in_recipient_system"
        type="text"
        label="ID in Recipient System"
        subtext="Customer ID by which the source data will be labeled in the destination. This value can be used to identify rows transferred from this source."
        value={provider.id_in_recipient_system}
        onChangeHandler={(value: string) =>
          setField("id_in_recipient_system", value)
        }
        required
      />
      {productConfigs && (
        <>
          <label className="block text-sm font-medium text-gray-700">
            Select what products the provider will send
          </label>
          {productConfigs.map(({ product_name }) => (
            <Checkbox
              key={product_name}
              id={product_name}
              label={product_name}
              checked={provider.products.includes(product_name)}
              setChecked={(isChecked: boolean) =>
                updateProducts(isChecked, product_name)
              }
            />
          ))}
          {productsError && (
            <p className="mt-1 text-xs font-medium text-red-600">
              {productsError}
            </p>
          )}
        </>
      )}
      <div>
        <Button
          className="mt-6"
          ref={buttonRef}
          type={ButtonStyle.PRIMARY}
          text="Create Provider"
          submit
        />
      </div>
    </Form>
  );
};

export default ProviderForm;
