import React, { useState, useEffect } from "react";
import { PrequelLogo } from "@prequel-internal/react-components";

import { useTypedDispatch, useTypedSelector } from "../../store";
import { selectEmail, resetAuthErrors } from "../../store/auth/auth.duck";

import OptionsCard from "./OptionsCard";
import PasswordlessCard from "./PasswordlessCard";
import PasswordlessSubmittedCard from "./PasswordlessSubmittedCard";
import SsoCard from "./SsoCard";

export type FormState =
  | "NOTHING_SELECTED"
  | "SSO_SELECTED"
  | "PASSWORDLESS_SELECTED"
  | "PASSWORDLESS_SUBMITTED";

const LoginCard = () => {
  const dispatch = useTypedDispatch();
  const [email, setEmail] = useState("");

  const [formState, setFormState] = useState<FormState>("NOTHING_SELECTED");

  const loginEmail = useTypedSelector(selectEmail);

  useEffect(() => {
    loginEmail && setEmail(loginEmail);
  }, [loginEmail]);

  useEffect(() => {
    dispatch(resetAuthErrors());
  }, [email]);

  return (
    <div className="flex min-h-screen flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="mx-auto w-full max-w-md">
        <PrequelLogo className="mx-auto h-12 w-auto" kind="logo" />
        <h2 className="mt-6 text-center text-3xl font-bold tracking-tight text-gray-900">
          Sign in to Prequel
        </h2>
      </div>
      <div className="mt-8 mx-auto w-full max-w-md">
        <div className="bg-white py-8 shadow rounded-lg px-10">
          <div className="space-y-6">
            {formState === "NOTHING_SELECTED" && (
              <OptionsCard
                setFormState={setFormState}
                email={email}
                setEmail={setEmail}
              />
            )}

            {formState === "PASSWORDLESS_SELECTED" && (
              <PasswordlessCard
                setFormState={setFormState}
                email={email}
                setEmail={setEmail}
              />
            )}

            {formState === "PASSWORDLESS_SUBMITTED" && (
              <PasswordlessSubmittedCard
                setFormState={setFormState}
                email={email}
                setEmail={setEmail}
              />
            )}

            {formState === "SSO_SELECTED" && (
              <SsoCard
                setFormState={setFormState}
                email={email}
                setEmail={setEmail}
              />
            )}
          </div>
        </div>
      </div>
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <p className="mt-6 mb-20 text-center text-sm text-gray-600">
          Or{" "}
          <a
            href="https://www.prequel.co/get-started"
            className="font-medium text-emerald-600 hover:text-emerald-500"
          >
            request an account
          </a>{" "}
          to get started
        </p>
      </div>
    </div>
  );
};

export default LoginCard;
