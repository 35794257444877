import React from "react";

import TestDestinationConnection from "../TestDestinationConnection";
import { Domain } from "../../lib";
import { useParams } from "react-router-dom";
import { useTypedSelector } from "../../store";
import {
  selectDestination,
  selectImportDestination,
} from "../../store/destinations/destinations.duck";

type DestinationConnectionDetailsProps = {
  domain: Domain;
};
const DestinationConnectionDetails = ({
  domain,
}: DestinationConnectionDetailsProps) => {
  const { connectionId } = useParams<{ connectionId: string }>();
  const destination =
    domain === Domain.EXPORT
      ? useTypedSelector((state) => selectDestination(state, connectionId))
      : useTypedSelector((state) =>
          selectImportDestination(state, connectionId)
        );

  if (!destination) {
    return <div>The destination could not be found.</div>;
  }

  return <TestDestinationConnection existingDestination={destination} />;
};

export default DestinationConnectionDetails;
