import React, { useEffect } from "react";
import { useTypedDispatch, useTypedSelector } from "../../store";
import {
  Badge,
  BadgeColor,
  Button,
  ButtonStyle,
  Table,
} from "@prequel-internal/react-components";
import Timestamp from "../Timestamp";
import { useNavigate } from "react-router-dom";
import {
  selectProducts,
  fetchProducts,
} from "../../store/products/products.duck";
import { selectModels, fetchModels } from "../../store/models/models.duck";
import { ReactComponent as FolderIcon } from "../../assets/icons/folder.svg";

const ProductsTable = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const products = useTypedSelector(selectProducts);
  const models = useTypedSelector(selectModels);

  useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchModels());
  }, [dispatch]);

  if (products?.length === 0) {
    return (
      <div className="flex-col bg-gray-50 border border-gray-200 text-gray-500 rounded p-4 text-sm">
        <div className="flex">
          <FolderIcon
            className="h-12 w-12 text-emerald-600"
            aria-hidden="true"
          />
          <div className="ml-3">
            <p className="font-medium text-gray-900 mb-1">
              You have not created any products.
            </p>
            <p>
              Products can be used when your recipients should have access to
              different subsets of available models. For example, separate
              customers on different service tiers for a software product may
              need access to a different set of models: e.g., &quot;Basic&quot;,
              &quot;Pro&quot;, and &quot;Enterprise&quot;.{" "}
              <a
                target="_blank"
                href="https://docs.prequel.co/docs/products-1"
                rel="noreferrer"
                className="font-medium text-primary-600 hover:text-primary-500"
              >
                Learn more
              </a>
            </p>
          </div>
        </div>
        <div className="mt-3 ml-3">
          <Button
            text="Create Product"
            onClick={() => navigate("new")}
            type={ButtonStyle.TERTIARY}
          />
        </div>
      </div>
    );
  }

  const rows = [...(products || [])].map((product) => ({
    id: product.id,
    cells: [
      { value: product.product_name },
      {
        value: (
          <>
            {product.models.length
              ? // If the model wasn't found for some reason, the models column will still render, but the
                // link to the model page will just redirect to /models.
                (() => {
                  return product.models.map((model) => {
                    const selectedModel = models?.find(
                      (m) => m.model_name === model
                    );
                    return (
                      <Badge
                        key={model}
                        className="mr-1"
                        text={selectedModel ? selectedModel.model_name : model}
                        color={BadgeColor.GRAY}
                        onClick={() =>
                          navigate(`/export/models/${selectedModel?.id}`)
                        }
                      />
                    );
                  });
                })()
              : "No models defined"}
          </>
        ),
      },
      { value: product.description },
      { value: <Timestamp timestamp={product.created_at} /> },
    ],
  }));

  const columns = ["Name", "Models", "Description", "Created At"];

  return (
    <Table
      header={
        <div className="flex justify-end">
          <Button
            text="Add Product"
            onClick={() => navigate("new")}
            type={ButtonStyle.TERTIARY}
          />
        </div>
      }
      columns={columns}
      rows={rows}
      footer={rows ? `${rows.length} products` : "0 rows"}
    />
  );
};

export default ProductsTable;
