import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, put, takeEvery } from "redux-saga/effects";

import ModelsService from "./models.service";
import { ExistingModel, ModelValidation } from ".";
import { createWorkerSaga, RootState } from "..";
import { AppError, RequestStatus } from "../../axios";
import { createToast } from "../toasts/toasts.duck";
import { ModelConfig } from "@prequel/react";

type ModelsState = {
  models: ExistingModel[] | undefined;
  importModels: ExistingModel[] | undefined;
  modelValidation: ModelValidation;
  isValidatingModel: boolean;
};
const initialState: ModelsState = {
  models: undefined,
  importModels: undefined,
  modelValidation: { status: undefined, message: undefined },
  isValidatingModel: false,
};

const fetchModelsReducer: CaseReducer<ModelsState, PayloadAction<void>> = (
  state
) => state;

const fetchModelsSuccessReducer: CaseReducer<
  ModelsState,
  PayloadAction<ExistingModel[]>
> = (state, action) => {
  state.models = action.payload.sort((a, b) =>
    a.model_name.localeCompare(b.model_name)
  );
};

const fetchModelsFailureReducer: CaseReducer<
  ModelsState,
  PayloadAction<AppError>
> = (state) => state;

const fetchImportModelsReducer: CaseReducer<
  ModelsState,
  PayloadAction<void>
> = (state) => state;

const fetchImportModelsSuccessReducer: CaseReducer<
  ModelsState,
  PayloadAction<ExistingModel[]>
> = (state, action) => {
  state.importModels = action.payload.sort((a, b) =>
    a.model_name.localeCompare(b.model_name)
  );
};

const fetchImportModelsFailureReducer: CaseReducer<
  ModelsState,
  PayloadAction<AppError>
> = (state) => state;

const validateModelReducer: CaseReducer<
  ModelsState,
  PayloadAction<ExistingModel>
> = (state) => {
  state.modelValidation.status = "processing";
  state.isValidatingModel = true;
};

const validateModelSuccessReducer: CaseReducer<
  ModelsState,
  PayloadAction<RequestStatus>
> = (state) => {
  state.modelValidation.status = "success";
  state.isValidatingModel = false;
};

const validateModelFailureReducer: CaseReducer<
  ModelsState,
  PayloadAction<AppError>
> = (state, action) => {
  state.modelValidation = {
    status: "error",
    message: action.payload.error.message,
  };
  state.isValidatingModel = false;
};

function* watchFetchModels() {
  yield takeEvery(
    fetchModels.type,
    createWorkerSaga(
      fetchModels,
      fetchModelsSuccess,
      fetchModelsFailure,
      ModelsService.getModels
    )
  );
}

function* watchFetchImportModels() {
  yield takeEvery(
    fetchImportModels.type,
    createWorkerSaga(
      fetchImportModels,
      fetchImportModelsSuccess,
      fetchImportModelsFailure,
      ModelsService.getImportModels
    )
  );
}

function* watchValidateModel() {
  yield takeEvery(
    validateModel.type,
    createWorkerSaga(
      validateModel,
      validateModelSuccess,
      validateModelFailure,
      ModelsService.validateModel
    )
  );
}

function* watchValidateModelSuccess() {
  yield takeEvery(validateModelSuccess.type, function* () {
    yield put(
      createToast({
        toast: {
          id: new Date().getTime().toString(),
          kind: "SUCCESS",
          message:
            "The model configuration is valid, and its tables and columns exist in the source database.",
        },
      })
    );
  });
}

const modelsSlice = createSlice({
  name: "models",
  initialState,
  reducers: {
    fetchModels: fetchModelsReducer,
    fetchModelsSuccess: fetchModelsSuccessReducer,
    fetchModelsFailure: fetchModelsFailureReducer,
    fetchImportModels: fetchImportModelsReducer,
    fetchImportModelsSuccess: fetchImportModelsSuccessReducer,
    fetchImportModelsFailure: fetchImportModelsFailureReducer,
    validateModel: validateModelReducer,
    validateModelSuccess: validateModelSuccessReducer,
    validateModelFailure: validateModelFailureReducer,
  },
});

export const {
  fetchModels,
  fetchModelsSuccess,
  fetchModelsFailure,
  fetchImportModels,
  fetchImportModelsSuccess,
  fetchImportModelsFailure,
  validateModel,
  validateModelSuccess,
  validateModelFailure,
} = modelsSlice.actions;

export const selectModels = ({ models }: RootState) => models.models;
export const selectImportModels = ({ models }: RootState) =>
  models.importModels;
export const selectIsValidatingModel = ({ models }: RootState) =>
  models.isValidatingModel;
export const selectModel: (
  state: RootState,
  id?: string
) => ExistingModel | null | undefined = (state, modelId) => {
  const models = selectModels(state);
  if (models === undefined) {
    // If we don't have models yet, return undefined while we wait for the fetch;
    return undefined;
  }

  const found = models.find(({ id }) => id === modelId);
  // If we could not find the model in the loaded models, return null to signify it does not exist
  return found ?? null;
};

export function* modelsSaga() {
  yield all([
    watchFetchModels(),
    watchFetchImportModels(),
    watchValidateModel(),
    watchValidateModelSuccess(),
  ]);
}
export default modelsSlice.reducer;
