import React from "react";
import { NavLink, useNavigate, useRoutes } from "react-router-dom";
import {
  Button,
  ButtonStyle,
  PageHeading,
  Tabs,
  checkTabIsActive,
} from "@prequel-internal/react-components";

import { Domain } from "../../lib";
import ImportSourceSteps from "../ImportSourceSteps";
import ThirdPartyConnectionsTable from "../ThirdPartyConnectionsTable";
import ThirdPartyConnectionPage from "../ThirdPartyConnectionPage";
import ExportDestinationForm from "../ExportDestinationForm";

type ThirdPartyConnectionsPageProps = {
  domain: Domain;
};
const ThirdPartyConnectionsPage = ({
  domain,
}: ThirdPartyConnectionsPageProps) => {
  const navigate = useNavigate();

  const tabs = [
    <NavLink key="Summary" to="#" className={checkTabIsActive}>
      Summary
    </NavLink>,
  ];

  const element = useRoutes([
    {
      path: "/",
      element: (
        <div className="max-w-6xl pb-16">
          <PageHeading
            title={domain === Domain.EXPORT ? "Destinations" : "Sources"}
          >
            <Button
              text={domain === Domain.EXPORT ? "Add Destination" : "Add Source"}
              onClick={() => navigate("new")}
              type={ButtonStyle.SECONDARY}
            />
          </PageHeading>
          <Tabs tabs={tabs} />
          <ThirdPartyConnectionsTable domain={domain} />
        </div>
      ),
    },
    {
      path: "new",
      element:
        domain === Domain.EXPORT ? (
          <div className="max-w-lg">
            <PageHeading
              title="Add Destination"
              onGoBack={() => navigate(-1)}
            />
            <ExportDestinationForm />
          </div>
        ) : (
          <div className="max-w-6xl min-w-lg">
            <PageHeading title={"Add Source"} onGoBack={() => navigate(-1)} />
            <ImportSourceSteps />
          </div>
        ),
    },
    {
      path: ":connectionId/edit",
      element:
        domain === Domain.EXPORT ? (
          <div className="max-w-lg">
            <PageHeading
              title="Edit Destination"
              onGoBack={() => navigate(-1)}
            />
            <ExportDestinationForm />
          </div>
        ) : (
          <div className="max-w-6xl min-w-lg">
            <PageHeading title={"Edit Source"} onGoBack={() => navigate(-1)} />
            <ImportSourceSteps />
          </div>
        ),
    },
    {
      path: ":connectionId/*",
      element: (
        <div className="max-w-4xl">
          <ThirdPartyConnectionPage domain={domain} />
        </div>
      ),
    },
  ]);

  return <div className="flex flex-col pb-4">{element}</div>;
};

export default ThirdPartyConnectionsPage;
