import React, { useEffect } from "react";

import { useTypedDispatch, useTypedSelector } from "../../store";
import {
  fetchTransferReport,
  selectTransferReport,
} from "../../store/reports/transfers_report.duck";

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  PointElement,
  Title,
  Tooltip,
  ChartOptions,
  ScriptableChartContext,
} from "chart.js";
import { Chart } from "react-chartjs-2";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  BarController,
  PointElement,
  Title,
  Tooltip
);

type OverviewChartProps = {
  destinationId?: string;
};
const OverviewChart = ({ destinationId }: OverviewChartProps) => {
  const dispatch = useTypedDispatch();
  const transferReport = useTypedSelector(selectTransferReport);

  useEffect(() => {
    dispatch(fetchTransferReport({ destinationId }));
  }, [dispatch]);

  const labels = transferReport?.map((transferDay) => {
    const utcMonth = new Date(transferDay.day).toLocaleDateString("en-US", {
      month: "short",
      timeZone: "UTC",
      timeZoneName: undefined,
    });
    const utcDay = new Date(transferDay.day).toLocaleDateString("en-US", {
      day: "numeric",
      timeZone: "UTC",
      timeZoneName: undefined,
    });
    return `${utcMonth}. ${utcDay}`;
  });
  const data = transferReport?.map(
    (transferDay) => transferDay.total_rows_transferred
  );

  const chartData = {
    labels: labels,
    datasets: [
      {
        label: "Rows Transferred",
        data: data,
        barPercentage: 0.9,
        backgroundColor: (context: ScriptableChartContext) => {
          const chart = context.chart;
          const { ctx, chartArea } = chart;
          if (!chartArea) {
            return;
          }
          const chartWidth = chartArea.right - chartArea.left;
          const chartHeight = chartArea.bottom - chartArea.top;
          let width, height, gradient;

          if (!gradient || width !== chartWidth || height !== chartHeight) {
            // Create the gradient because this is either the first render
            // or the size of the chart has changed
            width = chartWidth;
            height = chartHeight;
            gradient = ctx.createLinearGradient(
              0,
              chartArea.bottom,
              0,
              chartArea.top
            );
            gradient.addColorStop(0, "#10b981");
            //gradient.addColorStop(0.5, "#059669");
            gradient.addColorStop(1, "#10b981");
          }

          return gradient;
        },
        borderWidth: 0,
        borderRadius: 2,
      },
    ],
  };

  const options: ChartOptions = {
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true,
      },
    },

    interaction: {
      intersect: false,
      mode: "index",
    },

    scales: {
      x: {
        title: {
          display: false,
          text: "Day",
        },
        grid: {
          display: false,
          drawBorder: true,
        },
      },
      y: {
        title: {
          display: true,
          text: "Rows transferred",
        },
        min: 0,
        suggestedMax: 1000,

        ticks: {},
        grid: {
          display: true,
          drawBorder: false,
        },
      },
    },
  };

  return (
    <div className="mx-auto max-w-7xl px-2 pb-8">
      <Chart data={chartData} type="bar" height={60} options={options} />
    </div>
  );
};

export default OverviewChart;
