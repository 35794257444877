import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  VendorField,
  IamRole as GcpIamRole,
  AwsTrustPolicy,
} from "@prequel/react";
import {
  Dropdown,
  DropdownListItem,
  FormField,
  TextArea,
  PasswordDot,
} from "@prequel-internal/react-components";
import { Link } from "react-router-dom";

import {
  ExistingImportSource,
  ImportSource,
  PreparedImportSource,
  convertToImportSource,
  prepareImportSource,
} from "../../../store/sources";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  fetchImportSources,
  fetchSourceVendors,
  selectSourceTest,
  selectSourceVendors,
} from "../../../store/sources/sources.duck";
import {
  DropdownListItemWithFields,
  Vendor,
  getValidServiceAccountKey,
} from "../../../lib";
import VendorLogo from "../../VendorLogo";
import SSHTunnelForm from "../../SSHTunnelForm";
import TestSourceConnection from "../../TestSourceConnection";
import {
  fetchProviders,
  selectProviders,
} from "../../../store/providers/providers.duck";
import ExistingProvider from "../../../store/providers";
import IamRole from "../../IamRole";
import IamTrustPolicy from "../../IamTrustPolicy";

type ImportSourceFormProps = {
  source: ImportSource;
  setSource: React.Dispatch<React.SetStateAction<ImportSource>>;
  setCanGoForward: React.Dispatch<React.SetStateAction<boolean>>;
  sourceToEdit: ExistingImportSource | null | undefined;
  isEditing: boolean;
};
const ImportSourceForm = ({
  source,
  setSource,
  setCanGoForward,
  sourceToEdit,
  isEditing,
}: ImportSourceFormProps) => {
  const dispatch = useTypedDispatch();
  const formRef = useRef<HTMLFormElement>(null);
  const sourceVendors = useTypedSelector(selectSourceVendors);
  const sourceTest = useTypedSelector(selectSourceTest);
  const providers = useTypedSelector(selectProviders);

  const [providerOptions, setProviderOptions] =
    useState<DropdownListItem<string>[]>();
  const [selectedProvider, setSelectedProvider] = useState<
    DropdownListItem<string>
  >({
    key: "",
    text: "",
  });
  const [vendorOptions, setVendorOptions] =
    useState<DropdownListItemWithFields[]>();
  const [selectedVendor, setSelectedVendor] =
    useState<DropdownListItemWithFields>({
      key: "",
      text: "",
      fields: [],
      docs: "",
      uses_staging_bucket: false,
      uses_service_account: false,
      supports_ssh_tunnel: false,
    });

  const formFields: { [key: string]: VendorField } = useMemo(() => {
    return selectedVendor.fields.reduce(
      (acc, obj) => ({ ...acc, [obj.name]: obj }),
      {}
    );
  }, [selectedVendor]);

  // On service_account_key changes, attempt to coerce the string into the JSON object
  const tokenIsValid = useMemo(
    () => !!getValidServiceAccountKey(source.service_account_key),
    [source.service_account_key]
  );

  const preparedSource: PreparedImportSource = useMemo(
    () => prepareImportSource(source),
    [source]
  );

  const validateForm = () =>
    formRef.current ? formRef.current.reportValidity() : false;

  const setSourceField = (
    key: keyof ImportSource,
    value: string | string[] | boolean
  ) => {
    setSource((oldSource) => ({
      ...oldSource,
      [key]: value,
    }));
  };

  useEffect(() => {
    dispatch(fetchSourceVendors());
    dispatch(fetchProviders());
    dispatch(fetchImportSources());
  }, [dispatch]);

  useEffect(() => {
    if (sourceToEdit) {
      setSource(convertToImportSource(sourceToEdit));
    }
  }, [sourceToEdit]);

  useEffect(() => {
    if (selectedProvider) {
      setCanGoForward(sourceTest.status === "success");
    }
  }, [sourceTest]);

  useEffect(() => {
    // Turn off the next button if the source changes
    setCanGoForward(false);
  }, [source]);

  useEffect(() => {
    if (sourceVendors) {
      const vendors = sourceVendors.map((s: Vendor) => {
        const icon = () => <VendorLogo logo_url={s.logo_url} />;
        return {
          ...s,
          key: s.vendor_name,
          text: s.display_name,
          icon,
          uses_service_account: false,
        };
      });

      setVendorOptions(vendors);
      const opt =
        vendors.find(({ key }) => key === source.vendor) ?? vendors[0];
      setSelectedVendor(opt);
    }
  }, [sourceVendors, source.vendor]);

  useEffect(() => {
    if (providers) {
      const opts = providers.map(
        ({ name, id, id_in_recipient_system }: ExistingProvider) => ({
          key: id,
          text: (
            <span className="flex items-center">
              <span className="mr-1">{name}</span>
              <span className="text-xs text-gray-500 truncate">
                ({id_in_recipient_system})
              </span>
            </span>
          ),
        })
      );

      setProviderOptions(opts);
      if (opts.length > 0) {
        setSelectedProvider(opts[0]);
      }
    }
  }, [providers]);

  useEffect(() => {
    if (!isEditing) {
      setSourceField("vendor", selectedVendor.key);
    }
  }, [selectedVendor]);

  useEffect(() => {
    if (!isEditing) {
      setSourceField("provider_id", selectedProvider.key);
    }
  }, [selectedProvider]);

  // Intercept native form submission, prevent default, and run test
  // We use the default form submission event so that we can borrow the browsers built-in support for handling missing required fields
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
  };

  const placeholderTrustPolicy: AwsTrustPolicy = {
    Version: "2012-10-17",
    Statement: [
      {
        Effect: "Allow",
        Action: ["sts:AssumeRoleWithWebIdentity"],
        Principal: {
          Federated: "accounts.google.com",
        },
        Condition: {
          StringEquals: {
            "accounts.google.com:sub": "<some_service_account_identifier>",
          },
        },
      },
    ],
  };

  const placeholderIamRole: GcpIamRole = {
    id: "<some-service-account-name>@prql-prod.iam.gserviceaccount.com",
  };

  return (
    <form className="space-y-8" onSubmit={onSubmit} ref={formRef}>
      <div className="space-y-4">
        {vendorOptions && (
          <Dropdown
            label="Source type"
            items={vendorOptions}
            selectedItem={selectedVendor}
            setSelectedItem={setSelectedVendor}
            disabled={isEditing}
          />
        )}
        <FormField
          label="Name the source"
          id="name"
          type="text"
          subtext="Descriptive name for this source."
          value={source.name}
          onChangeHandler={(value: string) => setSourceField("name", value)}
          required
          disabled={sourceTest.status === "processing"}
        />
        {providerOptions && (
          <div>
            <Dropdown
              label="Provider"
              placeholder={
                <div className="text-sm text-gray-400">
                  No providers have been created yet. You can add one{" "}
                  <Link
                    className="text-emerald-600 hover:text-emerald-800"
                    to="/import/providers/new"
                  >
                    here
                  </Link>
                  .{" "}
                </div>
              }
              items={providerOptions}
              selectedItem={selectedProvider}
              setSelectedItem={setSelectedProvider}
              disabled={isEditing}
            />
            <p
              className="mt-2 text-xs text-gray-400 whitespace-pre-line"
              id="description"
            >
              {"Provider name (id_in_recipient_system)"}
            </p>
          </div>
        )}
      </div>
      <div className="h-px w-full bg-gray-200"></div> {/* Divider  */}
      <div className="space-y-4">
        <div>
          <label className="block text-sm font-medium text-gray-700">
            Enter the source credentials
          </label>
          <div className="mt-1">
            <p className="mt-1 text-sm text-gray-500">
              {`Provide the details of the ${selectedVendor.text} source
            in the form below. For assistance, `}
              <a
                href={selectedVendor.docs}
                target="_blank"
                rel="noreferrer"
                className="font-medium text-primary-600 hover:text-primary-500"
              >
                view our documentation on {selectedVendor.text}.
              </a>
            </p>
          </div>
        </div>
        {"host" in formFields && (
          <FormField
            id="host"
            type="text"
            label={formFields.host.label}
            placeholder={formFields.host.placeholder}
            subtext={formFields.host.help}
            value={source.host}
            onChangeHandler={(value: string) => {
              setSourceField("host", value);
            }}
            required={formFields.host.is_required}
            disabled={sourceTest.status === "processing" || isEditing}
          />
        )}
        {"port" in formFields && (
          <FormField
            id="port"
            type="text"
            label={formFields.port.label}
            placeholder={formFields.port.placeholder}
            subtext={formFields.port.help}
            value={source.port}
            onChangeHandler={(value: string) => {
              setSourceField("port", value);
            }}
            required={formFields.port.is_required}
            disabled={sourceTest.status === "processing"}
          />
        )}
        {"database" in formFields && (
          <FormField
            id="database"
            type="text"
            label={formFields.database.label}
            placeholder={formFields.database.placeholder}
            subtext={formFields.database.help}
            value={source.database}
            onChangeHandler={(value: string) => {
              setSourceField("database", value);
            }}
            required={formFields.database.is_required}
            disabled={sourceTest.status === "processing"}
          />
        )}
        {"username" in formFields && (
          <FormField
            id="username"
            type="text"
            label={formFields.username.label}
            placeholder={formFields.username.placeholder}
            subtext={formFields.username.help}
            value={source.username}
            onChangeHandler={(value: string) => {
              setSourceField("username", value);
            }}
            required={formFields.username.is_required}
            disabled={sourceTest.status === "processing"}
          />
        )}
        {"password" in formFields && (
          <FormField
            id="password"
            type="password"
            label={formFields.password.label}
            placeholder={
              isEditing
                ? PasswordDot.repeat(20)
                : formFields.password.placeholder
            }
            overwriteOnly={isEditing}
            subtext={formFields.password.help}
            value={source.password}
            onChangeHandler={(value: string) => {
              setSourceField("password", value);
            }}
            required={formFields.password.is_required && !isEditing}
            disabled={sourceTest.status === "processing"}
          />
        )}
        {"service_account_key" in formFields && (
          <TextArea
            id="service_account_key"
            placeholder={
              isEditing
                ? PasswordDot.repeat(184)
                : formFields.service_account_key.placeholder
            }
            overwriteOnly={isEditing}
            subtext={formFields.service_account_key.help}
            value={source.service_account_key}
            onChangeHandler={(value: string) => {
              setSourceField("service_account_key", value);
            }}
            invalid={!tokenIsValid}
            required={formFields.service_account_key.is_required && !isEditing}
            disabled={sourceTest.status === "processing"}
          />
        )}
        {"gcp_iam_role" in formFields && (
          <>
            <IamRole role={placeholderIamRole} kind="FIRSTPARTYSOURCE" />
            <FormField
              id="gcp_iam_role"
              type="text"
              label={formFields.gcp_iam_role.label}
              placeholder={formFields.gcp_iam_role.placeholder}
              subtext={formFields.gcp_iam_role.help}
              value={source?.gcp_iam_role}
              onChangeHandler={(value: string) => {
                setSourceField("gcp_iam_role", value);
              }}
              required={formFields.gcp_iam_role.is_required}
              disabled={sourceTest.status === "processing"}
            />
          </>
        )}
        {"aws_iam_role" in formFields && (
          <>
            <IamTrustPolicy
              iamTrustPolicy={placeholderTrustPolicy}
              kind="FIRSTPARTYSOURCE"
            />
            <FormField
              id="aws_iam_role"
              type="text"
              label={formFields.aws_iam_role.label}
              placeholder={formFields.aws_iam_role.placeholder}
              subtext={formFields.aws_iam_role.help}
              value={source.aws_iam_role}
              onChangeHandler={(value: string) => {
                setSourceField("aws_iam_role", value);
              }}
              required={formFields.aws_iam_role.is_required}
              disabled={sourceTest.status === "processing"}
            />
          </>
        )}
        {selectedVendor.uses_staging_bucket && (
          <div className="h-px w-full bg-gray-200"></div>
        )}
        <div className="space-y-4">
          {selectedVendor.uses_staging_bucket && (
            <>
              <label className="block text-sm font-medium text-gray-700">
                Enter the staging bucket credentials
              </label>
              <div className="mt-1">
                <p className="mt-1 text-sm text-gray-500">
                  {
                    "For some destinations without built in data staging areas, a staging bucket must be provided for efficent data loading. For assistance, "
                  }
                  <a
                    href="https://docs.prequel.co/docs/s3-staging-bucket"
                    target="_blank"
                    rel="noreferrer"
                    className="font-medium text-primary-600 hover:text-primary-500"
                  >
                    view our documentation on staging buckets.
                  </a>
                </p>
              </div>
            </>
          )}
          {"bucket_vendor" in formFields && (
            <FormField
              id="bucket_vendor"
              type="text"
              label={formFields.bucket_vendor.label}
              placeholder={formFields.bucket_vendor.placeholder}
              subtext={formFields.bucket_vendor.help}
              value={source.bucket_vendor}
              onChangeHandler={(value: string) => {
                setSourceField("bucket_vendor", value);
              }}
              required={formFields.bucket_vendor.is_required}
              disabled={sourceTest.status === "processing"}
            />
          )}
          {"bucket_name" in formFields && (
            <FormField
              id="bucket_name"
              type="text"
              label={formFields.bucket_name.label}
              placeholder={formFields.bucket_name.placeholder}
              subtext={formFields.bucket_name.help}
              value={source.bucket_name}
              onChangeHandler={(value: string) => {
                setSourceField("bucket_name", value);
              }}
              required={formFields.bucket_name.is_required}
              disabled={sourceTest.status === "processing" || isEditing}
            />
          )}
          {"bucket_region" in formFields && (
            <FormField
              id="bucket_region"
              type="text"
              label={formFields.bucket_region.label}
              placeholder={formFields.bucket_region.placeholder}
              subtext={formFields.bucket_region.help}
              value={source.bucket_region}
              onChangeHandler={(value: string) => {
                setSourceField("bucket_region", value);
              }}
              required={formFields.bucket_region.is_required}
              disabled={sourceTest.status === "processing"}
            />
          )}
          {"bucket_access_id" in formFields && (
            <FormField
              id="bucket_access_id"
              type="text"
              label={formFields.bucket_access_id.label}
              placeholder={formFields.bucket_access_id.placeholder}
              subtext={formFields.bucket_access_id.help}
              value={source.bucket_access_id}
              onChangeHandler={(value: string) => {
                setSourceField("bucket_access_id", value);
              }}
              required={formFields.bucket_access_id.is_required}
              disabled={sourceTest.status === "processing"}
            />
          )}
          {"bucket_secret_key" in formFields && (
            <FormField
              id="bucket_secret_key"
              type="password"
              label={formFields.bucket_secret_key.label}
              placeholder={
                isEditing
                  ? PasswordDot.repeat(40)
                  : formFields.bucket_secret_key.placeholder
              }
              overwriteOnly={isEditing}
              subtext={formFields.bucket_secret_key.help}
              value={source.bucket_secret_key}
              onChangeHandler={(value: string) => {
                setSourceField("bucket_secret_key", value);
              }}
              required={formFields.bucket_secret_key.is_required && !isEditing}
              disabled={sourceTest.status === "processing"}
            />
          )}
        </div>
      </div>
      {selectedVendor.supports_ssh_tunnel && (
        <SSHTunnelForm connector={source} setField={setSourceField} />
      )}
      <TestSourceConnection
        beforeSubmitTest={validateForm}
        preparedSource={preparedSource}
        existingSource={sourceToEdit}
      />
    </form>
  );
};

export default ImportSourceForm;
