import React, { useEffect } from "react";
import {
  Button,
  ButtonStyle,
  Spinner,
} from "@prequel-internal/react-components";
import { ReactComponent as AlertCircleIcon } from "../../assets/icons/alert-circle.svg";
import { ReactComponent as AlertTriangleIcon } from "../../assets/icons/alert-triangle.svg";
import { ReactComponent as CheckIcon } from "../../assets/icons/check.svg";
import { ReactComponent as EllipsisIcon } from "../../assets/icons/ellipsis.svg";

import { useTypedDispatch, useTypedSelector } from "../../store";
import ExistingSource, {
  computeChangedFields,
  computeChangedImportFields,
  ExistingImportSource,
  ExistingSourceTestPayload,
  PreparedImportSource,
  PreparedSource,
  SourceType,
} from "../../store/sources";
import {
  resetSourceTest,
  selectSourceTest,
  testNewSource,
  testExistingSource,
  testExistingImportSource,
} from "../../store/sources/sources.duck";
import { ExistingImportSourcePayload } from "../../store/sources";

type TestSourceConnectionProps = {
  beforeSubmitTest?: () => boolean;
  preparedSource?: PreparedSource | PreparedImportSource;
  existingSource?: ExistingSource | ExistingImportSource | null;
};
const TestSourceConnection = ({
  beforeSubmitTest = () => true,
  preparedSource,
  existingSource,
}: TestSourceConnectionProps) => {
  const dispatch = useTypedDispatch();
  const sourceTest = useTypedSelector(selectSourceTest);

  useEffect(() => {
    dispatch(resetSourceTest());
  }, [preparedSource]);

  // Cleanup test state on unmount
  useEffect(() => {
    return () => {
      dispatch(resetSourceTest());
    };
  }, []);

  const onTestConnection = () => {
    if (!beforeSubmitTest()) {
      return;
    }

    if (
      existingSource?.type === SourceType.ExistingSource &&
      preparedSource?.type === SourceType.PreparedSource
    ) {
      const payload: ExistingSourceTestPayload = {
        sourceId: existingSource.id,
      };
      const changed = computeChangedFields(existingSource, preparedSource);
      if (Object.keys(changed).length > 0) {
        payload.fields = changed;
      }
      dispatch(testExistingSource(payload));
    } else if (
      existingSource?.type === SourceType.ExistingImportSource &&
      preparedSource?.type === SourceType.PreparedImportSource
    ) {
      const payload: ExistingImportSourcePayload = {
        sourceId: existingSource.id,
      };
      const changed = computeChangedImportFields(
        existingSource,
        preparedSource
      );
      if (Object.keys(changed).length > 0) {
        payload.fields = changed;
      }
      dispatch(testExistingImportSource(payload));
    } else if (existingSource?.type === SourceType.ExistingSource) {
      dispatch(testExistingSource({ sourceId: existingSource.id }));
    } else if (existingSource?.type === SourceType.ExistingImportSource) {
      dispatch(testExistingImportSource({ sourceId: existingSource.id }));
    } else if (preparedSource) {
      dispatch(testNewSource(preparedSource));
    }
  };

  return (
    <div className="relative pt-4">
      <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-primary-500 focus-within:ring-1 focus-within:ring-primary-500">
        <div className="p-4">
          <div className="flex">
            <div className="flex-shrink-0">
              <div
                className={`mx-auto flex items-center justify-center h-10 w-10 rounded-full ${
                  sourceTest.status === "success"
                    ? "bg-emerald-100"
                    : sourceTest.status === "error"
                    ? "bg-red-100"
                    : sourceTest.status === "processing"
                    ? "bg-gray-100"
                    : "bg-gray-100"
                }`}
              >
                {sourceTest.status === "success" && (
                  <CheckIcon
                    className="h-5 w-5 text-emerald-600"
                    aria-hidden="true"
                  />
                )}
                {sourceTest.status === "error" && (
                  <AlertCircleIcon
                    className="h-5 w-5 text-red-600"
                    aria-hidden="true"
                  />
                )}
                {sourceTest.status === "processing" && (
                  <EllipsisIcon
                    className="h-5 w-5 text-gray-600"
                    aria-hidden="true"
                  />
                )}
                {!sourceTest.status && (
                  <AlertTriangleIcon
                    className="h-5 w-5 text-gray-600"
                    aria-hidden="true"
                  />
                )}
              </div>
            </div>
            <div className="ml-3 mt-1">
              <h3 className="text-lg font-medium text-gray-800">
                {sourceTest.status === "success" && "Connection successful!"}
                {sourceTest.status === "error" && "Error connecting to source."}
                {sourceTest.status === "processing" && "Testing connection..."}
                {!sourceTest.status &&
                  `Test connection${
                    preparedSource ? " before saving." : " status."
                  } `}
              </h3>
              <div className="mt-2 mb-2 text-sm text-gray-700">
                <p>
                  {sourceTest.status === "success" &&
                    `Connection was successfully established to this source.${
                      preparedSource
                        ? " The source details can now be saved."
                        : ""
                    }`}
                  {sourceTest.status === "error" && (
                    <>
                      <span className="block mb-4">
                        Try updating the source details and try again.
                      </span>
                      <code className="text-xs">{sourceTest.message}</code>
                    </>
                  )}
                  {sourceTest.status === "processing" && ""}
                  {!sourceTest.status && ""}
                </p>
              </div>
            </div>
          </div>
        </div>
        <div aria-hidden="true">
          <div className="h-px" />
          <div className="py-2">
            <div className="py-px">
              <div className="h-9" />
            </div>
          </div>
        </div>
        <div className="absolute bottom-0 inset-x-px">
          <div className="border-t border-gray-200 px-2 py-2 flex justify-between items-center space-x-3 sm:px-3">
            <div className="flex">
              <div className="flex-shrink-0">
                <Button
                  onClick={onTestConnection}
                  type={ButtonStyle.PRIMARY}
                  disabled={sourceTest.status === "processing"}
                  text={
                    sourceTest.status === "processing" ? (
                      <div className="flex">
                        <Spinner.Inline className="mr-3 text-white" />
                        Testing Connection...
                      </div>
                    ) : (
                      "Test Connection"
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TestSourceConnection;
