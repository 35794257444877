import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { all, put, takeEvery } from "redux-saga/effects";
import { ExistingDestination, Transfer } from "@prequel/react";

import { AppError } from "../../axios";
import TransfersService from "./transfers.service";
import {
  RootState,
  WithRedirect,
  createRedirectSaga,
  createWorkerSaga,
} from "..";

import { createToast } from "../toasts/toasts.duck";
import { ExistingImportSource } from "../sources";

// Slice state
type TransfersState = {
  transfers: { [id: Transfer["id"]]: Transfer[] };
  isLoadingTransfers: boolean;
  importTransfers: { [id: Transfer["id"]]: Transfer[] };
  isLoadingImportTransfers: boolean;
  isPostingTransfer: boolean;
  isCancelingTransfer: boolean;
};
const initialState: TransfersState = {
  transfers: {},
  isLoadingTransfers: false,
  importTransfers: {},
  isLoadingImportTransfers: false,
  isPostingTransfer: false,
  isCancelingTransfer: false,
};

// Action Reducers (Case Reducers)
const fetchTransfersReducer: CaseReducer<
  TransfersState,
  PayloadAction<ExistingDestination["id"]>
> = (state) => {
  state.isLoadingTransfers = true;
};

const fetchTransfersSuccessReducer: CaseReducer<
  TransfersState,
  PayloadAction<{
    destinationId: ExistingDestination["id"] | undefined;
    transfers: Transfer[];
  }>
> = (state, action) => {
  state.isLoadingTransfers = false;
  if (action.payload.destinationId) {
    state.transfers[action.payload.destinationId] = action.payload.transfers;
  }
};

const fetchTransfersFailureReducer: CaseReducer<
  TransfersState,
  PayloadAction<AppError>
> = (state) => {
  state.isLoadingTransfers = false;
};

const fetchImportTransfersReducer: CaseReducer<
  TransfersState,
  PayloadAction<ExistingImportSource["id"] | undefined>
> = (state) => {
  state.isLoadingImportTransfers = true;
};

const fetchImportTransfersSuccessReducer: CaseReducer<
  TransfersState,
  PayloadAction<{
    sourceId: ExistingImportSource["id"] | undefined;
    transfers: Transfer[];
  }>
> = (state, action) => {
  state.isLoadingImportTransfers = false;
  if (action.payload.sourceId) {
    state.importTransfers[action.payload.sourceId] = action.payload.transfers;
  }
};

const fetchImportTransfersFailureReducer: CaseReducer<
  TransfersState,
  PayloadAction<AppError>
> = (state) => {
  state.isLoadingImportTransfers = false;
};

const createTransferReducer: CaseReducer<
  TransfersState,
  PayloadAction<{
    destinationId: ExistingDestination["id"];
    fullRefresh: boolean;
    models?: string[];
  }>
> = (state) => {
  state.isPostingTransfer = true;
};

const createTransferSuccessReducer: CaseReducer<
  TransfersState,
  PayloadAction<{ destinationId: ExistingDestination["id"] }>
> = (state) => {
  state.isPostingTransfer = false;
};

const createTransferFailureReducer: CaseReducer<
  TransfersState,
  PayloadAction<AppError>
> = (state) => {
  state.isPostingTransfer = false;
};

const createImportTransferReducer: CaseReducer<
  TransfersState,
  PayloadAction<{ sourceId: ExistingImportSource["id"]; fullRefresh: boolean }>
> = (state) => {
  state.isPostingTransfer = true;
};

const createImportTransferSuccessReducer: CaseReducer<
  TransfersState,
  PayloadAction<void>
> = (state) => {
  state.isPostingTransfer = false;
};

const createImportTransferFailureReducer: CaseReducer<
  TransfersState,
  PayloadAction<AppError>
> = (state) => {
  state.isPostingTransfer = false;
};

const cancelExportTransferReducer: CaseReducer<
  TransfersState,
  PayloadAction<WithRedirect<{ transferId: Transfer["id"] }>>
> = (state) => {
  state.isCancelingTransfer = true;
};

const cancelExportTransferSuccessReducer: CaseReducer<
  TransfersState,
  PayloadAction<WithRedirect<{}>>
> = (state) => {
  state.isCancelingTransfer = false;
};

const cancelExportTransferFailureReducer: CaseReducer<
  TransfersState,
  PayloadAction<AppError>
> = (state) => {
  state.isCancelingTransfer = false;
};

function* watchFetchTransfers() {
  yield takeEvery(
    fetchTransfers.type,
    createWorkerSaga(
      fetchTransfers,
      fetchTransfersSuccess,
      fetchTransfersFailure,
      TransfersService.getTransfers
    )
  );
}

function* watchFetchImportTransfers() {
  yield takeEvery(
    fetchImportTransfers.type,
    createWorkerSaga(
      fetchImportTransfers,
      fetchImportTransfersSuccess,
      fetchImportTransfersFailure,
      TransfersService.getImportTransfers
    )
  );
}

function* watchCreateTransfer() {
  yield takeEvery(
    createTransfer.type,
    createWorkerSaga(
      createTransfer,
      createTransferSuccess,
      createTransferFailure,
      TransfersService.postTransfer
    )
  );
}

function* watchCreateImportTransfer() {
  yield takeEvery(
    createImportTransfer.type,
    createWorkerSaga(
      createImportTransfer,
      createImportTransferSuccess,
      createImportTransferFailure,
      TransfersService.postImportTransfer
    )
  );
}

function* watchCancelExportTransfer() {
  yield takeEvery(
    cancelExportTransfer.type,
    createWorkerSaga(
      cancelExportTransfer,
      cancelExportTransferSuccess,
      cancelExportTransferFailure,
      TransfersService.cancelExportTransfer
    )
  );
}

function* watchCancelExportTransferSuccessOrFailure() {
  yield takeEvery(
    [cancelExportTransferFailure.type, cancelExportTransferSuccess.type],
    function* (
      action: PayloadAction<{
        destinationId: ExistingDestination["id"];
      }>
    ) {
      yield put(fetchTransfers(action.payload.destinationId));
    }
  );
  yield takeEvery(
    [cancelExportTransferFailure.type, cancelExportTransferSuccess.type],
    createRedirectSaga()
  );
}

function* watchCreateTransferSuccess() {
  yield takeEvery(createTransferSuccess.type, function* () {
    yield put(
      createToast({
        toast: {
          id: new Date().getTime().toString(),
          kind: "SUCCESS",
          message: "Transfer enqueued successfully!",
        },
      })
    );
  });
}

function* watchCreateImportTransferSuccess() {
  yield takeEvery(createImportTransferSuccess.type, function* () {
    yield put(
      createToast({
        toast: {
          id: new Date().getTime().toString(),
          kind: "SUCCESS",
          message: "Transfer enqueued successfully!",
        },
      })
    );
  });
}

function* watchCreateTransferSuccessOrFailure() {
  yield takeEvery(
    [createTransferFailure.type, createTransferSuccess.type],
    function* (
      action: PayloadAction<{
        destinationId: ExistingDestination["id"];
      }>
    ) {
      yield put(fetchTransfers(action.payload.destinationId));
    }
  );
}

function* watchCreateImportTransferSuccessOrFailure() {
  yield takeEvery(
    [createImportTransferFailure.type, createImportTransferSuccess.type],
    function* (
      action: PayloadAction<{
        sourceId: ExistingImportSource["id"] | undefined;
      }>
    ) {
      yield put(fetchImportTransfers(action.payload.sourceId));
    }
  );
}

const transfersSlice = createSlice({
  name: "transfers",
  initialState,
  reducers: {
    cancelExportTransfer: cancelExportTransferReducer,
    cancelExportTransferSuccess: cancelExportTransferSuccessReducer,
    cancelExportTransferFailure: cancelExportTransferFailureReducer,
    fetchTransfers: fetchTransfersReducer,
    fetchTransfersSuccess: fetchTransfersSuccessReducer,
    fetchTransfersFailure: fetchTransfersFailureReducer,
    fetchImportTransfers: fetchImportTransfersReducer,
    fetchImportTransfersSuccess: fetchImportTransfersSuccessReducer,
    fetchImportTransfersFailure: fetchImportTransfersFailureReducer,
    createTransfer: createTransferReducer,
    createTransferSuccess: createTransferSuccessReducer,
    createTransferFailure: createTransferFailureReducer,
    createImportTransfer: createImportTransferReducer,
    createImportTransferSuccess: createImportTransferSuccessReducer,
    createImportTransferFailure: createImportTransferFailureReducer,
  },
});

export const {
  cancelExportTransfer,
  cancelExportTransferSuccess,
  cancelExportTransferFailure,
  fetchTransfers,
  fetchTransfersSuccess,
  fetchTransfersFailure,
  fetchImportTransfers,
  fetchImportTransfersSuccess,
  fetchImportTransfersFailure,
  createTransfer,
  createTransferSuccess,
  createTransferFailure,
  createImportTransfer,
  createImportTransferSuccess,
  createImportTransferFailure,
} = transfersSlice.actions;

export const selectTransfers = (destinationId: string | undefined) => {
  return ({ transfers }: RootState) => {
    return destinationId ? transfers.transfers[destinationId] : undefined;
  };
};
export const selectImportTransfers = (sourceId: string | undefined) => {
  return ({ transfers }: RootState) => {
    return sourceId ? transfers.importTransfers[sourceId] : undefined;
  };
};
export const selectIsPostingTransfer = ({ transfers }: RootState) =>
  transfers.isPostingTransfer;
export const selectIsCancelingTransfer = ({ transfers }: RootState) =>
  transfers.isCancelingTransfer;
export const selectIsLoadingTransfers = ({ transfers }: RootState) =>
  transfers.isLoadingTransfers;
export const selectIsLoadingImportTransfers = ({ transfers }: RootState) =>
  transfers.isLoadingImportTransfers;

export function* transfersSaga() {
  yield all([
    watchFetchTransfers(),
    watchFetchImportTransfers(),
    watchCreateTransfer(),
    watchCreateImportTransfer(),
    watchCreateTransferSuccess(),
    watchCreateImportTransferSuccess(),
    watchCreateTransferSuccessOrFailure(),
    watchCreateImportTransferSuccessOrFailure(),
    watchCancelExportTransfer(),
    watchCancelExportTransferSuccessOrFailure(),
  ]);
}
export default transfersSlice.reducer;
