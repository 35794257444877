import React from "react";
import { ServiceAccountKey, getValidServiceAccountKey } from "../../lib";
import { ModelConfigColumn } from "@prequel/react";
import { RequestStatus } from "../../axios";

// Using this enum for the type values of the source interfaces
// allows us easier type detection and to create discriminated unions
// for us in multi-purpose components like SourceDetails
export enum SourceType {
  Source,
  PreparedSource,
  ExistingSource,
  ImportSource,
  PreparedImportSource,
  ExistingImportSource,
}

export interface Source {
  type: SourceType.Source;
  name: string;
  vendor: string;
  use_ssh_tunnel: boolean;
  host?: string;
  port?: string;
  database?: string;
  username?: string;
  password?: string;
  bucket_name?: string;
  bucket_region?: string;
  bucket_vendor?: string;
  aws_iam_role?: string;
  gcp_iam_role?: string;
  bucket_secret_key?: string;
  bucket_access_id?: string;
  service_account_key?: string;
  ssh_public_key?: string;
  ssh_tunnel_host?: string;
  ssh_tunnel_port?: string;
  ssh_tunnel_username?: string;
}

export interface PreparedSource {
  type: SourceType.PreparedSource;
  name: string;
  vendor: string;
  use_ssh_tunnel: boolean;
  host?: string;
  port?: number;
  database?: string;
  username?: string;
  password?: string;
  bucket_name?: string;
  bucket_region?: string;
  bucket_vendor?: string;
  aws_iam_role?: string;
  gcp_iam_role?: string;
  bucket_secret_key?: string;
  bucket_access_id?: string;
  service_account_key?: ServiceAccountKey;
  ssh_public_key?: string;
  ssh_tunnel_host?: string;
  ssh_tunnel_port?: number;
  ssh_tunnel_username?: string;
}

export default interface ExistingSource extends Omit<Source, "type"> {
  type: SourceType.ExistingSource;
  id: string;
  created_at: string;
  updated_at: string;
}

export type ImportSource = {
  type: SourceType.ImportSource;
  name: string;
  vendor: string;
  use_ssh_tunnel: boolean;
  provider_id: string;
  is_enabled: boolean;
  frequency_minutes?: string;
  host?: string;
  port?: string;
  database?: string;
  schema?: string;
  username?: string;
  password?: string;
  bucket_name?: string;
  bucket_region?: string;
  bucket_vendor?: string;
  aws_iam_role?: string;
  gcp_iam_role?: string;
  bucket_access_id?: string;
  bucket_secret_key?: string;
  service_account_key?: string;
  ssh_public_key?: string;
  ssh_tunnel_host?: string;
  ssh_tunnel_port?: string;
  ssh_tunnel_username?: string;
  enabled_models?: string[];
  model_mappings: ModelMapping[];
};

export interface PreparedImportSource {
  type: SourceType.PreparedImportSource;
  name: string;
  vendor: string;
  use_ssh_tunnel: boolean;
  provider_id: string;
  is_enabled: boolean;
  frequency_minutes?: number;
  host?: string;
  port?: number;
  database?: string;
  username?: string;
  password?: string;
  bucket_name?: string;
  bucket_region?: string;
  bucket_vendor?: string;
  aws_iam_role?: string;
  gcp_iam_role?: string;
  bucket_secret_key?: string;
  bucket_access_id?: string;
  service_account_key?: ServiceAccountKey;
  ssh_public_key?: string;
  ssh_tunnel_host?: string;
  ssh_tunnel_port?: number;
  ssh_tunnel_username?: string;
  enabled_models?: string[];
  model_mappings: ModelMapping[];
}

export interface ExistingImportSource extends Omit<ImportSource, "type"> {
  type: SourceType.ExistingImportSource;
  id: string;
  is_enabled: boolean;
  provider_id: string;
  created_at: string;
  updated_at: string;
}

export type SourceTest = {
  status: RequestStatus;
  message?: string;
};
export type AvailableTablesState = {
  status: RequestStatus;
  tables: AvailableTable[] | undefined;
};
export type TableSampleState = {
  status: RequestStatus;
  table: AvailableTable | undefined;
  rows: TableSample | undefined;
};

export type ModelMapping = {
  model_name: string;
  source_table_name: string;
  description: string;
  mappings: ModelConfigColumn[];
};

export type ExistingSourceTestPayload = {
  sourceId: ExistingSource["id"];
  fields?: Partial<PreparedSource>;
};

export type ExistingImportSourcePayload = {
  sourceId: ExistingImportSource["id"];
  fields?: Partial<PreparedImportSource>;
};

export type ExistingImportSourceWithTablePayload =
  ExistingImportSourcePayload & {
    table: AvailableTable;
  };

export const prepareSource: (s: Source) => PreparedSource = (source) => {
  const serviceAccountKey = getValidServiceAccountKey(
    source.service_account_key
  );

  const prepared: PreparedSource = {
    ...source,
    type: SourceType.PreparedSource,
    service_account_key: serviceAccountKey,
    port: parseInt(source.port || ""),
    ssh_tunnel_port: parseInt(source.ssh_tunnel_port || ""),
  };

  return prepared;
};

export const prepareImportSource: (s: ImportSource) => PreparedImportSource = (
  source
) => {
  const serviceAccountKey = getValidServiceAccountKey(
    source.service_account_key
  );

  const prepared: PreparedImportSource = {
    ...source,
    type: SourceType.PreparedImportSource,
    service_account_key: serviceAccountKey,
    frequency_minutes: parseInt(source.frequency_minutes || ""),
    port: parseInt(source.port || ""),
    ssh_tunnel_port: parseInt(source.ssh_tunnel_port || ""),
  };

  return prepared;
};

export const convertToSource: (e: ExistingSource) => Source = (existing) => ({
  ...existing,
  type: SourceType.Source,
  password: "",
  service_account_key: JSON.stringify(existing.service_account_key) ?? "",
  port: existing.port ? existing.port.toString() : "",
  ssh_tunnel_port: existing.ssh_tunnel_port
    ? existing.ssh_tunnel_port.toString()
    : "",
  use_ssh_tunnel: existing.use_ssh_tunnel ?? false,
});

export const convertToImportSource: (e: ExistingImportSource) => ImportSource =
  (existing) => ({
    ...existing,
    type: SourceType.ImportSource,
    password: "",
    service_account_key: JSON.stringify(existing.service_account_key) ?? "",
    port: existing.port ? existing.port.toString() : "",
    ssh_tunnel_port: existing.ssh_tunnel_port
      ? existing.ssh_tunnel_port.toString()
      : "",
    use_ssh_tunnel: existing.use_ssh_tunnel ?? false,
  });

const parseServiceAccountKey: (k?: string) => ServiceAccountKey | undefined = (
  keyString
) => {
  if (!keyString) {
    return undefined;
  }

  try {
    const key: ServiceAccountKey = JSON.parse(keyString);
    return key;
  } catch (e) {
    return undefined;
  }
};

export const computeChangedFields: (
  e: ExistingSource,
  s: PreparedSource
) => Partial<PreparedSource> = (existingSource, preparedSource) => {
  const changedFields: Partial<PreparedSource> = {};
  for (const key in existingSource) {
    if (
      existingSource[key as keyof ExistingSource] !==
      preparedSource[key as keyof PreparedSource]
    ) {
      Object.assign(changedFields, {
        [key]: preparedSource[key as keyof PreparedSource],
      });
    }
  }

  // Special case password because ExistingDestination does not have the password populated
  if (preparedSource.password && preparedSource.password !== "") {
    changedFields.password = preparedSource.password;
  }

  // Special case service_account_key because ExistingDestination does not have service_account_key populated
  if (preparedSource.service_account_key) {
    changedFields.service_account_key = preparedSource.service_account_key;
  }

  return changedFields;
};

export const computeChangedImportFields: (
  e: ExistingImportSource,
  s: PreparedImportSource
) => Partial<PreparedImportSource> = (existingSource, preparedSource) => {
  const changedFields: Partial<PreparedImportSource> = {};
  for (const key in existingSource) {
    if (
      existingSource[key as keyof ExistingImportSource] !==
      preparedSource[key as keyof PreparedImportSource]
    ) {
      Object.assign(changedFields, {
        [key]: preparedSource[key as keyof PreparedImportSource],
      });
    }
  }

  // Special case password because ExistingDestination does not have the password populated
  if (preparedSource.password && preparedSource.password !== "") {
    changedFields.password = preparedSource.password;
  }

  // Special case service_account_key because ExistingDestination does not have service_account_key populated
  if (preparedSource.service_account_key) {
    changedFields.service_account_key = preparedSource.service_account_key;
  }

  return changedFields;
};

export type AvailableTable = {
  table_name: string;
  schema: string;
};

export type SampleRow = {
  [key: string]: any;
};

export type TableSample = SampleRow[];
