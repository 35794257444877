import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, put, takeEvery } from "redux-saga/effects";
import ExistingRecipient, {
  ExistingSourceCredential,
  Recipient,
  SourceCredential,
  SourceCredentialTest,
} from ".";
import {
  createRedirectSaga,
  createWorkerSaga,
  RootState,
  WithRedirect,
} from "..";
import { AppError, RequestStatus } from "../../axios";
import RecipientsService from "./recipients.service";
import { createToast } from "../toasts/toasts.duck";

// Slice state
type RecipientsState = {
  recipients: ExistingRecipient[] | undefined;
  sourceCredentials: ExistingSourceCredential[] | undefined;
  sourceCredentialTest: SourceCredentialTest;
};
const initialState: RecipientsState = {
  recipients: undefined,
  sourceCredentials: undefined,
  sourceCredentialTest: { status: undefined },
};

// Action Reducers (Case Reducers)
const fetchRecipientsReducer: CaseReducer<
  RecipientsState,
  PayloadAction<void>
> = (state: RecipientsState) => state;

const fetchRecipientsSuccessReducer: CaseReducer<
  RecipientsState,
  PayloadAction<ExistingRecipient[]>
> = (state: RecipientsState, action: PayloadAction<ExistingRecipient[]>) => {
  state.recipients = action.payload;
};

const fetchRecipientsFailureReducer: CaseReducer<
  RecipientsState,
  PayloadAction<AppError>
> = (state: RecipientsState) => state;

const fetchSourceCredentialsReducer: CaseReducer<
  RecipientsState,
  PayloadAction<void>
> = (state: RecipientsState) => state;

const fetchSourceCredentialsSuccessReducer: CaseReducer<
  RecipientsState,
  PayloadAction<ExistingSourceCredential[]>
> = (
  state: RecipientsState,
  action: PayloadAction<ExistingSourceCredential[]>
) => {
  state.sourceCredentials = action.payload;
};

const fetchSourceCredentialsFailureReducer: CaseReducer<
  RecipientsState,
  PayloadAction<AppError>
> = (state: RecipientsState) => state;

const createRecipientReducer: CaseReducer<
  RecipientsState,
  PayloadAction<WithRedirect<{ recipient: Recipient }>>
> = (state: RecipientsState) => state;

const createRecipientSuccessReducer: CaseReducer<
  RecipientsState,
  PayloadAction<WithRedirect<{ recipient: ExistingRecipient }>>
> = (state: RecipientsState) => state;

const createRecipientFailureReducer: CaseReducer<
  RecipientsState,
  PayloadAction<AppError>
> = (state: RecipientsState) => state;

const createSourceCredentialReducer: CaseReducer<
  RecipientsState,
  PayloadAction<WithRedirect<{ sourceCredential: SourceCredential }>>
> = (state: RecipientsState) => state;

const createSourceCredentialSuccessReducer: CaseReducer<
  RecipientsState,
  PayloadAction<WithRedirect<{ sourceCredential: ExistingSourceCredential }>>
> = (state: RecipientsState) => state;

const createSourceCredentialFailureReducer: CaseReducer<
  RecipientsState,
  PayloadAction<AppError>
> = (state: RecipientsState) => state;

const testSourceCredentialReducer: CaseReducer<
  RecipientsState,
  PayloadAction<SourceCredential>
> = (state: RecipientsState) => {
  state.sourceCredentialTest.status = "processing";
};

const testSourceCredentialSuccessReducer: CaseReducer<
  RecipientsState,
  PayloadAction<RequestStatus>
> = (state: RecipientsState, action: PayloadAction<RequestStatus>) => {
  state.sourceCredentialTest.status = action.payload;
};

const testSourceCredentialFailureReducer: CaseReducer<
  RecipientsState,
  PayloadAction<AppError>
> = (state: RecipientsState, action: PayloadAction<AppError>) => {
  state.sourceCredentialTest = {
    status: "error",
    message: action.payload.error.message,
  };
};

const resetSourceCredentialTestReducer: CaseReducer<
  RecipientsState,
  PayloadAction<void>
> = (state) => {
  state.sourceCredentialTest.status = undefined;
};

function* watchFetchRecipients() {
  yield takeEvery(
    fetchRecipients.type,
    createWorkerSaga(
      fetchRecipients,
      fetchRecipientsSuccess,
      fetchRecipientsFailure,
      RecipientsService.getRecipients
    )
  );
}

function* watchFetchSourceCredentialsReducer() {
  yield takeEvery(
    fetchSourceCredentials.type,
    createWorkerSaga(
      fetchSourceCredentials,
      fetchSourceCredentialsSuccess,
      fetchSourceCredentialsFailure,
      RecipientsService.getSourceCredentials
    )
  );
}

function* watchCreateRecipient() {
  yield takeEvery(
    createRecipient.type,
    createWorkerSaga(
      createRecipient,
      createRecipientSuccess,
      createRecipientFailure,
      RecipientsService.postRecipient
    )
  );
}

function* watchCreateSourceCredential() {
  yield takeEvery(
    createSourceCredential.type,
    createWorkerSaga(
      createSourceCredential,
      createSourceCredentialSuccess,
      createSourceCredentialFailure,
      RecipientsService.postSourceCredential
    )
  );
}

function* watchCreateRecipientSuccess() {
  yield takeEvery(createRecipientSuccess.type, function* () {
    yield put(fetchRecipients());
    yield put(
      createToast({
        toast: {
          id: new Date().getTime().toString(),
          kind: "SUCCESS",
          message: "Recipient created successfully!",
        },
      })
    );
  });
  yield takeEvery(createRecipientSuccess.type, createRedirectSaga());
}

function* watchCreateSourceCredentialSuccess() {
  yield takeEvery(createSourceCredentialSuccess.type, function* () {
    yield put(fetchSourceCredentials());
    yield put(
      createToast({
        toast: {
          id: new Date().getTime().toString(),
          kind: "SUCCESS",
          message: "Source credential created successfully!",
        },
      })
    );
  });
  yield takeEvery(createSourceCredentialSuccess.type, createRedirectSaga());
}

function* watchTestSourceCredential() {
  yield takeEvery(
    testSourceCredential.type,
    createWorkerSaga(
      testSourceCredential,
      testSourceCredentialSuccess,
      testSourceCredentialFailure,
      RecipientsService.testSourceCredentials
    )
  );
}

const recipientsSlice = createSlice({
  name: "recipients",
  initialState,
  reducers: {
    fetchRecipients: fetchRecipientsReducer,
    fetchRecipientsSuccess: fetchRecipientsSuccessReducer,
    fetchRecipientsFailure: fetchRecipientsFailureReducer,
    fetchSourceCredentials: fetchSourceCredentialsReducer,
    fetchSourceCredentialsSuccess: fetchSourceCredentialsSuccessReducer,
    fetchSourceCredentialsFailure: fetchSourceCredentialsFailureReducer,
    createRecipient: createRecipientReducer,
    createRecipientSuccess: createRecipientSuccessReducer,
    createRecipientFailure: createRecipientFailureReducer,
    testSourceCredential: testSourceCredentialReducer,
    testSourceCredentialSuccess: testSourceCredentialSuccessReducer,
    testSourceCredentialFailure: testSourceCredentialFailureReducer,
    createSourceCredential: createSourceCredentialReducer,
    createSourceCredentialSuccess: createSourceCredentialSuccessReducer,
    createSourceCredentialFailure: createSourceCredentialFailureReducer,
    resetSourceCredentialTest: resetSourceCredentialTestReducer,
  },
});

export const {
  fetchRecipients,
  fetchRecipientsSuccess,
  fetchRecipientsFailure,
  fetchSourceCredentials,
  fetchSourceCredentialsSuccess,
  fetchSourceCredentialsFailure,
  createRecipient,
  createRecipientSuccess,
  createRecipientFailure,
  testSourceCredential,
  testSourceCredentialSuccess,
  testSourceCredentialFailure,
  createSourceCredential,
  createSourceCredentialSuccess,
  createSourceCredentialFailure,
  resetSourceCredentialTest,
} = recipientsSlice.actions;

export const selectRecipients = ({ recipients }: RootState) =>
  recipients.recipients;
export const selectSourceCredentials = ({ recipients }: RootState) =>
  recipients.sourceCredentials;
export const selectSourceCredentialTest = ({ recipients }: RootState) =>
  recipients.sourceCredentialTest;

export function* recipientsSaga() {
  yield all([
    watchFetchRecipients(),
    watchCreateRecipient(),
    watchCreateRecipientSuccess(),
    watchFetchSourceCredentialsReducer(),
    watchTestSourceCredential(),
    watchCreateSourceCredential(),
    watchCreateSourceCredentialSuccess(),
  ]);
}
export default recipientsSlice.reducer;
