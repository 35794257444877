import React from "react";
import {
  Button,
  ButtonStyle,
  Spinner,
} from "@prequel-internal/react-components";
import { ReactComponent as AlertCircleIcon } from "../../../assets/icons/alert-circle.svg";
import { ReactComponent as AlertTriangleIcon } from "../../../assets/icons/alert-triangle.svg";
import { ReactComponent as CheckIcon } from "../../../assets/icons/check.svg";
import { ReactComponent as EllipsisIcon } from "../../../assets/icons/ellipsis.svg";

import {
  AvailableTable,
  ExistingImportSource,
  ExistingImportSourceWithTablePayload,
  PreparedImportSource,
  computeChangedImportFields,
} from "../../../store/sources";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  fetchExistingSourceTableSample,
  fetchTableSample,
  selectAvailableTables,
  selectTableSample,
} from "../../../store/sources/sources.duck";
import ResultsTable from "../ResultsTable";

type TableSampleProps = {
  source: PreparedImportSource;
  table: AvailableTable;
  sourceToEdit: ExistingImportSource | null | undefined;
  isEditing: boolean;
};
const TableSample = ({
  source,
  table,
  sourceToEdit,
  isEditing,
}: TableSampleProps) => {
  const dispatch = useTypedDispatch();
  const tableSample = useTypedSelector(selectTableSample);
  const availableTables = useTypedSelector(selectAvailableTables);

  const getTableSample = () => {
    if (isEditing && sourceToEdit) {
      const payload: ExistingImportSourceWithTablePayload = {
        sourceId: sourceToEdit.id,
        table,
      };
      const changed = computeChangedImportFields(sourceToEdit, source);
      if (Object.keys(changed).length > 0) {
        payload.fields = changed;
      }
      dispatch(fetchExistingSourceTableSample(payload));
    } else {
      dispatch(
        fetchTableSample({
          source,
          table,
        })
      );
    }
  };

  return (
    <div>
      <div className="relative mb-4">
        <div className="border border-gray-300 rounded-lg shadow-sm overflow-hidden focus-within:border-primary-500 focus-within:ring-1 focus-within:ring-primary-500">
          <div className="p-4">
            <div className="flex">
              <div className="flex-shrink-0">
                <div
                  className={`mx-auto flex items-center justify-center h-10 w-10 rounded-full
                  ${tableSample.status === "success" ? "bg-emerald-100" : ""}
                  ${tableSample.status === "error" ? "bg-red-100" : ""}
                  ${tableSample.status === "processing" ? "bg-gray-100" : ""}
                  ${!tableSample.status ? "bg-gray-100" : ""}`}
                >
                  {tableSample.status === "success" && (
                    <CheckIcon
                      className="h-5 w-5 text-emerald-600"
                      aria-hidden="true"
                    />
                  )}
                  {tableSample.status === "error" && (
                    <AlertCircleIcon
                      className="h-5 w-5 text-red-600"
                      aria-hidden="true"
                    />
                  )}
                  {tableSample.status === "processing" && (
                    <EllipsisIcon
                      className="h-5 w-5 text-gray-600"
                      aria-hidden="true"
                    />
                  )}
                  {!tableSample.status && (
                    <AlertTriangleIcon
                      className="h-5 w-5 text-gray-600"
                      aria-hidden="true"
                    />
                  )}
                </div>
              </div>
              <div className="ml-3 mt-1">
                <h3 className="text-lg font-medium text-gray-800">
                  {tableSample.status === "error" &&
                    "Error connecting to source. Try updating the connection details and try again."}
                  {tableSample.status === "processing" && "Running query..."}
                  {!tableSample.status &&
                    "Run query & preview results before continuing."}
                  {tableSample.status === "success" &&
                    "Table sample successfully generated."}
                  {!tableSample.status && ""}
                </h3>
              </div>
            </div>
          </div>
          <div aria-hidden="true">
            <div className="h-px" />
            <div className="py-2">
              <div className="py-px">
                <div className="h-9" />
              </div>
            </div>
          </div>
          <div className="absolute bottom-0 inset-x-px">
            <div className="border-t border-gray-200 px-2 py-2 flex justify-between items-center space-x-3 sm:px-3">
              <div className="flex">
                <div className="flex-shrink-0">
                  <Button
                    onClick={getTableSample}
                    type={ButtonStyle.PRIMARY}
                    disabled={
                      tableSample.status === "processing" ||
                      availableTables.status !== "success"
                    }
                    text={
                      tableSample.status === "processing" ? (
                        <div className="flex">
                          <Spinner.Inline className="mr-3 text-white" />
                          Sampling table...
                        </div>
                      ) : (
                        "Sample Table"
                      )
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {tableSample.status === "success" && tableSample.rows && (
        <ResultsTable rows={tableSample.rows} />
      )}
    </div>
  );
};

export default TableSample;
