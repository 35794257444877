import React, { useEffect, useState } from "react";
import { ModelConfig, ModelConfigColumn } from "@prequel/react";
import { ReactComponent as KeyIcon } from "../../../assets/icons/key.svg";
import { ReactComponent as ClockIcon } from "../../../assets/icons/clock.svg";

import { useTypedSelector } from "../../../store";
import { selectTableSample } from "../../../store/sources/sources.duck";
import {
  Badge,
  BadgeColor,
  Dropdown,
  DropdownListItem,
} from "@prequel-internal/react-components";

type ImportMapFormProps = {
  config: ModelConfig;
  destinationToSourceMap: {
    [key: string]: DropdownListItem<string> | undefined;
  };
  setDestinationToSourceMap: React.Dispatch<
    React.SetStateAction<{
      [key: string]: DropdownListItem<string> | undefined;
    }>
  >;
  setCanGoForward: React.Dispatch<React.SetStateAction<boolean>>;
};
const ImportMapForm = ({
  config,
  destinationToSourceMap,
  setDestinationToSourceMap,
  setCanGoForward,
}: ImportMapFormProps) => {
  const tableSample = useTypedSelector(selectTableSample);
  const sampleColumns = tableSample.rows?.length
    ? Object.keys(tableSample.rows[0])
    : [];
  const [dropdownItems, setDropdownItems] =
    useState<DropdownListItem<string>[]>();

  useEffect(() => {
    // Sets up the map to be { name_in_destination: "" } so we can set the values in the dropdowns
    const newMap = config.columns.reduce(
      (
        acc: { [key: string]: DropdownListItem<string> | undefined },
        obj: ModelConfigColumn
      ) => ({
        ...acc,
        [obj.name_in_destination]: undefined,
      }),
      {}
    );
    setDestinationToSourceMap(newMap);
  }, [config]);

  useEffect(() => {
    if (tableSample.status === "success" && tableSample.rows) {
      const columns = Object.keys(tableSample.rows[0]);
      const items = columns.map((col) => ({
        key: col,
        text: col,
      }));
      setDropdownItems(items);
    }
  }, [tableSample]);

  useEffect(() => {
    const allAssigned = Object.values(destinationToSourceMap).every(
      (sourceItem) => sourceItem !== undefined
    );

    setCanGoForward(
      allAssigned && Object.values(destinationToSourceMap).length > 0
    );
  }, [destinationToSourceMap]);

  const setDestinationToSourceValue = (
    destinationName: string,
    sourceName: DropdownListItem<string>
  ) => {
    setDestinationToSourceMap((oldMap) => ({
      ...oldMap,
      [destinationName]: sourceName,
    }));
  };

  return (
    <form
      // onSubmit={onSubmit}
      className="bg-white border border-gray-200 rounded-lg"
    >
      <div className="px-4 py-5">
        <h2
          id="applicant-information-title"
          className="text-lg font-medium leading-6 text-gray-900"
        >
          Map Data Columns
        </h2>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Assign data columns to expected schema.
        </p>
      </div>
      <div className="space-y-4 border-t border-gray-200 p-4">
        <div>
          <label className="text-base font-medium text-gray-900">
            Source Table
          </label>
          <div className="overflow-hidden rounded-lg border border-gray-200 focus-within:border-indigo-500 focus-within:ring-1 focus-within:ring-indigo-500">
            <div
              className="block w-full resize-none border-0 p-4 text-gray-900 text-sm sm:leading-6"
              placeholder="Write a description..."
              defaultValue={""}
            >
              <div className="flex-1">
                <p className="text-sm font-medium text-gray-500">
                  Detected Columns{" "}
                </p>

                <dd className="text-sm text-gray-900 mt-2 space-y-2">
                  {sampleColumns.map((column) => (
                    <Badge
                      key={column}
                      className="mr-2"
                      text={column}
                      color={BadgeColor.GREEN}
                    />
                  ))}
                </dd>
              </div>
            </div>
          </div>
        </div>
        <div>
          <label className="text-base font-medium text-gray-900">
            Mapped Columns
          </label>
          <dd className="mt-1.5 text-sm text-gray-900">
            <ul
              role="list"
              className="divide-y divide-gray-200 rounded-md border border-gray-200"
            >
              {config.columns.map((column) => (
                <li
                  key={`${config.model_name}-${column.name_in_destination}`}
                  className="grid grid-cols-2 gap-x-4 p-3"
                >
                  <div className="col-span-1">
                    <dd className="mt-1 text-sm text-gray-900 mt-2 truncate">
                      <div className="font-mono bg-gray-100 mr-1 px-1.5 border rounded-md border-gray-200 inline-flex items-center">
                        {column.name_in_destination}
                        {column.is_primary_key && (
                          <KeyIcon className="ml-2 text-emerald-500 w-4 h-4" />
                        )}
                        {column.is_last_modified && (
                          <ClockIcon className="ml-2 text-emerald-500 w-4 h-4" />
                        )}
                      </div>
                      <span className="font-mono text-zinc-400 dark:text-zinc-500">
                        {column.data_type}
                      </span>
                    </dd>
                  </div>
                  <div className="col-span-1">
                    {dropdownItems && (
                      <Dropdown
                        items={dropdownItems}
                        selectedItem={
                          destinationToSourceMap[column.name_in_destination]
                        }
                        setSelectedItem={(item: DropdownListItem<string>) =>
                          setDestinationToSourceValue(
                            column.name_in_destination,
                            item
                          )
                        }
                      />
                    )}
                  </div>
                </li>
              ))}
            </ul>
          </dd>
        </div>
      </div>
    </form>
  );
};

export default ImportMapForm;
