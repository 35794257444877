import { CaseReducer, PayloadAction, createSlice } from "@reduxjs/toolkit";
import ExistingProvider, { Provider } from ".";
import { AppError } from "../../axios";
import { all, put, takeEvery } from "redux-saga/effects";
import {
  RootState,
  WithRedirect,
  createRedirectSaga,
  createWorkerSaga,
} from "..";
import ProvidersService from "./providers.service";
import { createToast } from "../toasts/toasts.duck";

type ProvidersState = {
  providers: ExistingProvider[] | undefined;
};
const initialState: ProvidersState = {
  providers: undefined,
};

// Action Reducers (Case Reducers)
const fetchProvidersReducer: CaseReducer<
  ProvidersState,
  PayloadAction<void>
> = (state: ProvidersState) => state;

const fetchProvidersSuccessReducer: CaseReducer<
  ProvidersState,
  PayloadAction<ExistingProvider[]>
> = (state: ProvidersState, action: PayloadAction<ExistingProvider[]>) => {
  state.providers = action.payload;
};

const fetchProvidersFailureReducer: CaseReducer<
  ProvidersState,
  PayloadAction<AppError>
> = (state: ProvidersState) => state;

const createProviderReducer: CaseReducer<
  ProvidersState,
  PayloadAction<WithRedirect<{ provider: Provider }>>
> = (state: ProvidersState) => state;

const createProviderSuccessReducer: CaseReducer<
  ProvidersState,
  PayloadAction<WithRedirect<{ provider: ExistingProvider }>>
> = (state: ProvidersState) => state;

const createProviderFailureReducer: CaseReducer<
  ProvidersState,
  PayloadAction<AppError>
> = (state: ProvidersState) => state;

function* watchFetchProviders() {
  yield takeEvery(
    fetchProviders.type,
    createWorkerSaga(
      fetchProviders,
      fetchProvidersSuccess,
      fetchProvidersFailure,
      ProvidersService.getProviders
    )
  );
}

function* watchCreateProvider() {
  yield takeEvery(
    createProvider.type,
    createWorkerSaga(
      createProvider,
      createProviderSuccess,
      createProviderFailure,
      ProvidersService.postProvider
    )
  );
}

function* watchCreateProviderSuccess() {
  yield takeEvery(createProviderSuccess.type, function* () {
    yield put(fetchProviders());
    yield put(
      createToast({
        toast: {
          id: new Date().getTime().toString(),
          kind: "SUCCESS",
          message: "Provider created successfully!",
        },
      })
    );
  });
  yield takeEvery(createProviderSuccess.type, createRedirectSaga());
}

const providersSlice = createSlice({
  name: "providers",
  initialState,
  reducers: {
    fetchProviders: fetchProvidersReducer,
    fetchProvidersSuccess: fetchProvidersSuccessReducer,
    fetchProvidersFailure: fetchProvidersFailureReducer,
    createProvider: createProviderReducer,
    createProviderSuccess: createProviderSuccessReducer,
    createProviderFailure: createProviderFailureReducer,
  },
});

export const {
  fetchProviders,
  fetchProvidersSuccess,
  fetchProvidersFailure,
  createProvider,
  createProviderSuccess,
  createProviderFailure,
} = providersSlice.actions;

export const selectProviders = ({ providers }: RootState) =>
  providers.providers;

export function* providersSaga() {
  yield all([
    watchFetchProviders(),
    watchCreateProvider(),
    watchCreateProviderSuccess(),
  ]);
}

export default providersSlice.reducer;
