import React from "react";
import { Navigate, useRoutes } from "react-router-dom";

import OverviewPage from "./containers/OverviewPage";
import ExportMagicLinksPage from "./containers/ExportMagicLinksPage";
import ModelsPage from "./containers/ModelsPage";
import FirstPartyConnectionsPage from "./containers/FirstPartyConnectionsPage";
import RecipientsPage from "./containers/RecipientsPage";
import ThirdPartyConnectionsPage from "./containers/ThirdPartyConnectionsPage";
import { Domain } from "./lib";

const ExportApp = () => {
  const element = useRoutes([
    {
      path: "overview/*",
      element: <OverviewPage />,
    },
    {
      path: "/sources/*",
      element: <FirstPartyConnectionsPage domain={Domain.EXPORT} />,
    },
    {
      path: "/recipients/*",
      element: <RecipientsPage />,
    },
    {
      path: "destinations/*",
      element: <ThirdPartyConnectionsPage domain={Domain.EXPORT} />,
    },
    { path: "magic-links/*", element: <ExportMagicLinksPage /> },
    { path: "models/*", element: <ModelsPage /> },
    { path: "*", element: <Navigate to="overview" replace /> },
  ]);

  return element;
};

export default ExportApp;
