import React, { useEffect } from "react";
import { ModelConfigColumn } from "@prequel/react";
import {
  Button,
  ButtonStyle,
  DescriptionList,
  InlineText,
  PageHeading,
  Table,
  Badge,
  BadgeColor,
  Spinner,
} from "@prequel-internal/react-components";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { useTypedDispatch, useTypedSelector } from "../../store";
import {
  selectIsValidatingModel,
  fetchModels,
  selectModel,
  validateModel,
} from "../../store/models/models.duck";

const ModelConfigPage = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const { modelId } = useParams<{ modelId: string }>();

  const model = useTypedSelector((state) => selectModel(state, modelId));
  const isValidatingModel = useTypedSelector(selectIsValidatingModel);

  useEffect(() => {
    dispatch(fetchModels());
  }, [dispatch]);

  if (model === undefined) {
    return <Spinner />;
  }

  if (model === null) {
    return <Navigate to="/export/models" replace />;
  }

  const rows = [...model.columns].map((column: ModelConfigColumn) => ({
    id: column.name_in_source,
    cells: [
      {
        value: (
          <>
            {column.name_in_source}
            {column.is_primary_key && (
              <Badge
                text="Primary Key"
                color={BadgeColor.GREEN}
                className="ml-1"
              />
            )}
            {column.is_last_modified && (
              <Badge
                text="Last Modified"
                color={BadgeColor.GREEN}
                className="ml-1"
              />
            )}
          </>
        ),
      },
      {
        value: column.name_in_destination,
      },
      {
        value: (
          <>
            {column.description !== "" ? (
              column.description
            ) : (
              <span className="flex-grow text-gray-300 italic">undefined</span>
            )}
          </>
        ),
      },
      {
        value: <InlineText value={column.data_type} />,
      },
    ],
  }));

  const columns = [
    "Name in Source",
    "Name in Destination",
    "Description",
    "Data Type",
  ];

  const onValidate = () => {
    dispatch(validateModel(model));
  };

  return (
    <>
      <PageHeading title={model?.model_name} onGoBack={() => navigate(-1)}>
        <Button
          text={
            isValidatingModel ? (
              <div className="flex">
                <Spinner.Inline className="mr-3 text-emerald-600" /> Validating
              </div>
            ) : (
              "Validate Model"
            )
          }
          type={ButtonStyle.TERTIARY}
          onClick={onValidate}
        />
      </PageHeading>
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Details
          </h3>
        </div>
        <div className="mt-6">
          <DescriptionList>
            <DescriptionList.Item
              label="Description"
              value={model.description}
            />
            <DescriptionList.Item
              label="Source Table"
              value={model.source_table}
            />
            <DescriptionList.Item
              label="Organization Column"
              value={model.organization_column}
            />
            <DescriptionList.Item
              label="Source Name"
              value={model.source_name}
            />
          </DescriptionList>
        </div>
      </div>
      <div className="mt-10">
        <div className="space-y-1 mb-3">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Columns
          </h3>
        </div>
        <div className="mt-6">
          <Table
            columns={columns}
            rows={rows}
            footer={`${rows.length} columns`}
          />
        </div>
      </div>
    </>
  );
};

export default ModelConfigPage;
