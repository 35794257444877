import React, { useEffect, useState } from "react";
import { Destination } from "@prequel/react";
import {
  Checkbox,
  RadioCardOption,
  RadioCards,
  SectionExpandable,
} from "@prequel-internal/react-components";

import { useTypedSelector } from "../../../store";
import { selectOrg } from "../../../store/org/org.duck";
import { getFrequencyLabel, getFrequencyOptions } from "../../../store/org";

type AdvancedOptionsSectionProps = {
  destination: Destination;
  setDestinationField: (
    key: keyof Destination,
    value: string | boolean | string[]
  ) => void;
  disabled: boolean;
  isEditing: boolean;
};
const AdvancedOptionsSection = ({
  destination,
  setDestinationField,
  disabled,
  isEditing,
}: AdvancedOptionsSectionProps) => {
  const org = useTypedSelector(selectOrg);
  const [frequencyOptions, setFrequencyOptions] =
    useState<RadioCardOption<number>[]>();

  useEffect(() => {
    // When we load the org, set the frequency options
    if (org && !frequencyOptions) {
      setFrequencyOptions(
        getFrequencyOptions(org.fastest_allowed_frequency_minutes)
      );
    }
  }, [org]);

  return (
    <SectionExpandable label="Advanced options">
      {/* Using separate label so checkbox can be underneath it */}
      <div className="flex items-center justify-between">
        <h2 className={"text-sm font-medium text-gray-900 mb-3"}>
          Sync Frequency
        </h2>
      </div>
      <Checkbox
        key="use_default_frequency"
        id="use_default_frequency"
        label={
          <span>
            Use default frequency
            {org && getFrequencyLabel(org.default_frequency_minutes) ? (
              <span className="text-gray-400 font-normal">
                {" "}
                ({getFrequencyLabel(org.default_frequency_minutes)})
              </span>
            ) : (
              ""
            )}
          </span>
        }
        checked={destination.frequency_minutes === "0"}
        setChecked={(isChecked: boolean) =>
          isChecked
            ? setDestinationField("frequency_minutes", "0")
            : setDestinationField(
                "frequency_minutes",
                org?.default_frequency_minutes.toString() ?? "1440"
              )
        }
        disabled={disabled}
      />
      <RadioCards
        options={frequencyOptions ?? []}
        selectedOption={frequencyOptions?.find(
          ({ value }) => value.toString() === destination.frequency_minutes
        )}
        setSelectedOption={({ value }) =>
          setDestinationField("frequency_minutes", value.toString())
        }
        disabled={disabled || destination.frequency_minutes === "0"}
      />
    </SectionExpandable>
  );
};

export default AdvancedOptionsSection;
