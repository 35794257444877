import React, { useMemo } from "react";
import {
  FormField,
  PasswordDot,
  TextArea,
} from "@prequel-internal/react-components";
import { Destination, VendorField } from "@prequel/react";

import {
  DropdownListItemWithFields,
  getValidServiceAccountKey,
} from "../../lib";
import { ImportDestination } from "../../store/destinations";
import IamTrustPolicy from "../IamTrustPolicy";
import IamRole from "../IamRole";

type CredentialsFieldsProps = {
  selectedVendor: DropdownListItemWithFields;
  formFields: { [key: string]: VendorField };
  destination: ImportDestination;
  setDestinationField: (
    key: keyof ImportDestination,
    value: string | string[] | boolean
  ) => void;
  isEditing: boolean;
  disabled?: boolean;
};
const CredentialsFields = ({
  selectedVendor,
  formFields,
  destination,
  setDestinationField,
  isEditing,
  disabled,
}: CredentialsFieldsProps) => {
  // On service_account_key changes, attempt to coerce the string into the JSON object
  const tokenIsValid = useMemo(
    () => !!getValidServiceAccountKey(destination.service_account_key),
    [destination.service_account_key]
  );

  return (
    <>
      <div>
        <label className="block text-sm font-medium text-gray-700">
          Enter the destination credentials
        </label>
        <div className="mt-1">
          <p className="mt-1 text-sm text-gray-500">
            {`Provide the details shared by the owner of the ${selectedVendor.text} destination
        in the form below. For assistance, `}
            <a
              href={selectedVendor.docs}
              target="_blank"
              rel="noreferrer"
              className="font-medium text-primary-600 hover:text-primary-500"
            >
              view our documentation on {selectedVendor.text}.
            </a>
          </p>
        </div>
      </div>
      {"host" in formFields && (
        <FormField
          id="host"
          type="text"
          label={formFields.host.label}
          placeholder={formFields.host.placeholder}
          subtext={formFields.host.help}
          value={destination?.host}
          onChangeHandler={(value: string) => {
            setDestinationField("host", value);
          }}
          required={formFields.host.is_required}
          disabled={disabled || isEditing}
        />
      )}
      {"port" in formFields && (
        <FormField
          id="port"
          type="text"
          label={formFields.port.label}
          placeholder={formFields.port.placeholder}
          subtext={formFields.port.help}
          value={destination?.port}
          onChangeHandler={(value: string) => {
            setDestinationField("port", value);
          }}
          required={formFields.port.is_required}
          disabled={disabled}
        />
      )}
      {"cluster" in formFields && (
        <FormField
          id="cluster"
          type="text"
          label={formFields.cluster.label}
          placeholder={formFields.cluster.placeholder}
          subtext={formFields.cluster.help}
          value={destination?.cluster}
          onChangeHandler={(value: string) => {
            setDestinationField("cluster", value);
          }}
          required={formFields.cluster.is_required}
          disabled={disabled}
        />
      )}
      {"database" in formFields && (
        <FormField
          id="database"
          type="text"
          label={formFields.database.label}
          placeholder={formFields.database.placeholder}
          subtext={formFields.database.help}
          value={destination?.database}
          onChangeHandler={(value: string) => {
            setDestinationField("database", value);
          }}
          required={formFields.database.is_required}
          disabled={disabled}
        />
      )}
      {"schema" in formFields && (
        <FormField
          id="schema"
          type="text"
          label={formFields.schema.label}
          placeholder={formFields.schema.placeholder}
          subtext={formFields.schema.help}
          value={destination?.schema}
          onChangeHandler={(value: string) => {
            setDestinationField("schema", value);
          }}
          required={formFields.schema.is_required}
          disabled={disabled}
        />
      )}
      {"username" in formFields && (
        <FormField
          id="username"
          type="text"
          label={formFields.username.label}
          placeholder={formFields.username.placeholder}
          subtext={formFields.username.help}
          value={destination?.username}
          onChangeHandler={(value: string) => {
            setDestinationField("username", value);
          }}
          required={formFields.username.is_required}
          disabled={disabled}
        />
      )}
      {"password" in formFields && (
        <FormField
          id="password"
          type="password"
          label={formFields.password.label}
          placeholder={
            isEditing ? PasswordDot.repeat(20) : formFields.password.placeholder
          }
          overwriteOnly={isEditing}
          subtext={formFields.password.help}
          value={destination?.password}
          onChangeHandler={(value: string) => {
            setDestinationField("password", value);
          }}
          required={formFields.password.is_required && !isEditing}
          disabled={disabled}
        />
      )}
      {"service_account_key" in formFields && (
        <TextArea
          id="service_account_key"
          placeholder={
            isEditing
              ? PasswordDot.repeat(184)
              : formFields.service_account_key.placeholder
          }
          overwriteOnly={isEditing}
          subtext={formFields.service_account_key.help}
          value={destination?.service_account_key}
          onChangeHandler={(value: string) => {
            setDestinationField("service_account_key", value);
          }}
          invalid={!tokenIsValid}
          required={formFields.service_account_key.is_required && !isEditing}
          disabled={disabled}
        />
      )}
      {"gcp_iam_role" in formFields && (
        <>
          <IamRole role={selectedVendor.role} />
          <FormField
            id="gcp_iam_role"
            type="text"
            label={formFields.gcp_iam_role.label}
            placeholder={formFields.gcp_iam_role.placeholder}
            subtext={formFields.gcp_iam_role.help}
            value={destination?.gcp_iam_role}
            onChangeHandler={(value: string) => {
              setDestinationField("gcp_iam_role", value);
            }}
            required={formFields.gcp_iam_role.is_required}
            disabled={disabled}
          />
        </>
      )}
      {"aws_iam_role" in formFields && (
        <>
          <IamTrustPolicy iamTrustPolicy={selectedVendor.aws_trust_policy} />
          <FormField
            id="aws_iam_role"
            type="text"
            label={formFields.aws_iam_role.label}
            placeholder={formFields.aws_iam_role.placeholder}
            subtext={formFields.aws_iam_role.help}
            value={destination?.aws_iam_role}
            onChangeHandler={(value: string) => {
              setDestinationField("aws_iam_role", value);
            }}
            required={formFields.aws_iam_role.is_required}
            disabled={disabled}
          />
        </>
      )}
    </>
  );
};

export default CredentialsFields;
