import axios, { ApiResponse, ApiRoutes, ErrorResponse } from "../../axios";
import { ModelConfig, ProductConfig } from "@prequel/react";

const getImportModelConfigs: () => Promise<ModelConfig[]> = () => {
  return axios
    .get(ApiRoutes.IMPORT_MODELS)
    .then((response: ApiResponse<{ models: ModelConfig[] }>) =>
      // Only return one config for now because that is a required limit for v0
      response.data.data.models.length > 0
        ? response.data.data.models.slice(0, 1)
        : []
    )
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message:
            reason.response?.data?.message || "Failed to fetch model configs.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const getImportProductConfigs: () => Promise<ProductConfig[]> = () => {
  return axios
    .get(ApiRoutes.IMPORT_PRODUCTS)
    .then(
      (response: ApiResponse<{ products: ProductConfig[] }>) =>
        response.data.data.products
    )
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message:
            reason.response?.data?.message ||
            "Failed to fetch import product configs.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const ConfigsService = {
  getImportModelConfigs,
  getImportProductConfigs,
};
export default ConfigsService;
