import React, { useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDestinationVendors, VendorField } from "@prequel/react";
import {
  Button,
  ButtonStyle,
  Dropdown,
  FormField,
} from "@prequel-internal/react-components";

import {
  ImportDestinationType,
  ImportDestination,
  PreparedImportDestination,
  computeChangedImportFields,
  convertToImportDestination,
  prepareImportDestination,
} from "../../store/destinations";
import { useTypedDispatch, useTypedSelector } from "../../store";
import { DropdownListItemWithFields, Vendor } from "../../lib";
import VendorLogo from "../VendorLogo";
import {
  createImportDestination,
  fetchImportDestinations,
  selectDestinationTest,
  selectImportDestination,
  updateImportDestination,
} from "../../store/destinations/destinations.duck";
import CredentialsFields from "../DestinationCredentialsFields";
import SSHTunnelForm from "../SSHTunnelForm";
import ServiceAccount from "../ServiceAccount";
import TestDestinationConnection from "../TestDestinationConnection";
import BucketFields from "../DestinationBucketFields";
import { fetchOrg, selectOrg } from "../../store/org/org.duck";
import { env } from "../../env";

const ImportDestinationForm = () => {
  const navigate = useNavigate();
  const { connectionId } = useParams<{ connectionId: string }>();
  const destinationToEdit = useTypedSelector((state) =>
    selectImportDestination(state, connectionId)
  );
  const org = useTypedSelector(selectOrg);
  const isEditing = !!destinationToEdit; // Evaluates to true if destinationToEdit exists, otherwise evaluates to false
  const formRef = useRef<HTMLFormElement>(null);
  const dispatch = useTypedDispatch();
  const destinationVendors = useDestinationVendors(
    env.REACT_APP_API_SERVER,
    org?.id
  );
  const destinationTest = useTypedSelector(selectDestinationTest);

  const [vendorOptions, setVendorOptions] =
    useState<DropdownListItemWithFields[]>();
  const [selectedVendor, setSelectedVendor] =
    useState<DropdownListItemWithFields>({
      key: "",
      text: "",
      fields: [],
      docs: "",
      uses_staging_bucket: false,
      uses_service_account: false,
      supports_ssh_tunnel: false,
    });
  const [destination, setDestination] = useState<ImportDestination>({
    type: ImportDestinationType.ImportDestination,
    vendor: selectedVendor.key,
    name: "",
    host: "",
    port: "",
    database: "",
    schema: "",
    username: "",
    password: "",
    service_account_key: "",
    bucket_vendor: "",
    bucket_name: "",
    bucket_region: "",
    bucket_access_id: "",
    bucket_secret_key: "",
    aws_iam_role: "",
    is_bucket_credentials_implicit: false,
    use_ssh_tunnel: false,
    ssh_public_key: "",
    ssh_tunnel_host: "",
    ssh_tunnel_port: "",
    ssh_tunnel_username: "",
  });

  const formFields: { [key: string]: VendorField } = useMemo(() => {
    return selectedVendor.fields.reduce(
      (acc, obj) => ({ ...acc, [obj.name]: obj }),
      {}
    );
  }, [selectedVendor]);

  const preparedDestination: PreparedImportDestination = useMemo(
    () => prepareImportDestination(destination),
    [destination]
  );

  useEffect(() => {
    dispatch(fetchImportDestinations());
    dispatch(fetchOrg());
  }, [dispatch]);

  useEffect(() => {
    if (destinationVendors?.destinations && !vendorOptions) {
      let hasRoleOrTrustPolicy = false;
      const options = destinationVendors.destinations.map((d: Vendor) => {
        if (d.role || d.aws_trust_policy) {
          hasRoleOrTrustPolicy = true;
        }
        const icon = () => <VendorLogo logo_url={d.logo_url} />;
        return {
          ...d,
          key: d.vendor_name,
          text: d.display_name,
          icon,
        };
      });

      if (hasRoleOrTrustPolicy) {
        setVendorOptions(options);
        setSelectedVendor(options[0]);
      }
    }
  }, [destinationVendors]);

  useEffect(() => {
    setDestinationField("vendor", selectedVendor.key);
  }, [selectedVendor]);

  useEffect(() => {
    if (destinationToEdit) {
      setDestination(convertToImportDestination(destinationToEdit));
      const vendorOption = vendorOptions?.find(
        ({ key }) => key === destinationToEdit.vendor
      );
      if (vendorOption) {
        setSelectedVendor(vendorOption);
      }
    }
  }, [destinationToEdit]);

  const setDestinationField = (
    key: keyof ImportDestination,
    value: string | string[] | boolean
  ) => {
    setDestination((oldDestination) => ({
      ...oldDestination,
      [key]: value,
    }));
  };

  const onSave = () => {
    if (isEditing) {
      const changed = computeChangedImportFields(
        destinationToEdit,
        preparedDestination
      );
      // dispatch(updateImportDes)
      dispatch(
        updateImportDestination({
          destinationId: destinationToEdit.id,
          fields: changed,
          redirect: () => navigate("/import/destinations"),
        })
      );
    } else {
      dispatch(
        createImportDestination({
          destination: preparedDestination,
          redirect: () => navigate("/import/destinations"),
        })
      );
    }
  };

  const validateForm = () =>
    formRef.current ? formRef.current.reportValidity() : false;

  // Intercept native form submission, prevent default, and run test
  // We use the default form submission event so that we can borrow the browsers built-in support for handling missing required fields
  const onSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    onSave();
  };

  return (
    <div className="pb-16">
      <form className="space-y-8" onSubmit={onSubmit} ref={formRef}>
        <div className="space-y-4">
          {vendorOptions && (
            <Dropdown
              label={"Destination type"}
              items={vendorOptions}
              selectedItem={selectedVendor}
              setSelectedItem={setSelectedVendor}
              disabled={isEditing}
            />
          )}
          <FormField
            label="Name the destination"
            id="name"
            type="text"
            subtext="Descriptive name for this destination."
            value={destination.name}
            onChangeHandler={(value: string) =>
              setDestinationField("name", value)
            }
            required
            disabled={destinationTest.status === "processing"}
          />
        </div>
        <div className="h-px w-full bg-gray-200" /> {/* Divider  */}
        <div className="space-y-4">
          <CredentialsFields
            selectedVendor={selectedVendor}
            formFields={formFields}
            destination={destination}
            setDestinationField={setDestinationField}
            disabled={destinationTest?.status === "processing"}
            isEditing={isEditing}
          />
        </div>
        {selectedVendor.uses_staging_bucket && (
          <div className="h-px w-full bg-gray-200" />
        )}
        <div className="space-y-4">
          <BucketFields
            usesStagingBucket={selectedVendor.uses_staging_bucket}
            formFields={formFields}
            destination={destination}
            setDestinationField={setDestinationField}
            disabled={destinationTest?.status === "processing"}
            isEditing={isEditing}
          />
        </div>
        {selectedVendor.supports_ssh_tunnel && (
          <>
            <div className="h-px w-full bg-gray-200" /> {/* Divider  */}
            <div className="space-y-4"></div>
            <SSHTunnelForm
              connector={destination}
              setField={setDestinationField}
            />
          </>
        )}
        {selectedVendor.uses_service_account && (
          <ServiceAccount role={selectedVendor.role} />
        )}
        <TestDestinationConnection
          beforeSubmitTest={validateForm}
          preparedDestination={preparedDestination}
          existingDestination={destinationToEdit}
        />
      </form>
      <div className="flex justify-end mt-8">
        {isEditing && (
          <Button
            className="mr-3"
            type={ButtonStyle.TERTIARY}
            onClick={() => navigate(-1)}
            text="Cancel"
          />
        )}
        <Button
          type={
            !(destinationTest.status === "success")
              ? ButtonStyle.TERTIARY
              : ButtonStyle.PRIMARY
          }
          disabled={!(destinationTest.status === "success")}
          onClick={onSave}
          text="Save Destination"
        />
      </div>
    </div>
  );
};

export default ImportDestinationForm;
