import React from "react";
import { Alert, Button, FormField } from "@prequel-internal/react-components";

import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  fetchPasswordlessLogin,
  selectSSOEnabled,
  selectPasswordlessLogin,
} from "../../../store/auth/auth.duck";

import { FormState } from "..";

type PasswordlessCardProps = {
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
};

const PasswordlessCard = ({
  setFormState,
  email,
  setEmail,
}: PasswordlessCardProps) => {
  const dispatch = useTypedDispatch();

  const ssoEnabled = useTypedSelector(selectSSOEnabled);
  const passwordlessLogin = useTypedSelector(selectPasswordlessLogin);

  const onSendMeALink = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(fetchPasswordlessLogin(email));
    setFormState("PASSWORDLESS_SUBMITTED");
  };

  return (
    <form onSubmit={onSendMeALink} className="space-y-4">
      <FormField
        id="email"
        key="email"
        type="email"
        placeholder="you@yourcompany.com"
        value={email}
        label="Email"
        onChangeHandler={setEmail}
        required
        disabled
      />

      {ssoEnabled?.state === "SUCCESS" && ssoEnabled.enabled ? (
        <Alert>
          SSO is enabled.{" "}
          <span
            className="text-red-700 cursor-pointer font-semibold hover:underline"
            onClick={() => setFormState("SSO_SELECTED")}
          >
            Sign in with SSO instead.
          </span>
        </Alert>
      ) : (
        <Button
          className="w-full"
          submit
          autoFocus
          disabled={passwordlessLogin?.state === "LOADING"}
          text={"Send me a link"}
        />
      )}

      <div className="text-sm flex justify-center text-gray-500 ">
        <a
          className="text-primary-600 cursor-pointer hover:underline"
          onClick={() => setFormState("NOTHING_SELECTED")}
        >
          Back to other options
        </a>
      </div>
    </form>
  );
};

export default PasswordlessCard;
