import React from "react";
import { FormField, Toggle } from "@prequel-internal/react-components";

import SSHPublicKey, { PublicKeyAction } from "./SSHPublicKey";
import { SSHTunnelProperties } from "../../lib";

type SSHTunnelFormProps = {
  connector: SSHTunnelProperties;
  setField: (key: keyof SSHTunnelProperties, value: string | boolean) => void;
};
const SSHTunnelForm = ({ connector, setField }: SSHTunnelFormProps) => {
  return (
    <>
      <Toggle
        label="Connect using SSH tunnel"
        enabled={connector.use_ssh_tunnel}
        setEnabled={(isChecked: boolean) =>
          setField("use_ssh_tunnel", isChecked)
        }
      />
      {connector.use_ssh_tunnel && (
        <>
          {connector.ssh_public_key ? (
            <SSHPublicKey
              type={PublicKeyAction.EXISTING_KEY}
              existingPublicKey={connector.ssh_public_key}
            />
          ) : (
            <SSHPublicKey
              type={PublicKeyAction.GENERATE_KEY}
              setPublicKey={(publicKey: string) =>
                setField("ssh_public_key", publicKey)
              }
            />
          )}
          <FormField
            label="SSH Tunnel Host"
            id="ssh_tunnel_host"
            type="text"
            value={connector.ssh_tunnel_host}
            onChangeHandler={(value: string) => {
              setField("ssh_tunnel_host", value);
            }}
            required={connector.use_ssh_tunnel}
          />

          <FormField
            label="SSH Tunnel Port"
            id="ssh_tunnel_port"
            type="number"
            placeholder={connector.use_ssh_tunnel ? "22" : ""}
            value={connector.ssh_tunnel_port}
            onChangeHandler={(value: string) => {
              setField("ssh_tunnel_port", value);
            }}
            required={connector.use_ssh_tunnel}
          />

          <FormField
            label="SSH Tunnel Username"
            id="ssh_tunnel_username"
            type="text"
            value={connector.ssh_tunnel_username}
            onChangeHandler={(value: string) => {
              setField("ssh_tunnel_username", value);
            }}
            required={connector.use_ssh_tunnel}
          />
        </>
      )}
    </>
  );
};

export default SSHTunnelForm;
