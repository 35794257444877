import React, { useEffect } from "react";
import {
  Navigate,
  NavLink,
  useNavigate,
  useParams,
  useRoutes,
} from "react-router-dom";
import {
  Spinner,
  PageHeading,
  Tabs,
  checkTabIsActive,
} from "@prequel-internal/react-components";

import SourceDetails from "../SourceDetails";
import { useTypedDispatch, useTypedSelector } from "../../store";
import { fetchSources, selectSource } from "../../store/sources/sources.duck";
import SourceConnectionDetails from "../SourceConnectionDetails";
import { Domain } from "../../lib";
import {
  fetchImportDestinations,
  selectImportDestination,
} from "../../store/destinations/destinations.duck";
import DestinationDetails from "../DestinationDetails";
import DestinationConnectionDetails from "../DestinationConnectionDetails";
import ImportDestinationDetails from "../ImportDestinationDetails";

type FirstPartyConnectionPageProps = {
  domain: Domain;
};
const FirstPartyConnectionPage = ({
  domain,
}: FirstPartyConnectionPageProps) => {
  const { connectionId } = useParams<{ connectionId: string }>();
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const connection =
    domain === Domain.EXPORT
      ? useTypedSelector((state) => selectSource(state, connectionId))
      : useTypedSelector((state) =>
          selectImportDestination(state, connectionId)
        );

  useEffect(() => {
    if (domain === Domain.EXPORT) {
      dispatch(fetchSources());
    } else {
      dispatch(fetchImportDestinations());
    }
  }, [dispatch]);

  const tabs = [
    <NavLink key="General" to="" className={checkTabIsActive} replace end>
      General
    </NavLink>,
    <NavLink
      key="Connection"
      to="connection"
      className={checkTabIsActive}
      replace
      end
    >
      Connection
    </NavLink>,
  ];

  const element = useRoutes([
    {
      path: "/",
      element:
        domain === Domain.EXPORT ? (
          <SourceDetails domain={domain} />
        ) : (
          <ImportDestinationDetails domain={domain} />
        ),
    },
    {
      path: "/connection",
      element:
        domain === Domain.EXPORT ? (
          <SourceConnectionDetails domain={domain} />
        ) : (
          <DestinationConnectionDetails domain={domain} />
        ),
    },
    {
      path: "*",
      element: <Navigate to="" replace />,
    },
  ]);

  if (connection === undefined) {
    // Still loading connections
    return <Spinner />;
  }

  if (connection === null) {
    // Connections loaded, but could not find what we're looking for
    const n =
      domain === Domain.EXPORT ? "/export/sources" : "/import/destinations";
    return <Navigate to={n} replace />;
  }

  return (
    <>
      <PageHeading title={connection.name} onGoBack={() => navigate(-1)} />
      <div className="flex-1">
        <div className="relative max-w-4xl mx-auto">
          <div className="pt-0 pb-16">
            <div className="pb-6">
              {/* Tabs */}
              <Tabs tabs={tabs} />
              {element}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default FirstPartyConnectionPage;
