import React from "react";

import { useRoutes, useNavigate, NavLink, Navigate } from "react-router-dom";
import {
  PageHeading,
  Tabs,
  checkTabIsActive,
  Button,
  ButtonStyle,
} from "@prequel-internal/react-components";

import ExportSourceForm from "../ExportSourceForm";
import FirstPartyConnectionPage from "../FirstPartyConnectionPage";
import { Domain } from "../../lib";
import FirstPartyConnectionsCards from "../FirstPartyConnectionsCards";
import ImportDestinationForm from "../ImportDestinationForm";

type FirstPartyConnectionsPageProps = {
  domain: Domain;
};
const FirstPartyConnectionsPage = ({
  domain,
}: FirstPartyConnectionsPageProps) => {
  const navigate = useNavigate();

  const tabs = [
    <NavLink key="Summary" to="#" className={checkTabIsActive}>
      Summary
    </NavLink>,
  ];

  const element = useRoutes([
    {
      path: "/",
      element: (
        <div className="max-w-6xl pb-16">
          <PageHeading
            title={domain === Domain.EXPORT ? "Sources" : "Destinations"}
          >
            <Button
              text={domain === Domain.EXPORT ? "Add Source" : "Add Destination"}
              onClick={() => navigate("new")}
              type={ButtonStyle.SECONDARY}
            />
          </PageHeading>
          <Tabs tabs={tabs} />
          <FirstPartyConnectionsCards domain={domain} />
        </div>
      ),
    },
    {
      path: "new",
      element: (
        <div className="max-w-lg">
          <PageHeading
            title={domain === Domain.EXPORT ? "Add Source" : "Add Destination"}
            onGoBack={() => navigate(-1)}
          />
          {domain === Domain.EXPORT ? (
            <ExportSourceForm />
          ) : (
            <ImportDestinationForm />
          )}
        </div>
      ),
    },
    {
      path: ":connectionId/edit",
      element: (
        <div className="max-w-lg">
          <PageHeading
            title={
              domain === Domain.EXPORT ? "Edit Source" : "Edit Destination"
            }
            onGoBack={() => navigate(-1)}
          />
          {domain === Domain.EXPORT ? (
            <ExportSourceForm />
          ) : (
            <ImportDestinationForm />
          )}
        </div>
      ),
    },
    {
      path: ":connectionId/*",
      element: (
        <div className="max-w-4xl">
          <FirstPartyConnectionPage domain={domain} />
        </div>
      ),
    },
  ]);

  return <div className="flex flex-col pb-4">{element}</div>;
};

export default FirstPartyConnectionsPage;
