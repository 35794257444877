import React from "react";
import {
  Button,
  ButtonStyle,
  FormField,
  Spinner,
} from "@prequel-internal/react-components";

import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  fetchSSOEnabled,
  selectPasswordlessLogin,
  selectGoogleOauthLogin,
  fetchGoogleOauthLogin,
} from "../../../store/auth/auth.duck";

import { FormState } from "..";

import { ReactComponent as GoogleIcon } from "../../../assets/logos/google.svg";

type OptionsCardProps = {
  setFormState: React.Dispatch<React.SetStateAction<FormState>>;
  email: string;
  setEmail: React.Dispatch<React.SetStateAction<string>>;
};

const OptionsCard = ({ setFormState, email, setEmail }: OptionsCardProps) => {
  const dispatch = useTypedDispatch();

  const passwordlessLogin = useTypedSelector(selectPasswordlessLogin);
  const googleOauthLogin = useTypedSelector(selectGoogleOauthLogin);

  const onContinueWithEmail = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    dispatch(fetchSSOEnabled(email));
    setFormState("PASSWORDLESS_SELECTED");
  };

  const logInWithGoogleOauth = () => {
    dispatch(fetchGoogleOauthLogin());
  };

  return (
    <form onSubmit={onContinueWithEmail} className="space-y-4">
      <div className="space-y-4">
        <Button
          key="google"
          className="w-full"
          type={ButtonStyle.TERTIARY}
          onClick={logInWithGoogleOauth}
          disabled={
            googleOauthLogin?.state === "LOADING" ||
            googleOauthLogin?.state === "REDIRECTING"
          }
          /* eslint-disable indent */
          text={
            googleOauthLogin?.state === "LOADING" ||
            googleOauthLogin?.state === "REDIRECTING" ? (
              <div className="flex space-x-2.5">
                <Spinner.Inline className="mr-3" />
                <GoogleIcon className="h-5" />
                <span>Signing in with Google</span>
              </div>
            ) : (
              <div className="flex space-x-2.5">
                <GoogleIcon className="h-5" />
                <span>Continue with Google</span>
              </div>
            )
          }
        />
        <Button
          key="sso"
          className="w-full"
          type={ButtonStyle.TERTIARY}
          onClick={() => setFormState("SSO_SELECTED")}
          text={"Continue with SSO"}
        />
      </div>
      <div className="w-full border-b border-gray-300" />
      <FormField
        id="email"
        key="email"
        type="email"
        placeholder="you@yourcompany.com"
        value={email}
        label="Email"
        onChangeHandler={setEmail}
        required
        autoFocus
      />

      <Button
        className="w-full"
        submit
        disabled={passwordlessLogin?.state === "LOADING"}
        text={"Continue with email"}
      />
    </form>
  );
};

export default OptionsCard;
