import React, { useState, useEffect } from "react";

import {
  Button,
  ButtonStyle,
  MenuItemStyle,
  Modal,
  ConfirmModal,
  DescriptionList,
  Spinner,
} from "@prequel-internal/react-components";
import {
  getDefaultDestination,
  prepareDestinationFromExisting,
  useDestinationForm,
} from "@prequel/react";
import { useNavigate, useParams } from "react-router-dom";

import Timestamp from "../Timestamp";
import VendorLogo from "../VendorLogo";
import { useTypedDispatch, useTypedSelector } from "../../store";
import {
  deleteDestination,
  deleteImportDestination,
  selectDestination,
} from "../../store/destinations/destinations.duck";
import { createTransfer } from "../../store/transfers/transfers.duck";
import {
  getReadableLastSuccessfulSync,
  getReadableModels,
} from "../../store/destinations";
import SyncSpecificModelsModal from "../SyncSpecificModelsModal";
import { Domain } from "../../lib";
import { env } from "../../env";
import { fetchOrg, selectOrg } from "../../store/org/org.duck";

type DestinationDetailsProps = {
  domain: Domain;
};
const DestinationDetails = ({ domain }: DestinationDetailsProps) => {
  const { connectionId } = useParams<{ connectionId: string }>();
  const existingDestination = useTypedSelector((state) =>
    selectDestination(state, connectionId)
  );
  const destination = existingDestination
    ? prepareDestinationFromExisting(existingDestination)
    : getDefaultDestination();

  const org = useTypedSelector(selectOrg);
  const form = useDestinationForm(
    destination,
    org?.id ?? "",
    true,
    env.REACT_APP_API_SERVER
  );

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showRefreshModal, setShowRefreshModal] = useState(false);
  const [showSelectiveRefreshModal, setShowSelectiveRefreshModal] =
    useState(false);
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  useEffect(() => {
    dispatch(fetchOrg());
  }, [dispatch]);

  const onDelete = () => {
    setShowDeleteModal(true);
  };

  const onFullRefresh = () => {
    setShowRefreshModal(true);
  };

  const onConfirmDelete = () => {
    setShowDeleteModal(false);
    if (existingDestination) {
      const action =
        domain === Domain.IMPORT ? deleteImportDestination : deleteDestination;
      dispatch(
        action({
          destinationId: existingDestination.id,
          redirect: () => navigate(`/${domain}/destinations`),
        })
      );
    }
  };

  const onConfirmForceRefresh = () => {
    setShowRefreshModal(false);
    if (existingDestination) {
      dispatch(
        createTransfer({
          destinationId: existingDestination.id,
          fullRefresh: true,
        })
      );
    }
  };

  const refreshButtonMenuItems = [
    {
      label: "Select models to full refresh",
      style: MenuItemStyle.PRIMARY,
      onClick: () => setShowSelectiveRefreshModal(true),
    },
  ];

  if (!existingDestination || !form) {
    return <Spinner />;
  }

  const vendor =
    form[0].fields[0].form_element === "select"
      ? form[0].fields[0].enum?.find(({ key }) => key === destination.vendor)
      : undefined;

  return (
    <>
      <Modal open={showDeleteModal} onClose={() => setShowDeleteModal(false)}>
        <ConfirmModal
          title="Delete destination"
          message="Are you sure you want to delete this destination? This action cannot be undone."
          onConfirm={onConfirmDelete}
          confirmButtonText="Delete"
          onCancel={() => setShowDeleteModal(false)}
        />
      </Modal>
      <Modal open={showRefreshModal} onClose={() => setShowRefreshModal(false)}>
        <ConfirmModal
          title="Force full refresh"
          message="Are you sure you want to force a full data refresh for this destination? This will re-send all historical data and may take a while."
          onConfirm={onConfirmForceRefresh}
          confirmButtonText="Force full refresh"
          onCancel={() => setShowRefreshModal(false)}
        />
      </Modal>
      <Modal
        open={showSelectiveRefreshModal}
        onClose={() => setShowSelectiveRefreshModal(false)}
      >
        <SyncSpecificModelsModal
          destination={existingDestination}
          fullRefresh={true}
          onCancel={() => setShowSelectiveRefreshModal(false)}
          onConfirm={() => setShowSelectiveRefreshModal(false)}
        />
      </Modal>
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Status
          </h3>
        </div>
        <div className="mt-6">
          <DescriptionList>
            <DescriptionList.Item
              label="Last Successful Transfer"
              value={getReadableLastSuccessfulSync(
                existingDestination?.last_successful_transfer_ended_at ??
                  undefined
              )}
            />

            <DescriptionList.Item
              label="Current Status"
              value={destination?.is_enabled ? "Enabled" : "Disabled"}
            />
          </DescriptionList>
        </div>
      </div>
      {/* eslint-enable */}
      {/* Description list */}
      <div className="mt-10 divide-y divide-gray-200">
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Details
          </h3>
        </div>
        <div className="mt-6">
          {form && destination && (
            <DescriptionList>
              <DescriptionList.Item label="ID" value={existingDestination.id} />
              {vendor && (
                <DescriptionList.Item
                  label="Vendor"
                  value={
                    <div className="flex items-center">
                      <VendorLogo logo_url={vendor.icon_url ?? ""} />
                      {vendor.display}
                    </div>
                  }
                />
              )}
              <DescriptionList.Item
                label="Enabled"
                value={destination.is_enabled ? "Yes" : "No"}
              />
              {form.map((section) =>
                section.fields.map((field) => {
                  if (field.name === "password" || field.const) {
                    // Skip constants and passwords, don't need rendering
                    return;
                  }

                  if (field.name === "frequency_minutes") {
                    return (
                      <DescriptionList.Item
                        key={field.name}
                        label={field.label}
                        value={
                          destination[field.name] === "0"
                            ? "Default org frequency"
                            : destination[field.name]
                        }
                      />
                    );
                  }

                  if (field.name === "use_ssh_tunnel") {
                    return (
                      <DescriptionList.Item
                        key={field.name}
                        label={"Use SSH Tunnel"}
                        value={destination[field.name] ? "Yes" : "No"}
                      />
                    );
                  }

                  if (field.name === "disable_ssl") {
                    return (
                      <DescriptionList.Item
                        key={field.name}
                        label={field.label}
                        value={destination[field.name] ? "Yes" : "No"}
                      />
                    );
                  }

                  return (
                    field.label && (
                      <DescriptionList.Item
                        key={field.name}
                        label={field.label}
                        value={destination[field.name]}
                      />
                    )
                  );
                })
              )}
              <DescriptionList.Item
                label="Enabled models"
                value={getReadableModels(destination.enabled_models)}
              />
              {destination.created_at && (
                <DescriptionList.Item
                  label="Created"
                  value={<Timestamp timestamp={destination.created_at} />}
                />
              )}
              {destination.updated_at && (
                <DescriptionList.Item
                  label="Updated"
                  value={<Timestamp timestamp={destination.updated_at} />}
                />
              )}
            </DescriptionList>
          )}
        </div>
      </div>
      <div className="mt-10 divide-y divide-red-300">
        <div className="space-y-1">
          <h3 className="text-lg leading-6 font-medium text-gray-900">
            Danger Zone
          </h3>
          <p className="max-w-2xl text-sm text-gray-500">
            Actions in this section should be considered carefully.
          </p>
        </div>
        <div className="mt-6">
          <DescriptionList type="danger">
            <DescriptionList.ButtonItem
              label="Edit destination"
              description={`Edit destination fields. Destination ${
                domain === Domain.EXPORT
                  ? "vendor, host, and recipient_id"
                  : "vendor and host"
              } cannot be changed.`}
            >
              <Button
                text="Edit destination"
                type={ButtonStyle.DANGER}
                onClick={() =>
                  navigate(`/${domain}/destinations/${connectionId}/edit`)
                }
              />
            </DescriptionList.ButtonItem>
            <DescriptionList.ButtonItem
              label="Delete destination"
              description="Once you delete a destination, the action cannot be undone."
            >
              <Button
                text="Delete destination"
                type={ButtonStyle.DANGER}
                onClick={onDelete}
              />
            </DescriptionList.ButtonItem>
            {domain === Domain.EXPORT && (
              <DescriptionList.ButtonItem
                label="Force full refresh for destination"
                description="A full refresh will re-sync all available data, and may take a while."
              >
                <Button
                  text="Force full refresh"
                  type={ButtonStyle.DANGER}
                  onClick={onFullRefresh}
                  menuItems={refreshButtonMenuItems}
                />
              </DescriptionList.ButtonItem>
            )}
          </DescriptionList>
        </div>
      </div>
    </>
  );
};

export default DestinationDetails;
