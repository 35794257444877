import {
  Badge,
  BadgeColor,
  Table,
  TableRow,
} from "@prequel-internal/react-components";
import React, { useEffect, useState } from "react";
import { useTypedSelector } from "../../store";
import { selectProviders } from "../../store/providers/providers.duck";

const ProvidersTable = () => {
  const [rows, setRows] = useState<TableRow[]>([]);
  const providers = useTypedSelector(selectProviders);

  useEffect(() => {
    if (providers) {
      const providerRows = providers.map((provider) => ({
        id: provider.id,
        cells: [
          { value: provider.name },
          { value: provider.id_in_recipient_system },
          { value: provider.schema_suffix_in_destination_system },
          {
            value: (
              <>
                {provider.products.map((p) => (
                  <Badge
                    key={p}
                    className="mr-1"
                    text={p}
                    color={BadgeColor.GRAY}
                  />
                ))}
              </>
            ),
          },
        ],
      }));
      setRows(providerRows);
    }
  }, [providers]);

  const columns = [
    "Name",
    "ID in Recipient System",
    "Schema Suffix",
    "Products",
  ];

  return (
    <Table columns={columns} rows={rows} footer={`${rows.length} providers`} />
  );
};

export default ProvidersTable;
