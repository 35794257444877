import React, { useEffect } from "react";
import {
  Button,
  ButtonStyle,
  checkTabIsActive,
  PageHeading,
  Tabs,
} from "@prequel-internal/react-components";
import { Navigate, NavLink, useNavigate, useRoutes } from "react-router-dom";
import { useTypedDispatch } from "../../store";
import ImportMagicLinksTable from "../ImportMagicLinksTable";
import ImportMagicLinkForm from "../ImportMagicLinkForm";
import { fetchSourceVendors } from "../../store/sources/sources.duck";
import { fetchProviders } from "../../store/providers/providers.duck";

const ImportMagicLinksPage = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const tabs = [
    <NavLink key="ActiveLinks" to="#" className={checkTabIsActive}>
      Active Links
    </NavLink>,
  ];

  useEffect(() => {
    dispatch(fetchSourceVendors());
    dispatch(fetchProviders());
  }, [dispatch]);

  const element = useRoutes([
    {
      path: "active",
      element: (
        <div className="max-w-6xl pb-16">
          <PageHeading title="Magic Links">
            <Button
              text="Generate Link"
              onClick={() => navigate("new")}
              type={ButtonStyle.SECONDARY}
            />
          </PageHeading>
          <Tabs tabs={tabs} />
          <ImportMagicLinksTable />
        </div>
      ),
    },
    {
      path: "new",
      element: (
        <div className="max-w-lg pb-16">
          <PageHeading
            title="Generate Magic Link"
            onGoBack={() => navigate(-1)}
          />
          <ImportMagicLinkForm />
        </div>
      ),
    },
    {
      path: "*",
      element: <Navigate to="active" replace />,
    },
  ]);
  return <div className="flex flex-col">{element}</div>;
};

export default ImportMagicLinksPage;
