import { format, parseJSON } from "date-fns";
import {
  AwsTrustPolicy,
  DestinationVendor,
  IamRole,
  VendorField,
} from "@prequel/react";
import { DropdownListItem } from "@prequel-internal/react-components";

export type Vendor = DestinationVendor;

export enum Domain {
  IMPORT = "import",
  EXPORT = "export",
}

export type Connection = {
  id: string;
  vendor: string;
  name: string;
  host?: string;
  bucket_name?: string;
  created_at: string;
};

export const getReadableVendor = (
  vendor: string,
  destinationVendors: DestinationVendor[]
) => {
  const destinationVendor = destinationVendors.find(
    ({ vendor_name }) => vendor_name === vendor
  );
  return destinationVendor?.display_name ?? "";
};

// In the unlikely case that the backend returns a destination type that is not supported in the frontend, type the function to return undefined
export const getVendorLogoUrl = (
  vendor: string,
  destinationVendors: DestinationVendor[]
) => {
  const destinationVendor = destinationVendors.find(
    ({ vendor_name }) => vendor_name === vendor
  );
  return destinationVendor?.logo_url ?? "";
};

export const sortByCreatedAt = (
  a: { created_at: string },
  b: { created_at: string }
) => {
  const nullDate = new Date(0).toISOString(); // Create nullDate to replace null values with an early timestamp (epoch time)
  const dateA = parseJSON(a.created_at ?? nullDate);
  const dateB = parseJSON(b.created_at ?? nullDate);
  if (dateA > dateB) {
    return -1;
  }

  if (dateB > dateA) {
    return 1;
  }

  return 0;
};

export type DropdownListItemWithFields = DropdownListItem<string> & {
  fields: VendorField[];
  docs: string;
  uses_staging_bucket: boolean;
  uses_service_account: boolean;
  supports_ssh_tunnel: boolean;
  role?: IamRole;
  aws_trust_policy?: AwsTrustPolicy;
};

export type ServiceAccountKey = {
  type: string;
  project_id: string;
  private_key_id: string;
  client_email: string;
  client_id: string;
  auth_url: string;
  token_uri: string;
  auth_provider_x509_cert_url: string;
  client_x509_cert_url: string;
};

export type SSHTunnelProperties = {
  use_ssh_tunnel: boolean;
  ssh_public_key?: string;
  ssh_tunnel_host?: string;
  ssh_tunnel_port?: string;
  ssh_tunnel_username?: string;
};

export const getValidServiceAccountKey = (
  string?: string
): ServiceAccountKey | undefined => {
  if (!string) {
    return undefined;
  }
  try {
    const token: ServiceAccountKey = JSON.parse(string);
    return token;
  } catch (e) {
    return undefined;
  }
};
