import React, { useEffect, useState } from "react";
import {
  CopyToClipboard,
  StatusLight,
  Status,
  Table,
  TableRow,
} from "@prequel-internal/react-components";

import { useTypedDispatch, useTypedSelector } from "../../store";
import {
  fetchImportMagicLinks,
  selectImportMagicLinks,
} from "../../store/magic_links/magic_links.duck";
import { sortByCreatedAt } from "../../lib";
import { getTimeRemaining } from "../../store/magic_links";
import { selectProviders } from "../../store/providers/providers.duck";

const ImportMagicLinksTable = () => {
  const [rows, setRows] = useState<TableRow[]>([]);
  const dispatch = useTypedDispatch();
  const links = useTypedSelector(selectImportMagicLinks);
  const providers = useTypedSelector(selectProviders);

  useEffect(() => {
    dispatch(fetchImportMagicLinks());
  }, [dispatch]);

  useEffect(() => {
    if (links) {
      // copy the array before sorting
      const sortedLinks = [...links].sort(sortByCreatedAt);
      const linkRows = sortedLinks.map((link) => {
        const provider = providers?.find(({ id }) => id === link.provider_id);
        return {
          id: link.id,
          cells: [
            { value: link.name },
            { value: provider ? provider.name : link.provider_id },
            {
              value: (
                <div className="flex items-center max-w-xs">
                  <CopyToClipboard textToCopy={link.link} />
                  <span className="truncate">{link.link}</span>
                </div>
              ),
            },
            {
              value: (
                <div className="flex items-center text-gray-500">
                  <StatusLight
                    status={
                      link.has_been_used ? Status.INACTIVE : Status.ACTIVE
                    }
                  />
                  {link.has_been_used ? "Submitted" : "Active"}
                </div>
              ),
            },
            { value: getTimeRemaining(link.created_at) },
            {
              value: (
                <div className="flex items-center max-w-xs">
                  <span className="truncate">{link.host}</span>
                </div>
              ),
            },
          ],
        };
      });
      setRows(linkRows);
    }
  }, [links, providers]);

  const columns = [
    "Name",
    "Provider",
    "Magic Link",
    "Status",
    "Time Remaining",
    "Host",
  ];

  return (
    <Table
      columns={columns}
      rows={rows}
      footer={`${rows.length} magic links`}
    />
  );
};

export default ImportMagicLinksTable;
