import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  ButtonStyle,
  Checkbox,
  Form,
  FormField,
} from "@prequel-internal/react-components";

import { Product } from "../../store/products";
import { useTypedDispatch, useTypedSelector } from "../../store";
import { fetchModels, selectModels } from "../../store/models/models.duck";
import { createProduct } from "../../store/products/products.duck";
import { useNavigate } from "react-router-dom";

const ProductForm = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const models = useTypedSelector(selectModels);
  const [product, setProduct] = useState<Product>({
    product_name: "",
    description: "",
    models: [],
  });
  const [availableModels, setAvailableModels] = useState<string[]>();
  const [productsError, setProductsError] = useState<string>();

  useEffect(() => {
    dispatch(fetchModels());
  }, [dispatch]);

  useEffect(() => {
    if (models) {
      const opts = models.map(({ model_name }) => model_name);
      setAvailableModels(opts);
    }
  }, [models]);

  const setField = (key: keyof Product, value: string | string[]) => {
    setProduct((oldProduct: Product) => ({
      ...oldProduct,
      [key]: value,
    }));
  };

  const updateModels = (isEnabled: boolean, modelName: string) => {
    let updatedModels: string[] = [];
    if (isEnabled) {
      updatedModels = [...product.models, modelName];
    } else {
      updatedModels = product.models.filter((p) => p !== modelName);
    }
    setProductsError(undefined);
    setField("models", updatedModels);
  };

  const onSubmit = () => {
    dispatch(
      createProduct({
        product,
        redirect: () => navigate("/export/models/products"),
      })
    );
  };

  return (
    <Form className="space-y-4" onSubmit={onSubmit} submitButtonRef={buttonRef}>
      <FormField
        label="Name"
        id="name"
        type="text"
        subtext="Descriptive name for this Product. This will only be visible internally and is only used as a reference."
        value={product.product_name}
        onChangeHandler={(value: string) => setField("product_name", value)}
        required
      />
      <FormField
        label="Description"
        id="description"
        type="text"
        subtext="A brief description of this Product. This will only be visible internally."
        value={product.description}
        onChangeHandler={(value: string) => setField("description", value)}
        required
      />
      {availableModels && (
        <>
          <label className="block text-sm font-medium text-gray-700">
            Select what models the product will contain
          </label>
          {availableModels.map((m) => (
            <Checkbox
              key={m}
              id={m}
              label={m}
              checked={product.models.includes(m)}
              setChecked={(isChecked: boolean) => updateModels(isChecked, m)}
            />
          ))}
          {productsError && (
            <p className="mt-1 text-xs font-medium text-red-600">
              {productsError}
            </p>
          )}
        </>
      )}
      <div>
        <Button
          className="mt-6"
          ref={buttonRef}
          type={ButtonStyle.PRIMARY}
          text="Create product"
          submit
        />
      </div>
    </Form>
  );
};

export default ProductForm;
