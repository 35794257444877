import React, { useEffect } from "react";
import { useDestinationVendors } from "@prequel/react";
import {
  Table,
  TableRow,
  StatusLight,
  Status,
} from "@prequel-internal/react-components";
import { useNavigate } from "react-router-dom";

import { useTypedDispatch, useTypedSelector } from "../../store";
import {
  fetchImportSources,
  fetchSourceVendors,
  selectImportSources,
  selectSourceVendors,
} from "../../store/sources/sources.duck";
import Timestamp from "../Timestamp";
import VendorLogo from "../VendorLogo";
import {
  sortByCreatedAt,
  getReadableVendor,
  getVendorLogoUrl,
  Domain,
} from "../../lib";
import { getReadableLastSuccessfulSync } from "../../store/destinations";
import {
  fetchDestinations,
  selectDestinations,
} from "../../store/destinations/destinations.duck";
import { env } from "../../env";

type ThirdPartyConnectionsTableProps = {
  domain: Domain;
};
const ThirdPartyConnectionsTable = ({
  domain,
}: ThirdPartyConnectionsTableProps) => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const sourceVendors = useTypedSelector(selectSourceVendors);
  const destinationVendors = useDestinationVendors(env.REACT_APP_API_SERVER);
  const connections =
    domain === Domain.EXPORT
      ? useTypedSelector(selectDestinations)
      : useTypedSelector(selectImportSources);

  useEffect(() => {
    if (domain === Domain.EXPORT) {
      dispatch(fetchDestinations());
    } else {
      dispatch(fetchImportSources());
      dispatch(fetchSourceVendors());
    }
  }, [dispatch]);

  const vendors =
    domain === Domain.EXPORT ? destinationVendors?.destinations : sourceVendors;

  const sortedConnections = [...(connections || [])].sort(sortByCreatedAt);
  const rows = sortedConnections.map((connection) => ({
    id: connection.id,
    cells: [
      {
        value: connection.name,
      },
      {
        value: (
          <div className="flex items-center">
            <VendorLogo
              logo_url={getVendorLogoUrl(connection.vendor, vendors ?? [])}
            />
            {getReadableVendor(connection.vendor, vendors ?? [])}
          </div>
        ),
      },
      {
        value:
          /* eslint-disable */
          // temporary until last_successful_transfer_ended_at added to import source
          domain === Domain.EXPORT &&
          "last_successful_transfer_ended_at" in connection ? (
            <div className="text-gray-500">
              {getReadableLastSuccessfulSync(
                connection.last_successful_transfer_ended_at ?? undefined
              )}
            </div>
          ) : (
            connection.host ?? connection.bucket_name ?? "Unknown"
          ),
        /* eslint-enable */
      },
      {
        value: (
          <div className="flex items-center text-gray-500">
            <StatusLight
              status={connection.is_enabled ? Status.ACTIVE : Status.INACTIVE}
            />
            {connection.is_enabled ? "Enabled" : "Disabled"}
          </div>
        ),
      },
      {
        value: <Timestamp timestamp={connection.created_at} />,
      },
    ],
  }));

  const columns = [
    "Connection Name",
    "Vendor",
    "Last Successful Sync",
    "Status",
    "Created at",
  ];
  // hard-code override of column until we attach Transfer to ImportSource in API
  if (domain === Domain.IMPORT) {
    columns[2] = "Host/Bucket";
  }

  const onRowClick = (row: TableRow) => navigate(`${row.id}`);

  return (
    <Table
      columns={columns}
      rows={rows || []}
      footer={rows ? `${rows.length} connections` : ""}
      onRowClick={onRowClick}
    />
  );
};

export default ThirdPartyConnectionsTable;
