import axios, {
  ApiRoutes,
  ApiResponse,
  AppError,
  ErrorResponse,
  ID_PLACEHOLDER,
  RequestStatus,
} from "../../axios";

import { WithRedirect } from "..";
import { Vendor } from "../../lib";
import ExistingSource, {
  AvailableTable,
  ExistingImportSource,
  ExistingImportSourcePayload,
  ExistingImportSourceWithTablePayload,
  ExistingSourceTestPayload,
  PreparedImportSource,
  PreparedSource,
  SourceType,
  TableSample,
} from ".";

const getSources: () => Promise<ExistingSource[]> = () => {
  return axios
    .get(ApiRoutes.SOURCES)
    .then((response: ApiResponse<{ sources: ExistingSource[] }>) => {
      const sources: ExistingSource[] = response.data.data.sources.map((s) => ({
        ...s,
        type: SourceType.ExistingSource,
      }));
      return sources;
    })
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message: reason.response?.data?.message || "Failed to fetch sources.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const getImportSources: () => Promise<ExistingImportSource[]> = () => {
  return axios
    .get(ApiRoutes.IMPORT_SOURCES)
    .then((response: ApiResponse<{ sources: ExistingImportSource[] }>) => {
      const sources: ExistingImportSource[] = response.data.data.sources.map(
        (s) => ({ ...s, type: SourceType.ExistingImportSource })
      );
      return sources;
    })
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message:
            reason.response?.data?.message || "Failed to fetch import sources.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const testNewSource: (
  s: PreparedSource | PreparedImportSource
) => Promise<RequestStatus> = (source) => {
  return axios
    .post(ApiRoutes.TEST_SOURCE, { source })
    .then((response: ApiResponse<RequestStatus>) => response.data.data)
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message || "Source connection test failed.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const testExistingSource: (
  p: ExistingSourceTestPayload
) => Promise<RequestStatus> = ({ sourceId, fields }) => {
  const url = ApiRoutes.TEST_EXISTING_SOURCE.replace(ID_PLACEHOLDER, sourceId);
  return axios
    .post(url, fields ? { source: fields } : undefined)
    .then((response: ApiResponse<RequestStatus>) => response.data.data)
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message || "Source connection test failed.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const testExistingImportSource: (
  p: ExistingImportSourcePayload
) => Promise<RequestStatus> = ({ sourceId, fields }) => {
  const url = ApiRoutes.IMPORT_TEST_EXISTING_SOURCE.replace(
    ID_PLACEHOLDER,
    sourceId
  );
  return axios
    .post(url, fields ? { source: fields } : undefined)
    .then((response: ApiResponse<RequestStatus>) => response.data.data)
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason.response?.data?.message || "Source connection test failed.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postSource: (
  p: WithRedirect<{ source: PreparedSource }>
) => Promise<WithRedirect<{ source: ExistingSource }>> = ({
  source,
  redirect,
}) => {
  return axios
    .post(ApiRoutes.SOURCES, { source })
    .then((response: ApiResponse<{ source: ExistingSource }>) => ({
      source: response.data.data.source,
      redirect,
    }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message: reason.response?.data?.message || "Source creation failed.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postImportSource: ({
  source,
  redirect,
}: WithRedirect<{ source: PreparedImportSource }>) => Promise<
  WithRedirect<{ source: ExistingImportSource }>
> = ({ source, redirect }) => {
  return axios
    .post(ApiRoutes.IMPORT_SOURCES, { source })
    .then((response: ApiResponse<{ source: ExistingImportSource }>) => ({
      source: response.data.data.source,
      redirect,
    }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message: reason.response?.data?.message || "Source creation failed.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const patchSource: (
  p: WithRedirect<{
    sourceId: ExistingSource["id"];
    source: Partial<PreparedSource>;
  }>
) => Promise<WithRedirect<{ source: ExistingSource }>> = ({
  sourceId,
  source,
  redirect,
}) => {
  return axios
    .patch(`${ApiRoutes.SOURCES}/${sourceId}`, {
      source: source,
    })
    .then((response: ApiResponse<{ source: ExistingSource }>) => ({
      source: response.data.data.source,
      redirect,
    }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message || "Failed to update source.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const patchImportSource: (
  p: WithRedirect<{
    sourceId: ExistingImportSource["id"];
    source: Partial<PreparedImportSource>;
  }>
) => Promise<WithRedirect<{ source: ExistingImportSource }>> = ({
  sourceId,
  source,
  redirect,
}) => {
  return axios
    .patch(`${ApiRoutes.IMPORT_SOURCES}/${sourceId}`, {
      source: source,
    })
    .then((response: ApiResponse<{ source: ExistingImportSource }>) => ({
      source: response.data.data.source,
      redirect,
    }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message || "Failed to update source.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const deleteSource: (
  p: WithRedirect<{
    sourceId: ExistingSource["id"];
  }>
) => Promise<WithRedirect<{}>> = ({ sourceId, redirect }) => {
  return axios
    .delete(`${ApiRoutes.SOURCES}/${sourceId}`)
    .then(() => ({ redirect }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message || "Failed to delete source.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const deleteImportSource: (
  p: WithRedirect<{
    sourceId: ExistingImportSource["id"];
  }>
) => Promise<WithRedirect<{}>> = ({ sourceId, redirect }) => {
  return axios
    .delete(`${ApiRoutes.IMPORT_SOURCES}/${sourceId}`)
    .then(() => ({ redirect }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message || "Failed to delete source.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const getVendors: () => Promise<Vendor[]> = () => {
  return axios
    .get(ApiRoutes.PUBLIC_SOURCE_VENDORS)
    .then(
      (response: ApiResponse<{ sources: Vendor[] }>) =>
        response.data.data.sources
    )
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message ||
            "Failed to fetch source vendors.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postListSourceTables: (
  source: PreparedImportSource
) => Promise<AvailableTable[]> = (source) => {
  return axios
    .post(ApiRoutes.LIST_SOURCE_TABLES, { source })
    .then(
      (response: ApiResponse<{ tables: AvailableTable[] }>) =>
        response.data.data.tables
    )
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message || "Failed to fetch source tables.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postListExistingSourceTables: (
  p: ExistingImportSourcePayload
) => Promise<AvailableTable[]> = ({ sourceId, fields }) => {
  const url = ApiRoutes.IMPORT_LIST_EXISTING_SOURCE_TABLES.replace(
    ID_PLACEHOLDER,
    sourceId
  );
  return axios
    .post(url, fields ? { source: fields } : undefined)
    .then(
      (response: ApiResponse<{ tables: AvailableTable[] }>) =>
        response.data.data.tables
    )
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message || "Failed to fetch source tables.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postSampleSourceTable: ({
  source,
  table,
}: {
  source: PreparedImportSource;
  table: AvailableTable;
}) => Promise<{ table: AvailableTable; sample: TableSample }> = ({
  source,
  table,
}) => {
  return axios
    .post(ApiRoutes.SAMPLE_SOURCE_TABLE, {
      source,
      schema: table.schema,
      table: table.table_name,
    })
    .then((response: ApiResponse<{ rows: TableSample }>) => ({
      sample: response.data.data.rows,
      table,
    }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message || "Failed to sample source table.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postSampleExistingSourceTable: (
  p: ExistingImportSourceWithTablePayload
) => Promise<{ table: AvailableTable; sample: TableSample }> = ({
  sourceId,
  fields,
  table,
}) => {
  const url = ApiRoutes.IMPORT_SAMPLE_EXISTING_SOURCE_TABLE.replace(
    ID_PLACEHOLDER,
    sourceId
  );
  return axios
    .post(url, {
      source: fields,
      schema: table.schema,
      table: table.table_name,
    })
    .then((response: ApiResponse<{ rows: TableSample }>) => ({
      sample: response.data.data.rows,
      table,
    }))
    .catch((reason: ErrorResponse) => {
      const e: AppError = {
        error: {
          message:
            reason?.response?.data?.message || "Failed to sample source table.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const SourcesService = {
  getSources,
  getImportSources,
  testNewSource,
  postSource,
  postImportSource,
  patchSource,
  patchImportSource,
  deleteSource,
  deleteImportSource,
  getVendors,
  testExistingSource,
  testExistingImportSource,
  postListSourceTables,
  postListExistingSourceTables,
  postSampleSourceTable,
  postSampleExistingSourceTable,
};
export default SourcesService;
