import React, { useEffect } from "react";

import { useRoutes, NavLink } from "react-router-dom";
import {
  Badge,
  PageHeading,
  Tabs,
  checkTabIsActive,
  BadgeColor,
} from "@prequel-internal/react-components";

import OverviewChart from "../OverviewChart";

import AlertsTable from "../AlertsTable";
import SuccessesTable from "../SuccessesTable";

import { useTypedDispatch, useTypedSelector } from "../../store";

import {
  fetchDestinations,
  selectDestinations,
} from "../../store/destinations/destinations.duck";

const DestinationsPage = () => {
  const dispatch = useTypedDispatch();
  const destinations = useTypedSelector(selectDestinations);

  useEffect(() => {
    dispatch(fetchDestinations());
  }, [dispatch]);

  const errorDestinations = destinations?.filter(
    (transfer) => transfer.last_completed_transfer?.status != "SUCCESS"
  );
  const successDestinations = destinations?.filter(
    (transfer) => transfer.last_completed_transfer?.status == "SUCCESS"
  );

  const tabs = [
    <NavLink key="Alerts" to="" className={checkTabIsActive} replace end>
      {({ isActive }) => (
        <div className="space-x-2.5">
          <span>Alerts</span>
          {errorDestinations?.length && errorDestinations.length > 0 ? (
            <Badge
              text={errorDestinations.length.toString()}
              color={isActive ? BadgeColor.RED : BadgeColor.GRAY}
            />
          ) : undefined}
        </div>
      )}
    </NavLink>,
    <NavLink
      key="Successes"
      to="successes"
      className={checkTabIsActive}
      replace
      end
    >
      Successes
    </NavLink>,
  ];

  const element = useRoutes([
    {
      path: "/",
      element: <AlertsTable destinations={errorDestinations} />,
    },
    {
      path: "/successes",
      element: <SuccessesTable destinations={successDestinations} />,
    },
  ]);

  return (
    <div className="flex flex-col pb-4">
      <div className="max-w-6xl pb-16">
        <PageHeading title="Overview" />
        <OverviewChart />
        <Tabs tabs={tabs} />
        {element}
      </div>
    </div>
  );
};

export default DestinationsPage;
