import React, { useEffect } from "react";
import { useRoutes, Navigate } from "react-router-dom";
import { Spinner } from "@prequel-internal/react-components";

import { useTypedSelector, useTypedDispatch } from "../../store";
import {
  fetchAuthStatus,
  selectAuthenticated,
} from "../../store/auth/auth.duck";
import LoginCard from "../LoginCard";
import LoginSuccessfulCard from "../LoginSuccessfulCard";

type AuthenticationProps = {
  children: React.ReactNode;
};
const Authentication = ({ children }: AuthenticationProps) => {
  const dispatch = useTypedDispatch();
  const authenticated = useTypedSelector(selectAuthenticated);

  // `authenticated` is undefined if the frontend does not know the auth state
  // `authenticated` is false if the user is not authenticated
  // `authenticated` is true if the user is authenticated

  useEffect(() => {
    if (authenticated === undefined) {
      dispatch(fetchAuthStatus());
    }
  }, [dispatch, authenticated]);

  // Determine the unauthenticated route to render based on the path
  const unauthenticatedElement = useRoutes([
    { path: "/login", element: <LoginCard /> },
    { path: "/login-successful", element: <LoginSuccessfulCard /> },
    { path: "*", element: <Navigate to="/login" replace /> },
  ]);

  // Override the unauthenticated element with a loading screen if authentication status is loading
  const unauthenticatedOrLoadingElement =
    authenticated === undefined ? <Spinner /> : unauthenticatedElement;

  // If authenticated, render children and ignore unauthenticated routes
  if (authenticated) {
    return <>{children}</>;
  }

  return (
    <main className="h-screen w-screen bg-gray-50">
      {unauthenticatedOrLoadingElement}
    </main>
  );
};

export default Authentication;
