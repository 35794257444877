import React from "react";
import { useNavigate } from "react-router-dom";
import { useDestinationVendors, ExistingDestination } from "@prequel/react";
import {
  Badge,
  BadgeColor,
  Table,
  TableRow,
} from "@prequel-internal/react-components";

import VendorLogo from "../VendorLogo";
import { getReadableVendor, getVendorLogoUrl } from "../../lib";
import {
  getReadableLastSuccessfulSync,
  sortByLastSuccessfulSync,
} from "../../store/destinations";
import { env } from "../../env";

const SuccessesTable = ({
  destinations,
}: {
  destinations?: ExistingDestination[];
}) => {
  const navigate = useNavigate();

  const destinationVendors = useDestinationVendors(env.REACT_APP_API_SERVER);

  // copy the array before sorting because the array is frozen
  const rows = [...(destinations || [])]
    .sort(sortByLastSuccessfulSync)
    .map((destination) => ({
      id: destination.id,
      cells: [
        {
          value: destination.name,
        },
        {
          value: (
            <div className="flex items-center">
              <VendorLogo
                logo_url={getVendorLogoUrl(
                  destination.vendor,
                  destinationVendors.destinations || []
                )}
              />
              {getReadableVendor(
                destination.vendor,
                destinationVendors.destinations || []
              )}
            </div>
          ),
        },
        {
          value: (
            <Badge
              color={
                destination.last_completed_transfer?.status == "SUCCESS"
                  ? BadgeColor.GREEN
                  : BadgeColor.RED
              }
              text={destination.last_completed_transfer?.status ?? "UNKNOWN"}
            />
          ),
        },
        {
          value:
            destination.last_completed_transfer?.rows_transferred.toLocaleString(),
        },

        {
          value: (
            <div className="text-gray-500">
              {getReadableLastSuccessfulSync(
                destination.last_completed_transfer?.ended_at
              )}
            </div>
          ),
        },
      ],
    }));

  const columns = [
    "Destination Name",
    "Vendor",
    "Status",
    "Rows Transferred",
    "Last Successful Sync",
  ];

  const onRowClick = (row: TableRow) =>
    navigate(`/export/destinations/${row.id}/transfers`);

  return (
    <Table
      columns={columns}
      rows={rows || []}
      footer={rows ? `${rows.length} successful transfers` : ""}
      onRowClick={onRowClick}
    />
  );
};

export default SuccessesTable;
