import React, { useEffect, useMemo, useState } from "react";
import { ModelConfig } from "@prequel/react";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import {
  Button,
  ButtonStyle,
  DropdownListItem,
  Spinner,
  Steps,
} from "@prequel-internal/react-components";

import {
  prepareImportSource,
  PreparedImportSource,
  ImportSource,
  SourceType,
  computeChangedImportFields,
} from "../../store/sources";
import ImportSourceForm from "./ImportSourceForm";
import ImportQueryForm from "./ImportQueryForm";
import ImportMapForm from "./ImportMapForm";
import { useTypedDispatch, useTypedSelector } from "../../store";
import {
  createImportSource,
  resetSourceTest,
  resetTableSample,
  selectImportSource,
  selectTableSample,
  updateImportSource,
} from "../../store/sources/sources.duck";
import {
  fetchImportModelConfigs,
  selectImportModelConfigs,
} from "../../store/configs/configs.duck";
import ExpectedColumns from "./ExpectedColumns";

const STEPS = [
  "Connect Import Source",
  "Query Import Source",
  "Map Import Source",
];

const ImportSourceSteps = () => {
  const navigate = useNavigate();
  const { connectionId } = useParams<{ connectionId: string }>();
  const dispatch = useTypedDispatch();
  const importConfigs = useTypedSelector(selectImportModelConfigs);
  const tableSample = useTypedSelector(selectTableSample);
  const [modelConfig, setModelConfig] = useState<ModelConfig>();
  const [step, setStep] = useState<number>(0);
  const [canGoForward, setCanGoForward] = useState(false);
  const [source, setSource] = useState<ImportSource>({
    type: SourceType.ImportSource,
    name: "",
    vendor: "",
    is_enabled: true,
    use_ssh_tunnel: false,
    host: "",
    port: "",
    database: "",
    schema: "",
    username: "",
    password: "",
    provider_id: "",
    bucket_name: "",
    bucket_region: "",
    bucket_vendor: "",
    aws_iam_role: "",
    bucket_access_id: "",
    bucket_secret_key: "",
    service_account_key: "",
    ssh_public_key: "",
    ssh_tunnel_host: "",
    ssh_tunnel_port: "",
    ssh_tunnel_username: "",
    model_mappings: [],
  });
  const [destinationToSourceMap, setDestinationToSourceMap] = useState<{
    [key: string]: DropdownListItem<string> | undefined;
  }>({});
  const sourceToEdit = useTypedSelector((state) =>
    selectImportSource(state, connectionId)
  );
  const isEditing = !!sourceToEdit; // Evaluates to true if sourceToEdit exists, otherwise evaluates to false

  useEffect(() => {
    dispatch(fetchImportModelConfigs());
  }, [dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetSourceTest());
      dispatch(resetTableSample());
    };
  }, []);

  useEffect(() => {
    if (importConfigs && importConfigs.length) {
      setModelConfig(importConfigs[0]);
    }
  }, [importConfigs]);

  const preparedSource: PreparedImportSource = useMemo(
    () => prepareImportSource(source),
    [source]
  );

  const goBack = () => {
    setStep(step - 1);
    setCanGoForward(false);
  };

  const goForward = () => {
    setStep(step + 1);
    setCanGoForward(false);
  };

  const submit = () => {
    if (modelConfig) {
      const mapping = {
        model_name: modelConfig.model_name,
        source_table_name: tableSample?.table?.table_name ?? "",
        description: modelConfig.description,
        mappings: modelConfig.columns.map((column) => ({
          ...column,
          name_in_source:
            destinationToSourceMap[column.name_in_destination]?.key ?? "",
        })),
      };
      source.schema = tableSample?.table?.schema ?? "";
      source.model_mappings = [mapping];

      if (isEditing) {
        const changed = computeChangedImportFields(
          sourceToEdit,
          preparedSource
        );
        dispatch(
          updateImportSource({
            sourceId: sourceToEdit.id,
            source: changed,
            redirect: () => navigate("/import/sources"),
          })
        );
      } else {
        dispatch(
          createImportSource({
            source: prepareImportSource(source),
            redirect: () => navigate("/import/sources"),
          })
        );
      }
    }
  };

  if (connectionId && sourceToEdit === undefined) {
    return <Spinner />;
  }

  if (connectionId && sourceToEdit === null) {
    return <Navigate to="/import/sources" replace />;
  }

  return (
    <>
      <Steps currentStep={step} steps={STEPS} setStep={setStep} />
      <div className="mt-6 mb-16 grid grid-cols-3 gap-4">
        <div className="col-start-0 col-span-2">
          {step === 0 && (
            <ImportSourceForm
              source={source}
              setSource={setSource}
              setCanGoForward={setCanGoForward}
              sourceToEdit={sourceToEdit}
              isEditing={isEditing}
            />
          )}
          {step === 1 && (
            <ImportQueryForm
              source={preparedSource}
              setCanGoForward={setCanGoForward}
              sourceToEdit={sourceToEdit}
              isEditing={isEditing}
            />
          )}
          {step === 2 && modelConfig && (
            <ImportMapForm
              config={modelConfig}
              destinationToSourceMap={destinationToSourceMap}
              setDestinationToSourceMap={setDestinationToSourceMap}
              setCanGoForward={setCanGoForward}
            />
          )}
          <div className="flex mt-8">
            {step > 0 && (
              <Button
                text="Back"
                type={ButtonStyle.TERTIARY}
                onClick={goBack}
              />
            )}
            {connectionId && step === 0 && (
              <Button
                className="mr-3"
                type={ButtonStyle.TERTIARY}
                onClick={() => navigate(-1)}
                text="Cancel"
              />
            )}
            {step === 2 ? (
              <Button
                className="ml-auto"
                type={canGoForward ? ButtonStyle.PRIMARY : ButtonStyle.TERTIARY}
                onClick={submit}
                text="Save Connection"
                disabled={!canGoForward}
              />
            ) : (
              <Button
                className="ml-auto"
                type={canGoForward ? ButtonStyle.PRIMARY : ButtonStyle.TERTIARY}
                onClick={goForward}
                text="Continue"
                disabled={!canGoForward}
              />
            )}
          </div>
        </div>
        {modelConfig && (
          <div className="col-start-3 col-span-1">
            <ExpectedColumns config={modelConfig} />
          </div>
        )}
      </div>
    </>
  );
};

export default ImportSourceSteps;
