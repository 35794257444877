import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { all, takeEvery, put } from "redux-saga/effects";
import { ExistingDestination, PreparedDestination } from "@prequel/react";

import { AppError, RequestStatus } from "../../axios";
import {
  DestinationRequest,
  ExistingDestinationPayload,
  ExistingImportDestination,
  ExistingImportDestinationPayload,
  PreparedImportDestination,
} from ".";
import DestinationsService from "./destinations.service";
import {
  RootState,
  createWorkerSaga,
  createRedirectSaga,
  WithRedirect,
} from "..";
import { Root } from "react-dom/client";

// Slice state
type DestinationsState = {
  destinations: ExistingDestination[] | undefined;
  importDestinations: ExistingImportDestination[] | undefined;
  destinationTest: DestinationRequest;
  destinationRequest: DestinationRequest;
};
const initialState: DestinationsState = {
  destinations: undefined,
  importDestinations: undefined,
  destinationTest: { status: undefined },
  destinationRequest: { status: undefined },
};

// Action Reducers (Case Reducers)
const fetchDestinationsReducer: CaseReducer<
  DestinationsState,
  PayloadAction<void>
> = (state) => state;

const fetchDestinationsSuccessReducer: CaseReducer<
  DestinationsState,
  PayloadAction<ExistingDestination[]>
> = (state, action) => {
  action.payload.forEach((destination) => {
    // This enabled_models truthiness check is defensive, we saw an error previously where undefined enabled_models were causing silent errors
    destination.enabled_models?.sort((a, b) => a.localeCompare(b));
  });
  state.destinations = action.payload;
};

const fetchDestinationsFailureReducer: CaseReducer<
  DestinationsState,
  PayloadAction<AppError>
> = (state) => state;

const fetchImportDestinationsReducer: CaseReducer<
  DestinationsState,
  PayloadAction<void>
> = (state) => state;

const fetchImportDestinationsSuccessReducer: CaseReducer<
  DestinationsState,
  PayloadAction<ExistingImportDestination[]>
> = (state, action) => {
  state.importDestinations = action.payload;
};

const fetchImportDestinationsFailureReducer: CaseReducer<
  DestinationsState,
  PayloadAction<AppError>
> = (state) => state;

const testNewDestinationReducer: CaseReducer<
  DestinationsState,
  PayloadAction<PreparedDestination | PreparedImportDestination>
> = (state) => {
  state.destinationTest.status = "processing";
};

const testNewDestinationSuccessReducer: CaseReducer<
  DestinationsState,
  PayloadAction<RequestStatus>
> = (state) => {
  state.destinationTest.status = "success";
};

const testNewDestinationFailureReducer: CaseReducer<
  DestinationsState,
  PayloadAction<AppError>
> = (state, action) => {
  state.destinationTest = {
    status: "error",
    message: action.payload.error.message,
  };
};

const testExistingDestinationReducer: CaseReducer<
  DestinationsState,
  PayloadAction<ExistingDestinationPayload>
> = (state) => {
  state.destinationTest.status = "processing";
};

const testExistingDestinationSuccessReducer: CaseReducer<
  DestinationsState,
  PayloadAction<RequestStatus>
> = (state) => {
  state.destinationTest.status = "success";
};

const testExistingDestinationFailureReducer: CaseReducer<
  DestinationsState,
  PayloadAction<AppError>
> = (state, action) => {
  state.destinationTest = {
    status: "error",
    message: action.payload.error.message,
  };
};

const testExistingImportDestinationReducer: CaseReducer<
  DestinationsState,
  PayloadAction<ExistingImportDestinationPayload>
> = (state) => {
  state.destinationTest.status = "processing";
};

const testExistingImportDestinationSuccessReducer: CaseReducer<
  DestinationsState,
  PayloadAction<RequestStatus>
> = (state) => {
  state.destinationTest.status = "success";
};

const testExistingImportDestinationFailureReducer: CaseReducer<
  DestinationsState,
  PayloadAction<AppError>
> = (state, action) => {
  state.destinationTest = {
    status: "error",
    message: action.payload.error.message,
  };
};

const createDestinationReducer: CaseReducer<
  DestinationsState,
  PayloadAction<WithRedirect<{ destination: PreparedDestination }>>
> = (state) => {
  state.destinationRequest.status = "processing";
};

const createDestinationSuccessReducer: CaseReducer<
  DestinationsState,
  PayloadAction<WithRedirect<{ destination: ExistingDestination }>>
> = (state) => {
  state.destinationRequest.status = "success";
};

const createDestinationFailureReducer: CaseReducer<
  DestinationsState,
  PayloadAction<AppError>
> = (state, action) => {
  state.destinationRequest = {
    status: "error",
    message: action.payload.error.message,
  };
};

const createImportDestinationReducer: CaseReducer<
  DestinationsState,
  PayloadAction<WithRedirect<{ destination: PreparedImportDestination }>>
> = (state) => {
  state.destinationRequest.status = "processing";
};

const createImportDestinationSuccessReducer: CaseReducer<
  DestinationsState,
  PayloadAction<WithRedirect<{ destination: ExistingImportDestination }>>
> = (state) => {
  state.destinationRequest.status = "success";
};

const createImportDestinationFailureReducer: CaseReducer<
  DestinationsState,
  PayloadAction<AppError>
> = (state, action) => {
  state.destinationRequest = {
    status: "error",
    message: action.payload.error.message,
  };
};

const updateDestinationReducer: CaseReducer<
  DestinationsState,
  PayloadAction<WithRedirect<ExistingDestinationPayload>>
> = (state) => {
  state.destinationRequest.status = "processing";
};

const updateDestinationSuccessReducer: CaseReducer<
  DestinationsState,
  PayloadAction<{ destination: ExistingDestination }>
> = (state, action) => {
  const updatedDestination = action.payload.destination;
  state.destinations = state.destinations?.map((d) =>
    d.id === updatedDestination.id ? { ...d, ...updatedDestination } : d
  );
  state.destinationRequest.status = "success";
};

const updateDestinationFailureReducer: CaseReducer<
  DestinationsState,
  PayloadAction<AppError>
> = (state, action) => {
  state.destinationRequest = {
    status: "error",
    message: action.payload.error.message,
  };
};

const updateImportDestinationReducer: CaseReducer<
  DestinationsState,
  PayloadAction<WithRedirect<ExistingImportDestinationPayload>>
> = (state) => {
  state.destinationRequest.status = "processing";
};

const updateImportDestinationSuccessReducer: CaseReducer<
  DestinationsState,
  PayloadAction<{ destination: ExistingImportDestination }>
> = (state, action) => {
  const updatedDestination = action.payload.destination;
  state.importDestinations = state.importDestinations?.map((d) =>
    d.id === updatedDestination.id ? { ...d, ...updatedDestination } : d
  );
  state.destinationRequest.status = "success";
};

const updateImportDestinationFailureReducer: CaseReducer<
  DestinationsState,
  PayloadAction<AppError>
> = (state, action) => {
  state.destinationRequest = {
    status: "error",
    message: action.payload.error.message,
  };
};

const deleteDestinationReducer: CaseReducer<
  DestinationsState,
  PayloadAction<
    WithRedirect<{
      destinationId: ExistingDestination["id"];
    }>
  >
> = (state) => state;

const deleteDestinationSuccessReducer: CaseReducer<
  DestinationsState,
  PayloadAction<WithRedirect<{}>>
> = (state) => state;

const deleteDestinationFailureReducer: CaseReducer<
  DestinationsState,
  PayloadAction<AppError>
> = (state) => state;

const deleteImportDestinationReducer: CaseReducer<
  DestinationsState,
  PayloadAction<
    WithRedirect<{
      destinationId: ExistingImportDestination["id"];
    }>
  >
> = (state) => state;

const deleteImportDestinationSuccessReducer: CaseReducer<
  DestinationsState,
  PayloadAction<WithRedirect<{}>>
> = (state) => state;

const deleteImportDestinationFailureReducer: CaseReducer<
  DestinationsState,
  PayloadAction<AppError>
> = (state) => state;

const resetDestinationTestReducer: CaseReducer<
  DestinationsState,
  PayloadAction<void>
> = (state) => {
  state.destinationTest.status = undefined;
};

const resetDestinationRequestReducer: CaseReducer<
  DestinationsState,
  PayloadAction<void>
> = (state) => {
  state.destinationRequest.status = undefined;
};

function* watchFetchDestinations() {
  yield takeEvery(
    fetchDestinations.type,
    createWorkerSaga(
      fetchDestinations,
      fetchDestinationsSuccess,
      fetchDestinationsFailure,
      DestinationsService.getDestinations
    )
  );
}

function* watchFetchImportDestinations() {
  yield takeEvery(
    fetchImportDestinations.type,
    createWorkerSaga(
      fetchImportDestinations,
      fetchImportDestinationsSuccess,
      fetchImportDestinationsFailure,
      DestinationsService.getImportDestinations
    )
  );
}

function* watchTestNewDestination() {
  yield takeEvery(
    testNewDestination.type,
    createWorkerSaga(
      testNewDestination,
      testNewDestinationSuccess,
      testNewDestinationFailure,
      DestinationsService.testNewDestination
    )
  );
}

function* watchTestExistingDestination() {
  yield takeEvery(
    testExistingDestination.type,
    createWorkerSaga(
      testExistingDestination,
      testExistingDestinationSuccess,
      testExistingDestinationFailure,
      DestinationsService.testExistingDestination
    )
  );
}

function* watchTestExistingImportDestination() {
  yield takeEvery(
    testExistingImportDestination.type,
    createWorkerSaga(
      testExistingImportDestination,
      testExistingImportDestinationSuccess,
      testExistingImportDestinationFailure,
      DestinationsService.testExistingImportDestination
    )
  );
}

function* watchCreateDestination() {
  yield takeEvery(
    createDestination.type,
    createWorkerSaga(
      createDestination,
      createDestinationSuccess,
      createDestinationFailure,
      DestinationsService.postDestination
    )
  );
}

function* watchCreateImportDestination() {
  yield takeEvery(
    createImportDestination.type,
    createWorkerSaga(
      createImportDestination,
      createImportDestinationSuccess,
      createImportDestinationFailure,
      DestinationsService.postImportDestination
    )
  );
}

function* watchUpdateDestination() {
  yield takeEvery(
    updateDestination.type,
    createWorkerSaga(
      updateDestination,
      updateDestinationSuccess,
      updateDestinationFailure,
      DestinationsService.patchDestination
    )
  );
}

function* watchUpdateImportDestination() {
  yield takeEvery(
    updateImportDestination.type,
    createWorkerSaga(
      updateImportDestination,
      updateImportDestinationSuccess,
      updateImportDestinationFailure,
      DestinationsService.patchImportDestination
    )
  );
}

function* watchDeleteDestination() {
  yield takeEvery(
    deleteDestination.type,
    createWorkerSaga(
      deleteDestination,
      deleteDestinationSuccess,
      deleteDestinationFailure,
      DestinationsService.deleteDestination
    )
  );
}

function* watchDeleteImportDestination() {
  yield takeEvery(
    deleteImportDestination.type,
    createWorkerSaga(
      deleteImportDestination,
      deleteImportDestinationSuccess,
      deleteImportDestinationFailure,
      DestinationsService.deleteImportDestination
    )
  );
}

function* watchCreateDestinationSuccess() {
  yield takeEvery([createDestinationSuccess.type], function* () {
    yield put(fetchDestinations());
  });
  yield takeEvery(createDestinationSuccess.type, createRedirectSaga());
}

function* watchCreateImportDestinationSuccess() {
  yield takeEvery([createImportDestinationSuccess.type], function* () {
    yield put(fetchImportDestinations());
  });
  yield takeEvery(createImportDestinationSuccess.type, createRedirectSaga());
}

function* watchUpdateDestinationSuccess() {
  yield takeEvery(updateDestinationSuccess.type, function* () {
    yield put(fetchDestinations());
  });
  yield takeEvery(updateDestinationSuccess.type, createRedirectSaga());
}

function* watchUpdateImportDestinationSuccess() {
  yield takeEvery(updateImportDestinationSuccess.type, function* () {
    yield put(fetchImportDestinations());
  });
  yield takeEvery(updateImportDestinationSuccess.type, createRedirectSaga());
}

function* watchDeleteDestinationSuccess() {
  yield takeEvery(deleteDestinationSuccess.type, function* () {
    yield put(fetchDestinations());
  });
  yield takeEvery(deleteDestinationSuccess.type, createRedirectSaga());
}

function* watchDeleteImportDestinationSuccess() {
  yield takeEvery(deleteImportDestinationSuccess.type, function* () {
    yield put(fetchImportDestinations());
  });
  yield takeEvery(deleteImportDestinationSuccess.type, createRedirectSaga());
}

const destinationsSlice = createSlice({
  name: "destinations",
  initialState,
  reducers: {
    fetchDestinations: fetchDestinationsReducer,
    fetchDestinationsSuccess: fetchDestinationsSuccessReducer,
    fetchDestinationsFailure: fetchDestinationsFailureReducer,
    fetchImportDestinations: fetchImportDestinationsReducer,
    fetchImportDestinationsSuccess: fetchImportDestinationsSuccessReducer,
    fetchImportDestinationsFailure: fetchImportDestinationsFailureReducer,
    testNewDestination: testNewDestinationReducer,
    testNewDestinationSuccess: testNewDestinationSuccessReducer,
    testNewDestinationFailure: testNewDestinationFailureReducer,
    testExistingDestination: testExistingDestinationReducer,
    testExistingDestinationSuccess: testExistingDestinationSuccessReducer,
    testExistingDestinationFailure: testExistingDestinationFailureReducer,
    testExistingImportDestination: testExistingImportDestinationReducer,
    testExistingImportDestinationSuccess:
      testExistingImportDestinationSuccessReducer,
    testExistingImportDestinationFailure:
      testExistingImportDestinationFailureReducer,
    resetDestinationTest: resetDestinationTestReducer,
    resetDestinationRequest: resetDestinationRequestReducer,
    createDestination: createDestinationReducer,
    createDestinationSuccess: createDestinationSuccessReducer,
    createDestinationFailure: createDestinationFailureReducer,
    createImportDestination: createImportDestinationReducer,
    createImportDestinationSuccess: createImportDestinationSuccessReducer,
    createImportDestinationFailure: createImportDestinationFailureReducer,
    updateDestination: updateDestinationReducer,
    updateDestinationSuccess: updateDestinationSuccessReducer,
    updateDestinationFailure: updateDestinationFailureReducer,
    updateImportDestination: updateImportDestinationReducer,
    updateImportDestinationSuccess: updateImportDestinationSuccessReducer,
    updateImportDestinationFailure: updateImportDestinationFailureReducer,
    deleteDestination: deleteDestinationReducer,
    deleteDestinationSuccess: deleteDestinationSuccessReducer,
    deleteDestinationFailure: deleteDestinationFailureReducer,
    deleteImportDestination: deleteImportDestinationReducer,
    deleteImportDestinationSuccess: deleteImportDestinationSuccessReducer,
    deleteImportDestinationFailure: deleteImportDestinationFailureReducer,
  },
});

export const {
  fetchDestinations,
  fetchDestinationsSuccess,
  fetchDestinationsFailure,
  fetchImportDestinations,
  fetchImportDestinationsSuccess,
  fetchImportDestinationsFailure,
  testNewDestination,
  testNewDestinationSuccess,
  testNewDestinationFailure,
  testExistingDestination,
  testExistingDestinationSuccess,
  testExistingDestinationFailure,
  testExistingImportDestination,
  testExistingImportDestinationSuccess,
  testExistingImportDestinationFailure,
  resetDestinationTest,
  resetDestinationRequest,
  createDestination,
  createDestinationSuccess,
  createDestinationFailure,
  createImportDestination,
  createImportDestinationSuccess,
  createImportDestinationFailure,
  updateDestination,
  updateDestinationSuccess,
  updateDestinationFailure,
  updateImportDestination,
  updateImportDestinationSuccess,
  updateImportDestinationFailure,
  deleteDestination,
  deleteDestinationSuccess,
  deleteDestinationFailure,
  deleteImportDestination,
  deleteImportDestinationSuccess,
  deleteImportDestinationFailure,
} = destinationsSlice.actions;

export const selectDestinations = ({ destinations }: RootState) =>
  destinations.destinations;
export const selectImportDestinations = ({ destinations }: RootState) =>
  destinations.importDestinations;
export const selectDestination: (
  state: RootState,
  id?: string
) => ExistingDestination | null | undefined = (state, destinationId) => {
  const destinations = selectDestinations(state);
  if (destinations === undefined) {
    // if we don't have destinations, return undefined as we need to wait for fetch
    return undefined;
  }

  const found = destinations.find(({ id }) => id === destinationId);
  // If we could not find the destination, return null to signify it is not in the loaded destinations
  return found ?? null;
};
export const selectImportDestination: (
  state: RootState,
  id?: string
) => ExistingImportDestination | null | undefined = (state, destinationId) => {
  const importDestinations = selectImportDestinations(state);
  if (importDestinations === undefined) {
    // if we don't have destinations, return undefined as we need to wait for fetch
    return undefined;
  }

  const found = importDestinations.find(({ id }) => id === destinationId);
  // If we could not find the destination, return null to signify it is not in the loaded destinations
  return found ?? null;
};
export const selectDestinationTest = ({ destinations }: RootState) =>
  destinations.destinationTest;
export const selectDestinationRequest = ({ destinations }: RootState) =>
  destinations.destinationRequest;

export function* destinationsSaga() {
  yield all([
    watchFetchDestinations(),
    watchFetchImportDestinations(),
    watchTestNewDestination(),
    watchTestExistingDestination(),
    watchTestExistingImportDestination(),
    watchCreateDestination(),
    watchCreateImportDestination(),
    watchCreateDestinationSuccess(),
    watchCreateImportDestinationSuccess(),
    watchUpdateDestination(),
    watchUpdateImportDestination(),
    watchUpdateDestinationSuccess(),
    watchUpdateImportDestinationSuccess(),
    watchDeleteDestination(),
    watchDeleteImportDestination(),
    watchDeleteDestinationSuccess(),
    watchDeleteImportDestinationSuccess(),
  ]);
}
export default destinationsSlice.reducer;
