import React from "react";
import { BrowserRouter as Router, useRoutes, Navigate } from "react-router-dom";

import Layout from "./containers/Layout";
import ToastsFeature from "./containers/ToastsFeature";
import ExportApp from "./ExportApp";
import ImportApp from "./ImportApp";

import Authentication from "./containers/Authentication";

const App = () => {
  const element = useRoutes([
    { path: "/export/*", element: <ExportApp /> },
    { path: "/import/*", element: <ImportApp /> },
    { path: "*", element: <Navigate to="/export/overview" replace /> },
  ]);

  return <Layout>{element}</Layout>;
};

const AppWrapper = () => {
  return (
    <Router>
      <ToastsFeature />
      <Authentication>
        <App />
      </Authentication>
    </Router>
  );
};

export default AppWrapper;
