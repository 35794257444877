import React from "react";
import { TableSample } from "../../../store/sources";

type ResultsTableProps = {
  rows: TableSample;
};
const ResultsTable = ({ rows }: ResultsTableProps) => {
  // Extract names of columns from row object
  const columns = rows.length >= 1 ? Object.keys(rows[0]) : [];

  // Flatten row object into arrays of values
  const tableRows = rows.map((row) => {
    return columns.map((column) => row[column]);
  });

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto">
        <div className="inline-block w-full py-2 align-middle">
          <div className="overflow-auto border md:rounded-md">
            <table className="w-full table-auto divide-y divide-slate-300">
              <thead className="bg-slate-50">
                <tr className="divide-x">
                  {columns.map((col, idx) => (
                    <th
                      scope="col"
                      key={`${col}-${idx}`}
                      className="max-w-xs px-4 py-2.5 text-xs text-slate-500 font-light uppercase tracking-wider text-left"
                    >
                      <div>{col}</div>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-slate-200">
                {tableRows.map((row) => {
                  const rowId = JSON.stringify(row);
                  return (
                    <tr key={rowId} className="divide-x">
                      {row.map((cell, idx) => (
                        <td
                          key={`${rowId}-${idx}`}
                          className="max-w-xs px-4 py-2 truncate text-sm text-gray-500"
                        >
                          {cell !== null && cell !== undefined ? (
                            cell.toString()
                          ) : (
                            <span className="italic">null</span>
                          )}
                        </td>
                      ))}
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResultsTable;
