import React, { useEffect, useMemo, useRef, useState } from "react";
import { VendorField } from "@prequel/react";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  ButtonStyle,
  Dropdown,
  DropdownListItem,
  Form,
  FormField,
} from "@prequel-internal/react-components";

import { DropdownListItemWithFields, Vendor } from "../../lib";
import { useTypedDispatch, useTypedSelector } from "../../store";
import { selectSourceVendors } from "../../store/sources/sources.duck";
import VendorLogo from "../VendorLogo";
import { ImportMagicLink } from "../../store/magic_links";
import { selectProviders } from "../../store/providers/providers.duck";
import ExistingProvider from "../../store/providers";
import ConfirmDataSourceModal from "../ConfirmDataSourceModal";
import { createImportMagicLink } from "../../store/magic_links/magic_links.duck";

type DropdownListItemWithName = DropdownListItem<string> & { name: string };
const ImportMagicLinkForm = () => {
  const buttonRef = useRef<HTMLButtonElement>(null);
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();
  const sourceVendors = useTypedSelector(selectSourceVendors);
  const providers = useTypedSelector(selectProviders);

  const [showModal, setShowModal] = useState(false);
  const [dropdownSources, setDropdownSources] =
    useState<DropdownListItemWithFields[]>();
  const [dropdownProviders, setDropdownProviders] =
    useState<DropdownListItemWithName[]>();
  const [selectedVendor, setSelectedVendor] =
    useState<DropdownListItemWithFields>({
      key: "",
      text: "",
      fields: [],
      docs: "",
      uses_service_account: false,
      uses_staging_bucket: false,
      supports_ssh_tunnel: false,
    });
  const [selectedProvider, setSelectedProvider] =
    useState<DropdownListItemWithName>({
      key: "",
      text: "",
      name: "",
    });

  const [link, setLink] = useState<ImportMagicLink>({
    vendor: selectedVendor.key,
    name: "",
    host: "",
    provider_id: "",
    bucket_name: "",
    bucket_vendor: "",
    bucket_access_id: "",
    ssh_public_key: "",
    config_to_map: {
      model_name: "",
      columns: [],
      source_table: "",
      description: "",
      organization_column: "",
    },
  });

  // convert into map of name -> object
  const formFields: { [key: string]: VendorField } = useMemo(() => {
    return selectedVendor.fields.reduce(
      (acc, obj) => ({ ...acc, [obj.name]: obj }),
      {}
    );
  }, [selectedVendor]);

  const setLinkField = (key: keyof ImportMagicLink, value: string) => {
    setLink((oldLink) => ({
      ...oldLink,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (sourceVendors) {
      const vendors = sourceVendors.map((s: Vendor) => {
        const icon = () => <VendorLogo logo_url={s.logo_url} />;
        return {
          ...s,
          key: s.vendor_name,
          text: s.display_name,
          icon,
          uses_service_account: false,
        };
      });

      setDropdownSources(vendors);
      setSelectedVendor(vendors[0]);
    }
  }, [sourceVendors]);

  useEffect(() => {
    if (providers) {
      const providerOptions = providers.map(
        ({ id, id_in_recipient_system, name }: ExistingProvider) => ({
          key: id,
          text: (
            <span className="flex items-center">
              <span className="mr-1">{name}</span>
              <span className="text-xs text-gray-500 truncate">
                ({id_in_recipient_system})
              </span>
            </span>
          ),
          name: name,
        })
      );

      setDropdownProviders(providerOptions);
      setSelectedProvider(providerOptions[0]);
    }
  }, [providers]);

  useEffect(() => {
    // Reset the bucket name on changes so it doesn't get passed in the destination
    setLink((oldLink) => ({
      ...oldLink,
      bucket_name: "",
      vendor: selectedVendor.key,
    }));
  }, [selectedVendor]);

  useEffect(() => {
    setLinkField("provider_id", selectedProvider?.key ?? "");
  }, [selectedProvider]);

  const confirm = () => {
    setShowModal(false);
    dispatch(
      createImportMagicLink({
        link,
        redirect: () => navigate("/import/magic-links"),
      })
    );
  };

  const onSubmit = (event: React.ChangeEvent<HTMLFormElement>) => {
    event.preventDefault();
    // If there are products to select and we have not picked one, prevent creation
    // if (products && selectedProducts.length == 0) {
    //   setProductsError("Must select at least one product");
    //   return;
    // }

    setShowModal(true);
  };

  return (
    <>
      <ConfirmDataSourceModal
        kind="GENERATE_LINK"
        show={showModal}
        setShow={setShowModal}
        dataIdentifier={selectedProvider?.name}
        dataSource={link}
        onConfirm={confirm}
      />
      <Form
        className="space-y-4"
        onSubmit={onSubmit}
        submitButtonRef={buttonRef}
      >
        {dropdownSources && (
          <Dropdown
            label={"Import source type"}
            items={dropdownSources}
            selectedItem={selectedVendor}
            setSelectedItem={setSelectedVendor}
          />
        )}
        <FormField
          label="Name"
          id="name"
          type="text"
          subtext="Descriptive name for this Magic Link. This will only be visible internally and is only used as a reference."
          value={link.name}
          onChangeHandler={(value: string) => setLinkField("name", value)}
          required
        />
        {dropdownProviders && (
          <div>
            <Dropdown
              label={"Provider"}
              placeholder={
                <div className="text-sm text-gray-400">
                  No providers have been created yet. You can add one{" "}
                  <Link
                    className="text-emerald-600 hover:text-emerald-800"
                    to="/import/providers/new"
                  >
                    here
                  </Link>
                  .{" "}
                </div>
              }
              items={dropdownProviders}
              selectedItem={selectedProvider}
              setSelectedItem={setSelectedProvider}
            />
            <p
              className="mt-2 text-xs text-gray-400 whitespace-pre-line"
              id="description"
            >
              {"Provider name (id_in_recipient_system)"}
            </p>
          </div>
        )}
        {"host" in formFields && (
          <FormField
            label="Host of Source"
            id="host"
            type="text"
            subtext="Host address of the source database. The owner of the import source (your customer) should provide this value."
            value={link.host}
            onChangeHandler={(value: string) => setLinkField("host", value)}
            required
          />
        )}
        {"bucket_vendor" in formFields && (
          <FormField
            label="Bucket Vendor"
            id="bucket_vendor"
            type="text"
            subtext="Vendor of storage bucket to use. The owner of the source (your customer) should provide this value."
            value={link.bucket_vendor}
            onChangeHandler={(value: string) =>
              setLinkField("bucket_vendor", value)
            }
            required
          />
        )}
        {"bucket_name" in formFields && (
          <FormField
            label="Bucket Name"
            id="bucket_name"
            type="text"
            subtext="Name of storage bucket to use. The owner of the source (your customer) should provide this value."
            value={link.bucket_name}
            onChangeHandler={(value: string) =>
              setLinkField("bucket_name", value)
            }
            required
          />
        )}
        {"bucket_access_id" in formFields && (
          <FormField
            label="Bucket Access ID"
            id="bucket_access_id"
            type="text"
            subtext="Azure Blob Storage Access ID. The owner of the source (your customer) should provide this value."
            value={link.bucket_access_id}
            onChangeHandler={(value: string) =>
              setLinkField("bucket_access_id", value)
            }
            required
          />
        )}
        <div>
          <Button
            className="mt-6"
            submit
            text="Generate Link"
            type={ButtonStyle.PRIMARY}
            ref={buttonRef}
            // Disable the button if there are no providers created yet
            disabled={!dropdownProviders || dropdownProviders.length === 0}
          />
        </div>
      </Form>
    </>
  );
};

export default ImportMagicLinkForm;
