import { addDays, formatDistanceToNow, parseJSON } from "date-fns";
import { ModelConfig } from "@prequel/react";

const MAGIC_LINK_TTL_DAYS = 7;

export const getTimeRemaining = (dateString: string) => {
  const date = parseJSON(dateString);
  const expirationDate = addDays(date, MAGIC_LINK_TTL_DAYS);
  return formatDistanceToNow(expirationDate);
};

export type FormMagicLink = Omit<
  MagicLink,
  "link" | "created_at" | "id" | "has_been_used"
>;
export default interface MagicLink {
  id: string;
  name: string;
  id_in_provider_system: string;
  recipient_id: string;
  host: string;
  link: string;
  created_at: string;
  has_been_used: boolean;
  bucket_name: string;
  vendor: string;
  bucket_vendor: string;
  bucket_access_id: string;
  products: string[];
  set_destination_enabled: boolean;
  redirect_url?: string;
}

export interface ImportMagicLink {
  name: string;
  vendor: string;
  provider_id: string;
  host: string;
  bucket_name: string;
  bucket_access_id: string;
  bucket_vendor: string;
  ssh_public_key: string;
  config_to_map: ModelConfig;
}

export interface ExistingImportMagicLink extends ImportMagicLink {
  id: string;
  link: string;
  has_been_used: boolean;
  created_at: string;
}

export const prepareMagicLink: (l: FormMagicLink, b: boolean) => FormMagicLink =
  (link, useIdTenating) => {
    /* Cleaning up the link before it gets created
  - Replace products with [] if using recipient_id
  - Ensure recipient_id is empty if using id_in_provider_system
  - Ensure id_in_provider_system is empty if using recipient_id
  */
    return {
      ...link,
      products: useIdTenating ? link.products : [],
      recipient_id: useIdTenating ? "" : link.recipient_id,
      id_in_provider_system: useIdTenating ? link.id_in_provider_system : "",
    };
  };
