import ExistingProvider, { Provider } from ".";
import { WithRedirect } from "..";
import axios, { ApiResponse, ApiRoutes, ErrorResponse } from "../../axios";

const getProviders: () => Promise<ExistingProvider[]> = () => {
  return axios
    .get(ApiRoutes.IMPORT_PROVIDERS)
    .then(
      (response: ApiResponse<{ providers: ExistingProvider[] }>) =>
        response.data.data.providers
    )
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message:
            reason.response?.data?.message || "Failed to fetch providers.",
          suppressGlobalNotification: true,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

const postProvider: ({
  provider,
  redirect,
}: WithRedirect<{ provider: Provider }>) => Promise<
  WithRedirect<{ provider: ExistingProvider }>
> = ({ provider, redirect }) => {
  return axios
    .post(ApiRoutes.IMPORT_PROVIDERS, { provider })
    .then((response: ApiResponse<{ provider: ExistingProvider }>) => ({
      provider: response.data.data.provider,
      redirect,
    }))
    .catch((reason: ErrorResponse) => {
      const e = {
        error: {
          message: reason.response?.data?.message || "Failed to post provider.",
          suppressGlobalNotification: false,
          statusCode: reason.response?.status,
        },
      };
      throw e;
    });
};

export default {
  getProviders,
  postProvider,
};
