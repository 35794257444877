import React from "react";
import { Destination, VendorField } from "@prequel/react";
import { FormField, PasswordDot } from "@prequel-internal/react-components";

import { ImportDestination } from "../../store/destinations";

type BucketFieldsProps = {
  usesStagingBucket: boolean;
  formFields: { [key: string]: VendorField };
  destination: ImportDestination;
  setDestinationField: (
    key: keyof ImportDestination,
    value: string | string[] | boolean
  ) => void;
  isEditing: boolean;
  disabled?: boolean;
};
const BucketFields = ({
  usesStagingBucket,
  formFields,
  destination,
  setDestinationField,
  isEditing,
  disabled,
}: BucketFieldsProps) => {
  return (
    <>
      {usesStagingBucket && (
        <>
          <label className="block text-sm font-medium text-gray-700">
            Enter the staging bucket credentials
          </label>
          <div className="mt-1">
            <p className="mt-1 text-sm text-gray-500">
              {
                "For some destinations without built in data staging areas, a staging bucket must be provided for efficent data loading. For assistance, "
              }
              <a
                href="https://docs.prequel.co/docs/s3-staging-bucket"
                target="_blank"
                rel="noreferrer"
                className="font-medium text-primary-600 hover:text-primary-500"
              >
                view our documentation on staging buckets.
              </a>
            </p>
          </div>
        </>
      )}
      {"bucket_vendor" in formFields && (
        <FormField
          id="bucket_vendor"
          type="text"
          label={formFields.bucket_vendor.label}
          placeholder={formFields.bucket_vendor.placeholder}
          subtext={formFields.bucket_vendor.help}
          value={destination?.bucket_vendor}
          onChangeHandler={(value: string) => {
            setDestinationField("bucket_vendor", value);
          }}
          required={formFields.bucket_vendor.is_required}
          disabled={disabled}
        />
      )}
      {"bucket_name" in formFields && (
        <FormField
          id="bucket_name"
          type="text"
          label={formFields.bucket_name.label}
          placeholder={formFields.bucket_name.placeholder}
          subtext={formFields.bucket_name.help}
          value={destination?.bucket_name}
          onChangeHandler={(value: string) => {
            setDestinationField("bucket_name", value);
          }}
          required={formFields.bucket_name.is_required}
          disabled={disabled || isEditing}
        />
      )}
      {"bucket_region" in formFields && (
        <FormField
          id="bucket_region"
          type="text"
          label={formFields.bucket_region.label}
          placeholder={formFields.bucket_region.placeholder}
          subtext={formFields.bucket_region.help}
          value={destination?.bucket_region}
          onChangeHandler={(value: string) => {
            setDestinationField("bucket_region", value);
          }}
          required={formFields.bucket_region.is_required}
          disabled={disabled}
        />
      )}
      {"bucket_access_id" in formFields && (
        <FormField
          id="bucket_access_id"
          type="text"
          label={formFields.bucket_access_id.label}
          placeholder={formFields.bucket_access_id.placeholder}
          subtext={formFields.bucket_access_id.help}
          value={destination?.bucket_access_id}
          onChangeHandler={(value: string) => {
            setDestinationField("bucket_access_id", value);
          }}
          required={formFields.bucket_access_id.is_required}
          disabled={disabled}
        />
      )}
      {"bucket_secret_key" in formFields && (
        <FormField
          id="bucket_secret_key"
          type="password"
          label={formFields.bucket_secret_key.label}
          placeholder={
            isEditing
              ? PasswordDot.repeat(40)
              : formFields.bucket_secret_key.placeholder
          }
          overwriteOnly={isEditing}
          subtext={formFields.bucket_secret_key.help}
          value={destination?.bucket_secret_key}
          onChangeHandler={(value: string) => {
            setDestinationField("bucket_secret_key", value);
          }}
          required={formFields.bucket_secret_key.is_required && !isEditing}
          disabled={disabled}
        />
      )}
    </>
  );
};

export default BucketFields;
