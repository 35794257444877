import {
  PreparedDestination,
  ExistingDestination,
  Transfer,
} from "@prequel/react";
import { formatDistanceToNow, parseJSON } from "date-fns";

import { RequestStatus } from "../../axios";
import { ServiceAccountKey, getValidServiceAccountKey } from "../../lib";

// Using this enum for the type values of the destination interfaces
// allows us easier type detection and to create discriminated unions
export enum ImportDestinationType {
  ImportDestination,
  PreparedImportDestination,
  ExistingImportDestination,
}

export interface ImportDestination {
  type: ImportDestinationType.ImportDestination;
  vendor: string;
  name: string;
  host?: string;
  port?: string;
  cluster?: string;
  database?: string;
  schema?: string;
  username?: string;
  password?: string;
  service_account_key?: string;
  bucket_vendor?: string;
  bucket_name?: string;
  bucket_region?: string;
  bucket_access_id?: string;
  bucket_secret_key?: string;
  is_bucket_credentials_implicit?: boolean;
  aws_iam_role?: string;
  gcp_iam_role?: string;
  use_ssh_tunnel: boolean;
  ssh_public_key?: string;
  ssh_tunnel_host?: string;
  ssh_tunnel_port?: string;
  ssh_tunnel_username?: string;
}

export interface ExistingImportDestination
  extends Omit<ImportDestination, "type"> {
  type: ImportDestinationType.ExistingImportDestination;
  id: string;
  created_at: string;
  updated_at: string;
}

export interface PreparedImportDestination {
  type: ImportDestinationType.PreparedImportDestination;
  vendor: string;
  name: string;
  host?: string;
  port?: number;
  cluster?: string;
  database?: string;
  schema?: string;
  username?: string;
  password?: string;
  service_account_key?: ServiceAccountKey;
  bucket_vendor?: string;
  bucket_name?: string;
  bucket_region?: string;
  bucket_access_id?: string;
  bucket_secret_key?: string;
  is_bucket_credentials_implicit?: boolean;
  aws_iam_role?: string;
  gcp_iam_role?: string;
  use_ssh_tunnel: boolean;
  ssh_public_key?: string;
  ssh_tunnel_host?: string;
  ssh_tunnel_port?: number;
  ssh_tunnel_username?: string;
}

export type DestinationRequest = {
  status: RequestStatus;
  message?: string;
};

export type ExistingDestinationPayload = {
  destinationId: ExistingDestination["id"];
  fields?: Partial<PreparedDestination>;
};

export type ExistingImportDestinationPayload = {
  destinationId: ExistingDestination["id"];
  fields?: Partial<PreparedImportDestination>;
};

export enum DestinationTenanting {
  BY_ID = 0,
  BY_RECIPIENT = 1,
}

export const getReadableLastSuccessfulSync = (
  lastSync?: Transfer["ended_at"]
) => {
  if (!lastSync) {
    return "Has not transferred successfully";
  }

  const lastSyncDate = parseJSON(lastSync);
  return formatDistanceToNow(lastSyncDate, { addSuffix: true });
};

export const sortByLastSuccessfulSync = (
  destA: ExistingDestination,
  destB: ExistingDestination
) => {
  const nullDate = new Date(0).toISOString(); // Create nullDate to replace null values with an early timestamp (epoch time)
  const dateA = parseJSON(destA.last_completed_transfer?.ended_at ?? nullDate);
  const dateB = parseJSON(destB.last_completed_transfer?.ended_at ?? nullDate);
  if (dateA > dateB) {
    return -1;
  }

  if (dateB > dateA) {
    return 1;
  }

  return 0;
};

export const isExistingDestination = (
  obj: ExistingDestination | PreparedDestination
): obj is ExistingDestination => "id" in obj;

export const getReadableModels = (models: string[] | undefined): string => {
  if (!models || models.length === 0) {
    return "No models";
  }

  if (models.length === 1 && models[0] === "*") {
    return "All available models";
  }

  return models.join(", ");
};

export const convertToImportDestination: (
  e: ExistingImportDestination
) => ImportDestination = (existing) => {
  return {
    ...existing,
    type: ImportDestinationType.ImportDestination,
    password: "",
    service_account_key: JSON.stringify(existing.service_account_key) ?? "",
    port: existing.port ? existing.port.toString() : "",
    ssh_tunnel_port: existing.ssh_tunnel_port
      ? existing.ssh_tunnel_port.toString()
      : "",
    use_ssh_tunnel: existing.use_ssh_tunnel ?? false,
  };
};

export const prepareImportDestination: (
  d: ImportDestination
) => PreparedImportDestination = (destination) => {
  const AWS_DESTINATIONS = ["athena", "redshift", "s3", "databricks"];
  const GCP_DESTINATIONS = ["bigquery", "gcs", "google_sheets"];

  let destServiceAccountKey = destination.service_account_key;
  let bucketAccessId = destination.bucket_access_id;
  let bucketSecretKey = destination.bucket_secret_key;
  let username = destination.username;
  let password = destination.password;

  if (destination.gcp_iam_role || destination.aws_iam_role) {
    // If we have IAM roles set, ensure usernames, passwords, and bucket creds are empty
    destServiceAccountKey = "";
    bucketAccessId = "";
    bucketSecretKey = "";
    // Username is relevant to redshift, databricks, and athena so we don't override in those cases
    username =
      destination.vendor === "redshift" ||
      destination.vendor === "databricks" ||
      destination.vendor === "athena"
        ? destination.username
        : "";
    // Databricks uses credentials for DB auth so the password can't be thrown away here
    password = destination.vendor === "databricks" ? destination.password : "";
  }

  if (GCP_DESTINATIONS.includes(destination.vendor)) {
    // Ensure aws_iam_role is empty if we're creating a GCP vendor
    destination.aws_iam_role = "";
  }

  if (AWS_DESTINATIONS.includes(destination.vendor)) {
    // Ensure gcp_iam_role is empty if we're creating an AWS vendor
    destination.gcp_iam_role = "";
  }

  const serviceAccountKey = getValidServiceAccountKey(destServiceAccountKey);

  const prepared: PreparedImportDestination = {
    ...destination,
    bucket_access_id: bucketAccessId,
    bucket_secret_key: bucketSecretKey,
    username,
    password,
    type: ImportDestinationType.PreparedImportDestination,
    service_account_key: serviceAccountKey,
    port: parseInt(destination.port || ""),
    ssh_tunnel_port: parseInt(destination.ssh_tunnel_port || ""),
  };

  return prepared;
};

export const computeChangedImportFields: (
  e: ExistingImportDestination,
  d: PreparedImportDestination
) => Partial<PreparedImportDestination> = (
  existingDestination,
  preparedDestination
) => {
  const changedFields: Partial<PreparedImportDestination> = {};
  for (const key in existingDestination) {
    if (
      existingDestination[key as keyof ExistingImportDestination] !==
      preparedDestination[key as keyof PreparedImportDestination]
    ) {
      Object.assign(changedFields, {
        [key]: preparedDestination[key as keyof PreparedImportDestination],
      });
    }
  }

  // Special case password because ExistingDestination does not have the password populated
  if (preparedDestination.password && preparedDestination.password !== "") {
    changedFields.password = preparedDestination.password;
  }

  // Special case service_account_key because ExistingDestination does not have service_account_key populated
  if (preparedDestination.service_account_key) {
    changedFields.service_account_key = preparedDestination.service_account_key;
  }

  return changedFields;
};
