import React from "react";
import { useParams } from "react-router-dom";

import TestSourceConnection from "../TestSourceConnection";
import { useTypedSelector } from "../../store";
import {
  selectImportSource,
  selectSource,
} from "../../store/sources/sources.duck";
import { Domain } from "../../lib";

type SourceConnectionDetailsProps = {
  domain: Domain;
};
const SourceConnectionDetails = ({ domain }: SourceConnectionDetailsProps) => {
  const { connectionId } = useParams<{ connectionId: string }>();
  const source =
    domain === Domain.EXPORT
      ? useTypedSelector((state) => selectSource(state, connectionId))
      : useTypedSelector((state) => selectImportSource(state, connectionId));

  if (!source) {
    return <div>The source could not be found.</div>;
  }
  return <TestSourceConnection existingSource={source} />;
};

export default SourceConnectionDetails;
