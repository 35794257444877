import { CaseReducer, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { all, takeEvery } from "redux-saga/effects";
import { ModelConfig, ProductConfig } from "@prequel/react";

import ConfigsService from "./configs.service";
import { createWorkerSaga, RootState } from "..";
import { AppError } from "../../axios";

type ConfigsState = {
  importModelConfigs: ModelConfig[] | undefined;
  importProductConfigs: ProductConfig[] | undefined;
};
const initialState: ConfigsState = {
  importModelConfigs: undefined,
  importProductConfigs: undefined,
};

const fetchImportModelConfigsReducer: CaseReducer<
  ConfigsState,
  PayloadAction<void>
> = (state) => state;

const fetchImportModelConfigsSuccessReducer: CaseReducer<
  ConfigsState,
  PayloadAction<ModelConfig[]>
> = (state, action) => {
  state.importModelConfigs = action.payload;
};

const fetchImportModelConfigsFailureReducer: CaseReducer<
  ConfigsState,
  PayloadAction<AppError>
> = (state) => state;

const fetchImportProductConfigsReducer: CaseReducer<
  ConfigsState,
  PayloadAction<void>
> = (state) => state;

const fetchImportProductConfigsSuccessReducer: CaseReducer<
  ConfigsState,
  PayloadAction<ProductConfig[]>
> = (state, action) => {
  // Only update the import product configs if there are any, otherwise keep as undefined because the org does not have any configs
  state.importProductConfigs =
    action.payload.length > 0 ? action.payload : undefined;
};

const fetchImportProductConfigsFailureReducer: CaseReducer<
  ConfigsState,
  PayloadAction<AppError>
> = (state) => state;

function* watchFetchImportModelConfigs() {
  yield takeEvery(
    fetchImportModelConfigs.type,
    createWorkerSaga(
      fetchImportModelConfigs,
      fetchImportModelConfigsSuccess,
      fetchImportModelConfigsFailure,
      ConfigsService.getImportModelConfigs
    )
  );
}

function* watchFetchImportProductConfigs() {
  yield takeEvery(
    fetchImportProductConfigs.type,
    createWorkerSaga(
      fetchImportProductConfigs,
      fetchImportProductConfigsSuccess,
      fetchImportProductConfigsFailure,
      ConfigsService.getImportProductConfigs
    )
  );
}

const configsSlice = createSlice({
  name: "configs",
  initialState,
  reducers: {
    fetchImportProductConfigs: fetchImportProductConfigsReducer,
    fetchImportProductConfigsSuccess: fetchImportProductConfigsSuccessReducer,
    fetchImportProductConfigsFailure: fetchImportProductConfigsFailureReducer,
    fetchImportModelConfigs: fetchImportModelConfigsReducer,
    fetchImportModelConfigsSuccess: fetchImportModelConfigsSuccessReducer,
    fetchImportModelConfigsFailure: fetchImportModelConfigsFailureReducer,
  },
});

export const {
  fetchImportProductConfigs,
  fetchImportProductConfigsSuccess,
  fetchImportProductConfigsFailure,
  fetchImportModelConfigs,
  fetchImportModelConfigsSuccess,
  fetchImportModelConfigsFailure,
} = configsSlice.actions;

export const selectImportModelConfigs = ({ configs }: RootState) =>
  configs.importModelConfigs;
export const selectImportProductConfigs = ({ configs }: RootState) =>
  configs.importProductConfigs;

export function* configsSaga() {
  yield all([watchFetchImportModelConfigs(), watchFetchImportProductConfigs()]);
}
export default configsSlice.reducer;
