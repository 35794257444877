import React from "react";
import { Navigate, useRoutes } from "react-router-dom";

import ImportMagicLinksPage from "./containers/ImportMagicLinksPage";
import ProvidersPage from "./containers/ProvidersPage";
import ThirdPartyConnectionsPage from "./containers/ThirdPartyConnectionsPage";
import { Domain } from "./lib";
import FirstPartyConnectionsPage from "./containers/FirstPartyConnectionsPage";

const ImportApp = () => {
  const element = useRoutes([
    {
      path: "sources/*",
      element: <ThirdPartyConnectionsPage domain={Domain.IMPORT} />,
    },
    {
      path: "providers/*",
      element: <ProvidersPage />,
    },
    {
      path: "destinations/*",
      element: <FirstPartyConnectionsPage domain={Domain.IMPORT} />,
    },
    {
      path: "magic-links/*",
      element: <ImportMagicLinksPage />,
    },
    { path: "*", element: <Navigate to="/export/overview" replace /> }, // TODO replace this with import overview when it exists
  ]);

  return element;
};

export default ImportApp;
