import React, { useEffect, useState } from "react";
import { ExistingDestination } from "@prequel/react";
import {
  Button,
  ButtonStyle,
  Checkbox,
  Modal,
  Spinner,
} from "@prequel-internal/react-components";

import {
  getAvailableModels,
  isAllCurrentFutureModels,
  getConfigsByModelNames,
} from "../../store/configs";

import { useTypedDispatch, useTypedSelector } from "../../store";
import { createTransfer } from "../../store/transfers/transfers.duck";
import {
  fetchProducts,
  selectProducts,
} from "../../store/products/products.duck";
import { fetchModels, selectModels } from "../../store/models/models.duck";

type SyncSpecificModelsModalProps = {
  destination: ExistingDestination;
  onConfirm: () => void;
  onCancel: () => void;
  fullRefresh: boolean;
};

const SyncSpecificModelsModal = ({
  destination,
  onConfirm,
  onCancel,
  fullRefresh,
}: SyncSpecificModelsModalProps) => {
  const dispatch = useTypedDispatch();
  const products = useTypedSelector(selectProducts);
  const models = useTypedSelector(selectModels);

  useEffect(() => {
    dispatch(fetchProducts());
    dispatch(fetchModels());
  }, [dispatch]);

  const availableModels = getAvailableModels(
    models ?? [],
    destination.products,
    products
  );

  // if enabled models is "*", return all available models. Otherwise, return an array of model configs from the list of enabled models.
  const enabledModels = isAllCurrentFutureModels(destination.enabled_models)
    ? availableModels
    : getConfigsByModelNames(availableModels, destination.enabled_models);

  const [selectedModels, setSelectedModels] = useState<string[]>([]);

  const updateSelectedModels = (isEnabled: boolean, modelName: string) => {
    let updatedModels: string[] = [];
    if (isEnabled) {
      updatedModels = selectedModels
        .filter((m) => m !== modelName)
        .concat([modelName]);
    } else {
      updatedModels = selectedModels.filter((m) => m !== modelName);
    }

    setSelectedModels(updatedModels);
  };

  const onConfirmSyncSelected = () => {
    dispatch(
      createTransfer({
        destinationId: destination.id,
        fullRefresh: fullRefresh,
        models: selectedModels,
      })
    );
    onConfirm();
  };

  return (
    <>
      <div className="sm:flex sm:items-start">
        <div className="text-left">
          <Modal.Title
            as="h3"
            className="text-lg leading-6 font-medium text-gray-900"
          >
            {fullRefresh
              ? "Select models to full refresh"
              : "Select models to sync"}
          </Modal.Title>
          <div className="mt-5 space-y-2">
            {enabledModels.length > 0 ? (
              <>
                {enabledModels.map((model) => (
                  <Checkbox
                    key={model.model_name}
                    id={model.model_name}
                    label={model.model_name}
                    checked={selectedModels.includes(model.model_name)}
                    setChecked={(isChecked: boolean) =>
                      updateSelectedModels(isChecked, model.model_name)
                    }
                  />
                ))}
              </>
            ) : (
              <Spinner />
            )}
          </div>
          {fullRefresh && (
            <div className="mt-5 -mb-2 flex-col bg-gray-50 border border-gray-200 text-gray-500 rounded p-4 text-sm">
              <div className="flex">
                <div className="ml-3">
                  <p className="font-medium text-red-700 mb-1">
                    Are you sure you want to force a full data refresh for these
                    selected models?
                  </p>
                  <p>
                    {`This will re-send all historical data and may
                  take a while.`}
                  </p>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="mt-8 space-x-3">
        <Button
          text={fullRefresh ? "Full Refresh Models" : "Sync Models"}
          disabled={selectedModels.length === 0}
          type={fullRefresh ? ButtonStyle.DANGER : ButtonStyle.SECONDARY}
          onClick={onConfirmSyncSelected}
        />
        <Button text="Cancel" type={ButtonStyle.TERTIARY} onClick={onCancel} />
      </div>
    </>
  );
};

export default SyncSpecificModelsModal;
