import React, { useEffect, useState } from "react";
import {
  Dropdown,
  DropdownListItem,
  Spinner,
} from "@prequel-internal/react-components";
import { useTypedDispatch, useTypedSelector } from "../../../store";
import {
  fetchAvailableTables,
  fetchExistingSourceTables,
  resetTableSample,
  selectAvailableTables,
  selectTableSample,
} from "../../../store/sources/sources.duck";
import {
  AvailableTable,
  ExistingImportSource,
  ExistingImportSourcePayload,
  PreparedImportSource,
  computeChangedImportFields,
} from "../../../store/sources";
import TableSample from "../TableSample";

type DropdownListItemWithTable = DropdownListItem<string> & {
  table: AvailableTable;
};

type ImportQueryFormProps = {
  source: PreparedImportSource;
  setCanGoForward: React.Dispatch<React.SetStateAction<boolean>>;
  sourceToEdit: ExistingImportSource | null | undefined;
  isEditing: boolean;
};
const ImportQueryForm = ({
  source,
  setCanGoForward,
  sourceToEdit,
  isEditing,
}: ImportQueryFormProps) => {
  const dispatch = useTypedDispatch();
  const availableTables = useTypedSelector(selectAvailableTables);
  const tableSample = useTypedSelector(selectTableSample);

  const [selectedItem, setSelectedItem] = useState<DropdownListItemWithTable>({
    key: "",
    text: "",
    table: { schema: "", table_name: "" },
  });
  const [dropdownOptions, setDropdownOptions] = useState<
    DropdownListItemWithTable[]
  >([]);

  useEffect(() => {
    if (isEditing && sourceToEdit) {
      const payload: ExistingImportSourcePayload = {
        sourceId: sourceToEdit.id,
      };
      const changed = computeChangedImportFields(sourceToEdit, source);
      if (Object.keys(changed).length > 0) {
        payload.fields = changed;
      }
      dispatch(fetchExistingSourceTables(payload));
    } else {
      dispatch(fetchAvailableTables(source));
    }
  }, [dispatch]);

  useEffect(() => {
    setCanGoForward(tableSample.status === "success");
  }, [tableSample]);

  useEffect(() => {
    dispatch(resetTableSample());
  }, [selectedItem]);

  useEffect(() => {
    if (availableTables.status === "success" && availableTables.tables) {
      const options = availableTables.tables.map((table: AvailableTable) => ({
        key: `${table.schema}.${table.table_name}`,
        text: `${table.schema}.${table.table_name}`,
        table,
      }));

      setSelectedItem(options[0]);
      setDropdownOptions(options);
    }
  }, [availableTables]);

  return (
    <form
      // onSubmit={onSubmit}
      className="bg-white border border-gray-200 rounded-lg"
    >
      <div className="px-4 py-5">
        <h2
          id="applicant-information-title"
          className="text-lg font-medium leading-6 text-gray-900"
        >
          Query Data
        </h2>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Query the source to return relevant data.
        </p>
      </div>
      <div className="space-y-4 border-t border-gray-200 p-4">
        <label className="block text-sm font-medium text-gray-700">
          Source table
        </label>
        {availableTables.status === "processing" && (
          <div className="flex align-items-center">
            <Spinner.Inline className="animate-spin h-5 w-5 mr-3 text-gray-500" />
            <span className="text-gray-500 text-sm">Fetching tables...</span>
          </div>
        )}
        {availableTables.status === "success" && (
          <Dropdown
            items={dropdownOptions}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            className="w-64"
          />
        )}
        <div className="h-px w-full bg-gray-200"></div> {/* Divider  */}
        <TableSample
          source={source}
          table={selectedItem.table}
          isEditing={isEditing}
          sourceToEdit={sourceToEdit}
        />
      </div>
    </form>
  );
};

export default ImportQueryForm;
