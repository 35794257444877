import React, { useMemo } from "react";
import { Dropdown } from "@prequel-internal/react-components";
import { Destination, FormField } from "@prequel/react";

import VendorLogo from "../../VendorLogo";

type SelectWrapperProps = {
  field: FormField & { form_element: "select" };
  destination: Destination;
  setDestinationField: (
    key: keyof Destination,
    value: string | boolean | string[]
  ) => void;
  disabled: boolean;
  isEditing: boolean;
};

const SelectWrapper = ({
  field,
  destination,
  setDestinationField,
  disabled,
  isEditing,
}: SelectWrapperProps) => {
  const items = useMemo(
    () =>
      field.enum.map((e) => ({
        key: e.key.toString(),
        text: e.display,
        ...(e.icon_url !== undefined
          ? { icon: () => <VendorLogo logo_url={e.icon_url ?? ""} /> }
          : {}),
      })),
    [field.enum]
  );

  const selected = useMemo(
    () => items.find(({ key }) => key === destination[field.name]),
    [items, destination[field.name]]
  );

  return (
    <Dropdown
      label={field.label}
      items={items}
      selectedItem={selected}
      setSelectedItem={(item) => setDestinationField(field.name, item.key)}
      disabled={disabled || (field.name === "vendor" && isEditing)}
    />
  );
};

export default SelectWrapper;
