import React, { useEffect } from "react";
import { NavLink, useNavigate, useRoutes } from "react-router-dom";
import { useTypedDispatch } from "../../store";
import {
  Button,
  ButtonStyle,
  PageHeading,
  Tabs,
  checkTabIsActive,
} from "@prequel-internal/react-components";
import ProvidersTable from "../ProvidersTable";
import { fetchProviders } from "../../store/providers/providers.duck";
import ProviderForm from "../ProviderForm";
import { fetchImportProductConfigs } from "../../store/configs/configs.duck";

const ProvidersPage = () => {
  const navigate = useNavigate();
  const dispatch = useTypedDispatch();

  const tabs = [
    <NavLink
      key="Providers"
      to="/import/providers"
      className={checkTabIsActive}
    >
      Summary
    </NavLink>,
  ];

  useEffect(() => {
    dispatch(fetchProviders());
    dispatch(fetchImportProductConfigs());
  }, [dispatch]);

  const element = useRoutes([
    {
      path: "/",
      element: (
        <div className="max-w-6xl pb-16">
          <PageHeading title="Providers">
            <Button
              text="Add Provider"
              onClick={() => navigate("new")}
              type={ButtonStyle.SECONDARY}
            />
          </PageHeading>
          <Tabs tabs={tabs} />
          <ProvidersTable />
        </div>
      ),
    },
    {
      path: "new",
      element: (
        <div className="max-w-lg pb-16">
          <PageHeading title="Add Provider" onGoBack={() => navigate(-1)} />
          <ProviderForm />
        </div>
      ),
    },
  ]);

  return <div className="flex flex-col">{element}</div>;
};

export default ProvidersPage;
