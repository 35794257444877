import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";
import { all, takeEvery, put } from "redux-saga/effects";

import { AppError, RequestStatus } from "../../axios";
import ExistingSource, {
  AvailableTable,
  AvailableTablesState,
  ExistingImportSource,
  ExistingImportSourcePayload,
  ExistingImportSourceWithTablePayload,
  ExistingSourceTestPayload,
  PreparedImportSource,
  PreparedSource,
  SourceTest,
  TableSample,
  TableSampleState,
} from ".";
import { Vendor } from "../../lib";
import SourcesService from "./sources.service";
import {
  RootState,
  createWorkerSaga,
  createRedirectSaga,
  WithRedirect,
} from "..";

// Slice state
type SourcesState = {
  sources: ExistingSource[] | undefined;
  importSources: ExistingImportSource[] | undefined;
  sourceTest: SourceTest;
  sourceServiceAccount: string | undefined;
  vendors: Vendor[] | undefined;
  availableTables: AvailableTablesState;
  tableSample: TableSampleState;
};
const initialState: SourcesState = {
  sources: undefined,
  importSources: undefined,
  sourceTest: { status: undefined },
  sourceServiceAccount: undefined,
  vendors: undefined,
  availableTables: { status: undefined, tables: undefined },
  tableSample: { status: undefined, table: undefined, rows: undefined },
};

// Action Reducers (Case Reducers)
const fetchSourcesReducer: CaseReducer<SourcesState, PayloadAction<void>> = (
  state: SourcesState
) => state;

const fetchSourcesSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<ExistingSource[]>
> = (state: SourcesState, action: PayloadAction<ExistingSource[]>) => {
  state.sources = action.payload;
};

const fetchSourcesFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state: SourcesState) => state;

const fetchImportSourcesReducer: CaseReducer<
  SourcesState,
  PayloadAction<void>
> = (state) => state;

const fetchImportSourcesSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<ExistingImportSource[]>
> = (state, action) => {
  state.importSources = action.payload;
};

const fetchImportSourcesFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state) => state;

const testNewSourceReducer: CaseReducer<
  SourcesState,
  PayloadAction<PreparedSource | PreparedImportSource>
> = (state: SourcesState) => {
  state.sourceTest.status = "processing";
};

const testNewSourceSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<RequestStatus>
> = (state: SourcesState) => {
  state.sourceTest.status = "success";
};

const testNewSourceFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state: SourcesState, action: PayloadAction<AppError>) => {
  state.sourceTest = {
    status: "error",
    message: action.payload.error.message,
  };
};

const createSourceReducer: CaseReducer<
  SourcesState,
  PayloadAction<WithRedirect<{ source: PreparedSource }>>
> = (state: SourcesState) => state;

const createSourceSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<WithRedirect<{ source: ExistingSource }>>
> = (state: SourcesState) => state;

const createSourceFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state: SourcesState) => state;

const createImportSourceReducer: CaseReducer<
  SourcesState,
  PayloadAction<WithRedirect<{ source: PreparedImportSource }>>
> = (state: SourcesState) => state;

const createImportSourceSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<WithRedirect<{ source: ExistingImportSource }>>
> = (state: SourcesState) => state;

const createImportSourceFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state: SourcesState) => state;

const updateSourceReducer: CaseReducer<
  SourcesState,
  PayloadAction<
    WithRedirect<
      WithRedirect<{
        sourceId: ExistingSource["id"];
        source: Partial<PreparedSource>;
      }>
    >
  >
> = (state: SourcesState) => state;

const updateSourceSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<WithRedirect<{ source: ExistingSource }>>
> = (
  state: SourcesState,
  action: PayloadAction<{ source: ExistingSource }>
) => {
  const updatedSource = action.payload.source;
  state.sources = state.sources?.map((d) =>
    d.id === updatedSource.id ? { ...d, ...updatedSource } : d
  );
};

const updateSourceFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state: SourcesState) => state;

const updateImportSourceReducer: CaseReducer<
  SourcesState,
  PayloadAction<
    WithRedirect<
      WithRedirect<{
        sourceId: ExistingImportSource["id"];
        source: Partial<PreparedImportSource>;
      }>
    >
  >
> = (state: SourcesState) => state;

const updateImportSourceSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<WithRedirect<{ source: ExistingImportSource }>>
> = (
  state: SourcesState,
  action: PayloadAction<{ source: ExistingImportSource }>
) => {
  const updatedSource = action.payload.source;
  state.importSources = state.importSources?.map((d) =>
    d.id === updatedSource.id ? { ...d, ...updatedSource } : d
  );
};

const updateImportSourceFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state: SourcesState) => state;

const deleteSourceReducer: CaseReducer<
  SourcesState,
  PayloadAction<
    WithRedirect<{
      sourceId: ExistingSource["id"];
    }>
  >
> = (state: SourcesState) => state;

const deleteSourceSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<WithRedirect<{}>>
> = (state: SourcesState) => state;

const deleteSourceFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state: SourcesState) => state;

const deleteImportSourceReducer: CaseReducer<
  SourcesState,
  PayloadAction<
    WithRedirect<{
      sourceId: ExistingImportSource["id"];
    }>
  >
> = (state: SourcesState) => state;

const deleteImportSourceSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<WithRedirect<{}>>
> = (state: SourcesState) => state;

const deleteImportSourceFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state: SourcesState) => state;

const testExistingSourceReducer: CaseReducer<
  SourcesState,
  PayloadAction<ExistingSourceTestPayload>
> = (state) => {
  state.sourceTest.status = "processing";
};

const testExistingSourceSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<RequestStatus>
> = (state) => {
  state.sourceTest.status = "success";
};

const testExistingSourceFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state, action) => {
  state.sourceTest = {
    status: "error",
    message: action.payload.error.message,
  };
};

const testExistingImportSourceReducer: CaseReducer<
  SourcesState,
  PayloadAction<ExistingImportSourcePayload>
> = (state) => {
  state.sourceTest.status = "processing";
};

const testExistingImportSourceSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<RequestStatus>
> = (state) => {
  state.sourceTest.status = "success";
};

const testExistingImportSourceFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state, action) => {
  state.sourceTest = {
    status: "error",
    message: action.payload.error.message,
  };
};

const resetSourceTestReducer: CaseReducer<SourcesState, PayloadAction<void>> = (
  state: SourcesState
) => {
  state.sourceTest.status = undefined;
};

const fetchSourceServiceAccountReducer: CaseReducer<
  SourcesState,
  PayloadAction<void>
> = (state: SourcesState) => state;

const fetchSourceServiceAccountSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<string>
> = (state: SourcesState, action: PayloadAction<string>) => {
  state.sourceServiceAccount = action.payload;
};

const fetchSourceServiceAccountFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state: SourcesState) => state;

const fetchSourceVendorsReducer: CaseReducer<
  SourcesState,
  PayloadAction<void>
> = (state: SourcesState) => state;

const fetchSourceVendorsSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<Vendor[]>
> = (state: SourcesState, action: PayloadAction<Vendor[]>) => {
  state.vendors = action.payload;
};

const fetchSourceVendorsFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state: SourcesState) => state;

const fetchAvailableTablesReducer: CaseReducer<
  SourcesState,
  PayloadAction<PreparedImportSource>
> = (state: SourcesState) => {
  state.availableTables = { status: "processing", tables: undefined };
};

const fetchAvailableTablesSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<AvailableTable[]>
> = (state: SourcesState, action: PayloadAction<AvailableTable[]>) => {
  state.availableTables = { status: "success", tables: action.payload };
};

const fetchAvailableTablesFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state: SourcesState) => {
  state.availableTables = { status: "error", tables: undefined };
};

const fetchExistingSourceTablesReducer: CaseReducer<
  SourcesState,
  PayloadAction<ExistingImportSourcePayload>
> = (state: SourcesState) => {
  state.availableTables = { status: "processing", tables: undefined };
};

const fetchExistingSourceTablesSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<AvailableTable[]>
> = (state: SourcesState, action: PayloadAction<AvailableTable[]>) => {
  state.availableTables = { status: "success", tables: action.payload };
};

const fetchExistingSourceTablesFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state: SourcesState) => {
  state.availableTables = { status: "error", tables: undefined };
};

const fetchTableSampleReducer: CaseReducer<
  SourcesState,
  PayloadAction<{ source: PreparedImportSource; table: AvailableTable }>
> = (state: SourcesState) => {
  state.tableSample = {
    status: "processing",
    table: undefined,
    rows: undefined,
  };
};

const fetchTableSampleSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<{ sample: TableSample; table: AvailableTable }>
> = (
  state: SourcesState,
  action: PayloadAction<{ sample: TableSample; table: AvailableTable }>
) => {
  state.tableSample = {
    status: "success",
    table: action.payload.table,
    rows: action.payload.sample,
  };
};

const fetchTableSampleFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state: SourcesState) => {
  state.tableSample = { status: "error", table: undefined, rows: undefined };
};

const fetchExistingSourceTableSampleReducer: CaseReducer<
  SourcesState,
  PayloadAction<ExistingImportSourceWithTablePayload>
> = (state: SourcesState) => {
  state.tableSample = {
    status: "processing",
    table: undefined,
    rows: undefined,
  };
};

const fetchExistingSourceTableSampleSuccessReducer: CaseReducer<
  SourcesState,
  PayloadAction<{ sample: TableSample; table: AvailableTable }>
> = (
  state: SourcesState,
  action: PayloadAction<{ sample: TableSample; table: AvailableTable }>
) => {
  state.tableSample = {
    status: "success",
    table: action.payload.table,
    rows: action.payload.sample,
  };
};

const fetchExistingSourceTableSampleFailureReducer: CaseReducer<
  SourcesState,
  PayloadAction<AppError>
> = (state: SourcesState) => {
  state.tableSample = { status: "error", table: undefined, rows: undefined };
};

const resetTableSampleReducer: CaseReducer<
  SourcesState,
  PayloadAction<void>
> = (state: SourcesState) => {
  state.tableSample = { status: undefined, table: undefined, rows: undefined };
};

function* watchFetchSources() {
  yield takeEvery(
    fetchSources.type,
    createWorkerSaga(
      fetchSources,
      fetchSourcesSuccess,
      fetchSourcesFailure,
      SourcesService.getSources
    )
  );
}

function* watchFetchImportSources() {
  yield takeEvery(
    fetchImportSources.type,
    createWorkerSaga(
      fetchImportSources,
      fetchImportSourcesSuccess,
      fetchImportSourcesFailure,
      SourcesService.getImportSources
    )
  );
}

function* watchTestNewSource() {
  yield takeEvery(
    testNewSource.type,
    createWorkerSaga(
      testNewSource,
      testNewSourceSuccess,
      testNewSourceFailure,
      SourcesService.testNewSource
    )
  );
}

function* watchCreateSource() {
  yield takeEvery(
    createSource.type,
    createWorkerSaga(
      createSource,
      createSourceSuccess,
      createSourceFailure,
      SourcesService.postSource
    )
  );
}

function* watchCreateImportSource() {
  yield takeEvery(
    createImportSource.type,
    createWorkerSaga(
      createImportSource,
      createImportSourceSuccess,
      createImportSourceFailure,
      SourcesService.postImportSource
    )
  );
}

function* watchUpdateSource() {
  yield takeEvery(
    updateSource.type,
    createWorkerSaga(
      updateSource,
      updateSourceSuccess,
      updateSourceFailure,
      SourcesService.patchSource
    )
  );
}

function* watchUpdateImportSource() {
  yield takeEvery(
    updateImportSource.type,
    createWorkerSaga(
      updateImportSource,
      updateImportSourceSuccess,
      updateImportSourceFailure,
      SourcesService.patchImportSource
    )
  );
}

function* watchDeleteSource() {
  yield takeEvery(
    deleteSource.type,
    createWorkerSaga(
      deleteSource,
      deleteSourceSuccess,
      deleteSourceFailure,
      SourcesService.deleteSource
    )
  );
}

function* watchDeleteImportSource() {
  yield takeEvery(
    deleteImportSource.type,
    createWorkerSaga(
      deleteImportSource,
      deleteImportSourceSuccess,
      deleteImportSourceFailure,
      SourcesService.deleteImportSource
    )
  );
}

function* watchFetchSourceVendors() {
  yield takeEvery(
    fetchSourceVendors.type,
    createWorkerSaga(
      fetchSourceVendors,
      fetchSourceVendorsSuccess,
      fetchSourceVendorsFailure,
      SourcesService.getVendors
    )
  );
}

function* watchCreateSourceSuccess() {
  yield takeEvery(createSourceSuccess.type, function* () {
    yield put(fetchSources());
  });
  yield takeEvery(createSourceSuccess.type, createRedirectSaga());
}

function* watchCreateImportSourceSuccess() {
  yield takeEvery(createImportSourceSuccess.type, function* () {
    yield put(fetchImportSources());
  });
  yield takeEvery(createImportSourceSuccess.type, createRedirectSaga());
}

function* watchUpdateSourceSuccess() {
  yield takeEvery(updateSourceSuccess.type, function* () {
    yield put(fetchSources());
  });
  yield takeEvery(updateSourceSuccess.type, createRedirectSaga());
}

function* watchUpdateImportSourceSuccess() {
  yield takeEvery(updateImportSourceSuccess.type, function* () {
    yield put(fetchImportSources());
  });
  yield takeEvery(updateImportSourceSuccess.type, createRedirectSaga());
}

function* watchDeleteSourceSuccess() {
  yield takeEvery(deleteSourceSuccess.type, function* () {
    yield put(fetchSources());
  });
  yield takeEvery(deleteSourceSuccess.type, createRedirectSaga());
}

function* watchDeleteImportSourceSuccess() {
  yield takeEvery(deleteImportSourceSuccess.type, function* () {
    yield put(fetchImportSources());
  });
  yield takeEvery(deleteImportSourceSuccess.type, createRedirectSaga());
}

function* watchTestExistingSource() {
  yield takeEvery(
    testExistingSource.type,
    createWorkerSaga(
      testExistingSource,
      testExistingSourceSuccess,
      testExistingSourceFailure,
      SourcesService.testExistingSource
    )
  );
}

function* watchTestExistingImportSource() {
  yield takeEvery(
    testExistingImportSource.type,
    createWorkerSaga(
      testExistingImportSource,
      testExistingImportSourceSuccess,
      testExistingImportSourceFailure,
      SourcesService.testExistingImportSource
    )
  );
}

function* watchFetchAvailableTables() {
  yield takeEvery(
    fetchAvailableTables.type,
    createWorkerSaga(
      fetchAvailableTables,
      fetchAvailableTablesSuccess,
      fetchAvailableTablesFailure,
      SourcesService.postListSourceTables
    )
  );
}

function* watchFetchExistingSourceTables() {
  yield takeEvery(
    fetchExistingSourceTables.type,
    createWorkerSaga(
      fetchExistingSourceTables,
      fetchExistingSourceTablesSuccess,
      fetchExistingSourceTablesFailure,
      SourcesService.postListExistingSourceTables
    )
  );
}

function* watchFetchTableSample() {
  yield takeEvery(
    fetchTableSample.type,
    createWorkerSaga(
      fetchTableSample,
      fetchTableSampleSuccess,
      fetchTableSampleFailure,
      SourcesService.postSampleSourceTable
    )
  );
}

function* watchFetchExistingSourceTableSample() {
  yield takeEvery(
    fetchExistingSourceTableSample.type,
    createWorkerSaga(
      fetchExistingSourceTableSample,
      fetchExistingSourceTableSampleSuccess,
      fetchExistingSourceTableSampleFailure,
      SourcesService.postSampleExistingSourceTable
    )
  );
}

const sourcesSlice = createSlice({
  name: "sources",
  initialState,
  reducers: {
    fetchSources: fetchSourcesReducer,
    fetchSourcesSuccess: fetchSourcesSuccessReducer,
    fetchSourcesFailure: fetchSourcesFailureReducer,
    fetchImportSources: fetchImportSourcesReducer,
    fetchImportSourcesSuccess: fetchImportSourcesSuccessReducer,
    fetchImportSourcesFailure: fetchImportSourcesFailureReducer,
    testNewSource: testNewSourceReducer,
    testNewSourceSuccess: testNewSourceSuccessReducer,
    testNewSourceFailure: testNewSourceFailureReducer,
    resetSourceTest: resetSourceTestReducer,
    createSource: createSourceReducer,
    createSourceSuccess: createSourceSuccessReducer,
    createSourceFailure: createSourceFailureReducer,
    createImportSource: createImportSourceReducer,
    createImportSourceSuccess: createImportSourceSuccessReducer,
    createImportSourceFailure: createImportSourceFailureReducer,
    updateSource: updateSourceReducer,
    updateSourceSuccess: updateSourceSuccessReducer,
    updateSourceFailure: updateSourceFailureReducer,
    updateImportSource: updateImportSourceReducer,
    updateImportSourceSuccess: updateImportSourceSuccessReducer,
    updateImportSourceFailure: updateImportSourceFailureReducer,
    deleteSource: deleteSourceReducer,
    deleteSourceSuccess: deleteSourceSuccessReducer,
    deleteSourceFailure: deleteSourceFailureReducer,
    deleteImportSource: deleteImportSourceReducer,
    deleteImportSourceSuccess: deleteImportSourceSuccessReducer,
    deleteImportSourceFailure: deleteImportSourceFailureReducer,
    fetchSourceServiceAccount: fetchSourceServiceAccountReducer,
    fetchSourceServiceAccountSuccess: fetchSourceServiceAccountSuccessReducer,
    fetchSourceServiceAccountFailure: fetchSourceServiceAccountFailureReducer,
    fetchSourceVendors: fetchSourceVendorsReducer,
    fetchSourceVendorsSuccess: fetchSourceVendorsSuccessReducer,
    fetchSourceVendorsFailure: fetchSourceVendorsFailureReducer,
    testExistingSource: testExistingSourceReducer,
    testExistingSourceSuccess: testExistingSourceSuccessReducer,
    testExistingSourceFailure: testExistingSourceFailureReducer,
    testExistingImportSource: testExistingImportSourceReducer,
    testExistingImportSourceSuccess: testExistingImportSourceSuccessReducer,
    testExistingImportSourceFailure: testExistingImportSourceFailureReducer,
    fetchAvailableTables: fetchAvailableTablesReducer,
    fetchAvailableTablesSuccess: fetchAvailableTablesSuccessReducer,
    fetchAvailableTablesFailure: fetchAvailableTablesFailureReducer,
    fetchExistingSourceTables: fetchExistingSourceTablesReducer,
    fetchExistingSourceTablesSuccess: fetchExistingSourceTablesSuccessReducer,
    fetchExistingSourceTablesFailure: fetchExistingSourceTablesFailureReducer,
    fetchTableSample: fetchTableSampleReducer,
    fetchTableSampleSuccess: fetchTableSampleSuccessReducer,
    fetchTableSampleFailure: fetchTableSampleFailureReducer,
    fetchExistingSourceTableSample: fetchExistingSourceTableSampleReducer,
    fetchExistingSourceTableSampleSuccess:
      fetchExistingSourceTableSampleSuccessReducer,
    fetchExistingSourceTableSampleFailure:
      fetchExistingSourceTableSampleFailureReducer,
    resetTableSample: resetTableSampleReducer,
  },
});

export const {
  fetchSources,
  fetchSourcesSuccess,
  fetchSourcesFailure,
  fetchImportSources,
  fetchImportSourcesSuccess,
  fetchImportSourcesFailure,
  testNewSource,
  testNewSourceSuccess,
  testNewSourceFailure,
  resetSourceTest,
  createSource,
  createSourceSuccess,
  createSourceFailure,
  createImportSource,
  createImportSourceSuccess,
  createImportSourceFailure,
  updateSource,
  updateSourceSuccess,
  updateSourceFailure,
  updateImportSource,
  updateImportSourceSuccess,
  updateImportSourceFailure,
  deleteSource,
  deleteSourceSuccess,
  deleteSourceFailure,
  deleteImportSource,
  deleteImportSourceSuccess,
  deleteImportSourceFailure,
  fetchSourceServiceAccount,
  fetchSourceServiceAccountSuccess,
  fetchSourceServiceAccountFailure,
  fetchSourceVendors,
  fetchSourceVendorsSuccess,
  fetchSourceVendorsFailure,
  testExistingSource,
  testExistingSourceSuccess,
  testExistingSourceFailure,
  testExistingImportSource,
  testExistingImportSourceSuccess,
  testExistingImportSourceFailure,
  fetchAvailableTables,
  fetchAvailableTablesSuccess,
  fetchAvailableTablesFailure,
  fetchExistingSourceTables,
  fetchExistingSourceTablesSuccess,
  fetchExistingSourceTablesFailure,
  fetchTableSample,
  fetchTableSampleSuccess,
  fetchTableSampleFailure,
  fetchExistingSourceTableSample,
  fetchExistingSourceTableSampleSuccess,
  fetchExistingSourceTableSampleFailure,
  resetTableSample,
} = sourcesSlice.actions;

export const selectSources: (state: RootState) => SourcesState["sources"] = ({
  sources,
}: RootState) => sources.sources;
export const selectImportSources: (
  state: RootState
) => SourcesState["importSources"] = ({ sources }: RootState) =>
  sources.importSources;
export const selectSource: (
  state: RootState,
  id?: string
) => ExistingSource | null | undefined = (state, sourceId) => {
  const sources = selectSources(state);
  if (sources === undefined) {
    // If we don't have sources, return undefined as we need to wait for fetch
    return undefined;
  }

  const found = sources.find(({ id }) => id === sourceId);
  // If we could not find the source, return null to signify it is not in the loaded sources
  return found ?? null;
};
export const selectImportSource: (
  state: RootState,
  id?: string
) => ExistingImportSource | null | undefined = (rootState, sourceId) => {
  const importSources = selectImportSources(rootState);
  if (importSources === undefined) {
    // If we don't have import sources, return undefined as we need to wait for fetch
    return undefined;
  }

  const found = importSources.find(({ id }) => id === sourceId);
  // If we could not find the import source, return null to signify it is not in the loaded import sources
  return found ?? null;
};
export const selectSourceTest: (
  state: RootState
) => SourcesState["sourceTest"] = ({ sources }: RootState) =>
  sources.sourceTest;
export const selectSourceServiceAccount: (
  state: RootState
) => SourcesState["sourceServiceAccount"] = ({ sources }: RootState) =>
  sources.sourceServiceAccount;
export const selectSourceVendors: (
  state: RootState
) => SourcesState["vendors"] = ({ sources }: RootState) => sources.vendors;
export const selectAvailableTables: (
  state: RootState
) => SourcesState["availableTables"] = ({ sources }: RootState) =>
  sources.availableTables;
export const selectTableSample: (
  state: RootState
) => SourcesState["tableSample"] = ({ sources }: RootState) =>
  sources.tableSample;

export function* sourcesSaga() {
  yield all([
    watchFetchSources(),
    watchFetchImportSources(),
    watchTestNewSource(),
    watchCreateSource(),
    watchCreateImportSource(),
    watchCreateSourceSuccess(),
    watchCreateImportSourceSuccess(),
    watchUpdateSource(),
    watchUpdateImportSource(),
    watchUpdateSourceSuccess(),
    watchUpdateImportSourceSuccess(),
    watchDeleteSource(),
    watchDeleteImportSource(),
    watchDeleteSourceSuccess(),
    watchDeleteImportSourceSuccess(),
    watchFetchSourceVendors(),
    watchTestExistingSource(),
    watchTestExistingImportSource(),
    watchFetchAvailableTables(),
    watchFetchExistingSourceTables(),
    watchFetchTableSample(),
    watchFetchExistingSourceTableSample(),
  ]);
}
export default sourcesSlice.reducer;
