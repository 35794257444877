import React from "react";
import {
  FormField,
  SectionExpandable,
  Toggle,
} from "@prequel-internal/react-components";
import { FormMagicLink } from "../../../store/magic_links";

type AdvancedOptionsProps = {
  link: FormMagicLink;
  setLinkField: (
    key: keyof FormMagicLink,
    value: string | string[] | boolean
  ) => void;
};
const AdvancedOptions = ({ link, setLinkField }: AdvancedOptionsProps) => {
  return (
    <SectionExpandable label="Advanced options">
      <div className="space-y-4">
        <Toggle
          label="Enable destination automatically"
          subtext={"Connection will be created as \"enabled\" and data will begin transferring upon successful link submission."} // prettier-ignore
          enabled={link.set_destination_enabled}
          setEnabled={(enabled: boolean) =>
            setLinkField("set_destination_enabled", enabled)
          }
        />
        <FormField
          label="Redirect URL"
          id="redirect_url"
          type="text"
          subtext="If provided, the user will be redirected to the provided URL after successful form submission. If left blank, the user will remain on the successful submission page."
          value={link.redirect_url}
          onChangeHandler={(value: string) =>
            setLinkField("redirect_url", value)
          }
        />
      </div>
    </SectionExpandable>
  );
};

export default AdvancedOptions;
