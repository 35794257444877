import { createSlice, CaseReducer, PayloadAction } from "@reduxjs/toolkit";

import { all, takeEvery, put } from "redux-saga/effects";

import {
  RootState,
  createWorkerSaga,
  createRedirectSaga,
  WithRedirect,
} from "..";
import MagicLinksService from "./magic_links.service";
import MagicLink, {
  ExistingImportMagicLink,
  FormMagicLink,
  ImportMagicLink,
} from ".";
import { AppError } from "../../axios";

import { createToast } from "../toasts/toasts.duck";

// Slice state
type MagicLinksState = {
  links: MagicLink[] | undefined;
  importLinks: ExistingImportMagicLink[] | undefined;
};
const initialState: MagicLinksState = {
  links: undefined,
  importLinks: undefined,
};

// Action Reducers (Case Reducers)
const fetchMagicLinksReducer: CaseReducer<
  MagicLinksState,
  PayloadAction<void>
> = (state) => state;

const fetchMagicLinksSuccessReducer: CaseReducer<
  MagicLinksState,
  PayloadAction<MagicLink[]>
> = (state, action) => {
  state.links = action.payload;
};

const fetchMagicLinksFailureReducer: CaseReducer<
  MagicLinksState,
  PayloadAction<AppError>
> = (state) => state;

const fetchImportMagicLinksReducer: CaseReducer<
  MagicLinksState,
  PayloadAction<void>
> = (state) => state;

const fetchImportMagicLinksSuccessReducer: CaseReducer<
  MagicLinksState,
  PayloadAction<ExistingImportMagicLink[]>
> = (state, action) => {
  state.importLinks = action.payload;
};

const fetchImportMagicLinksFailureReducer: CaseReducer<
  MagicLinksState,
  PayloadAction<AppError>
> = (state) => state;

const createMagicLinkReducer: CaseReducer<
  MagicLinksState,
  PayloadAction<WithRedirect<{ link: FormMagicLink }>>
> = (state) => state;

const createMagicLinkSuccessReducer: CaseReducer<
  MagicLinksState,
  PayloadAction<WithRedirect<{ link: MagicLink }>>
> = (state) => state;

const createMagicLinkFailureReducer: CaseReducer<
  MagicLinksState,
  PayloadAction<AppError>
> = (state) => state;

const createImportMagicLinkReducer: CaseReducer<
  MagicLinksState,
  PayloadAction<WithRedirect<{ link: ImportMagicLink }>>
> = (state) => state;

const createImportMagicLinkSuccessReducer: CaseReducer<
  MagicLinksState,
  PayloadAction<WithRedirect<{ link: ExistingImportMagicLink }>>
> = (state) => state;

const createImportMagicLinkFailureReducer: CaseReducer<
  MagicLinksState,
  PayloadAction<AppError>
> = (state) => state;

function* watchFetchMagicLinks() {
  yield takeEvery(
    fetchMagicLinks.type,
    createWorkerSaga(
      fetchMagicLinks,
      fetchMagicLinksSuccess,
      fetchMagicLinksFailure,
      MagicLinksService.getMagicLinks
    )
  );
}

function* watchFetchImportMagicLinks() {
  yield takeEvery(
    fetchImportMagicLinks.type,
    createWorkerSaga(
      fetchImportMagicLinks,
      fetchImportMagicLinksSuccess,
      fetchImportMagicLinksFailure,
      MagicLinksService.getImportMagicLinks
    )
  );
}

function* watchCreateMagicLink() {
  yield takeEvery(
    createMagicLink.type,
    createWorkerSaga(
      createMagicLink,
      createMagicLinkSuccess,
      createMagicLinkFailure,
      MagicLinksService.postMagicLink
    )
  );
}

function* watchImportCreateMagicLink() {
  yield takeEvery(
    createImportMagicLink.type,
    createWorkerSaga(
      createImportMagicLink,
      createImportMagicLinkSuccess,
      createImportMagicLinkFailure,
      MagicLinksService.postImportMagicLink
    )
  );
}

function* watchCreateMagicLinkSuccess() {
  yield takeEvery(createMagicLinkSuccess.type, function* () {
    yield put(fetchMagicLinks());
    yield put(
      createToast({
        toast: {
          id: new Date().getTime().toString(),
          kind: "SUCCESS",
          message: "Magic Link created successfully!",
        },
      })
    );
  });
  yield takeEvery(createMagicLinkSuccess.type, createRedirectSaga());
}

function* watchImportCreateMagicLinkSuccess() {
  yield takeEvery(createImportMagicLinkSuccess.type, function* () {
    yield put(fetchImportMagicLinks());
    yield put(
      createToast({
        toast: {
          id: new Date().getTime().toString(),
          kind: "SUCCESS",
          message: "Magic Link created successfully!",
        },
      })
    );
  });
  yield takeEvery(createImportMagicLinkSuccess.type, createRedirectSaga());
}

export const magicLinksSlice = createSlice({
  name: "magic_links",
  initialState,
  reducers: {
    fetchMagicLinks: fetchMagicLinksReducer,
    fetchMagicLinksSuccess: fetchMagicLinksSuccessReducer,
    fetchMagicLinksFailure: fetchMagicLinksFailureReducer,
    fetchImportMagicLinks: fetchImportMagicLinksReducer,
    fetchImportMagicLinksSuccess: fetchImportMagicLinksSuccessReducer,
    fetchImportMagicLinksFailure: fetchImportMagicLinksFailureReducer,
    createMagicLink: createMagicLinkReducer,
    createMagicLinkSuccess: createMagicLinkSuccessReducer,
    createMagicLinkFailure: createMagicLinkFailureReducer,
    createImportMagicLink: createImportMagicLinkReducer,
    createImportMagicLinkSuccess: createImportMagicLinkSuccessReducer,
    createImportMagicLinkFailure: createImportMagicLinkFailureReducer,
  },
});

const {
  fetchMagicLinks,
  fetchMagicLinksSuccess,
  fetchMagicLinksFailure,
  fetchImportMagicLinks,
  fetchImportMagicLinksSuccess,
  fetchImportMagicLinksFailure,
  createMagicLink,
  createMagicLinkSuccess,
  createMagicLinkFailure,
  createImportMagicLink,
  createImportMagicLinkSuccess,
  createImportMagicLinkFailure,
} = magicLinksSlice.actions;

export const selectMagicLinks = ({ magic_links }: RootState) =>
  magic_links.links;
export const selectImportMagicLinks = ({ magic_links }: RootState) =>
  magic_links.importLinks;

export {
  fetchMagicLinks,
  fetchMagicLinksSuccess,
  fetchMagicLinksFailure,
  fetchImportMagicLinks,
  fetchImportMagicLinksSuccess,
  fetchImportMagicLinksFailure,
  createMagicLink,
  createMagicLinkSuccess,
  createMagicLinkFailure,
  createImportMagicLink,
  createImportMagicLinkSuccess,
  createImportMagicLinkFailure,
};
export function* magicLinksSaga() {
  yield all([
    watchFetchMagicLinks(),
    watchFetchImportMagicLinks(),
    watchCreateMagicLink(),
    watchCreateMagicLinkSuccess(),
    watchImportCreateMagicLink(),
    watchImportCreateMagicLinkSuccess(),
  ]);
}
export default magicLinksSlice.reducer;
