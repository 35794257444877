import { RadioCardOption } from "@prequel-internal/react-components";

export type Organization = {
  app_users: string[];
  default_frequency_minutes: number;
  fastest_allowed_frequency_minutes: number;
  github_branch_name: string;
  github_staging_branch_name: string;
  github_organization: string;
  github_repository: string;
  id: string;
  name: string;
  sources_schema_tenanted: boolean;
};

const frequencyOptions: RadioCardOption<number>[] = [
  {
    label: "15 m",
    value: 15,
    enabled: false,
  },
  {
    label: "1 hr",
    value: 60,
    enabled: true,
  },
  {
    label: "6 hr",
    value: 360,
    enabled: true,
  },
  {
    label: "12 hr",
    value: 720,
    enabled: true,
  },
  {
    label: "24 hr",
    value: 1440,
    enabled: true,
  },
];

export const getFrequencyOptions: (
  f: number | undefined
) => RadioCardOption<number>[] = (fastestAllowed) => {
  if (!fastestAllowed) {
    return [];
  }

  return frequencyOptions.map((opt) => ({
    ...opt,
    enabled: opt.value >= fastestAllowed,
  }));
};

export const getFrequencyLabel: (f: number) => string | undefined = (
  frequency
) => {
  const opt = frequencyOptions.find(({ value }) => value === frequency);

  return opt ? opt.label : undefined;
};
